@charset "UTF-8";
/*--------------------------------------------
  setting
--------------------------------------------*/
/*--------------------------------------------
  value
--------------------------------------------*/
/*--------------------------------------------
  MIXIN 等
--------------------------------------------*/
/*--------------------------------------------
  KEYFRAME
--------------------------------------------*/
/*--------------------------------------------
  MIXIN
--------------------------------------------*/
[data-duration="50"] {
  transition-duration: 50ms; }

[data-duration="100"] {
  transition-duration: 100ms; }

[data-duration="150"] {
  transition-duration: 150ms; }

[data-duration="200"] {
  transition-duration: 200ms; }

[data-duration="250"] {
  transition-duration: 250ms; }

[data-duration="300"] {
  transition-duration: 300ms; }

[data-duration="350"] {
  transition-duration: 350ms; }

[data-duration="400"] {
  transition-duration: 400ms; }

[data-duration="450"] {
  transition-duration: 450ms; }

[data-duration="500"] {
  transition-duration: 500ms; }

[data-duration="550"] {
  transition-duration: 550ms; }

[data-duration="600"] {
  transition-duration: 600ms; }

[data-duration="650"] {
  transition-duration: 650ms; }

[data-duration="700"] {
  transition-duration: 700ms; }

[data-duration="750"] {
  transition-duration: 750ms; }

[data-duration="800"] {
  transition-duration: 800ms; }

[data-duration="850"] {
  transition-duration: 850ms; }

[data-duration="900"] {
  transition-duration: 900ms; }

[data-duration="950"] {
  transition-duration: 950ms; }

[data-duration="1000"] {
  transition-duration: 1000ms; }

[data-duration="1050"] {
  transition-duration: 1050ms; }

[data-duration="1100"] {
  transition-duration: 1100ms; }

[data-duration="1150"] {
  transition-duration: 1150ms; }

[data-duration="1200"] {
  transition-duration: 1200ms; }

[data-duration="1250"] {
  transition-duration: 1250ms; }

[data-duration="1300"] {
  transition-duration: 1300ms; }

[data-duration="1350"] {
  transition-duration: 1350ms; }

[data-duration="1400"] {
  transition-duration: 1400ms; }

[data-duration="1450"] {
  transition-duration: 1450ms; }

[data-duration="1500"] {
  transition-duration: 1500ms; }

[data-duration="1550"] {
  transition-duration: 1550ms; }

[data-duration="1600"] {
  transition-duration: 1600ms; }

[data-duration="1650"] {
  transition-duration: 1650ms; }

[data-duration="1700"] {
  transition-duration: 1700ms; }

[data-duration="1750"] {
  transition-duration: 1750ms; }

[data-duration="1800"] {
  transition-duration: 1800ms; }

[data-duration="1850"] {
  transition-duration: 1850ms; }

[data-duration="1900"] {
  transition-duration: 1900ms; }

[data-duration="1950"] {
  transition-duration: 1950ms; }

[data-duration="2000"] {
  transition-duration: 2000ms; }

[data-duration="2050"] {
  transition-duration: 2050ms; }

[data-duration="2100"] {
  transition-duration: 2100ms; }

[data-duration="2150"] {
  transition-duration: 2150ms; }

[data-duration="2200"] {
  transition-duration: 2200ms; }

[data-duration="2250"] {
  transition-duration: 2250ms; }

[data-duration="2300"] {
  transition-duration: 2300ms; }

[data-duration="2350"] {
  transition-duration: 2350ms; }

[data-duration="2400"] {
  transition-duration: 2400ms; }

[data-duration="2450"] {
  transition-duration: 2450ms; }

[data-duration="2500"] {
  transition-duration: 2500ms; }

[data-duration="2550"] {
  transition-duration: 2550ms; }

[data-duration="2600"] {
  transition-duration: 2600ms; }

[data-duration="2650"] {
  transition-duration: 2650ms; }

[data-duration="2700"] {
  transition-duration: 2700ms; }

[data-duration="2750"] {
  transition-duration: 2750ms; }

[data-duration="2800"] {
  transition-duration: 2800ms; }

[data-duration="2850"] {
  transition-duration: 2850ms; }

[data-duration="2900"] {
  transition-duration: 2900ms; }

[data-duration="2950"] {
  transition-duration: 2950ms; }

[data-duration="3000"] {
  transition-duration: 3000ms; }

[data-duration="3050"] {
  transition-duration: 3050ms; }

[data-duration="3100"] {
  transition-duration: 3100ms; }

[data-duration="3150"] {
  transition-duration: 3150ms; }

[data-duration="3200"] {
  transition-duration: 3200ms; }

[data-duration="3250"] {
  transition-duration: 3250ms; }

[data-duration="3300"] {
  transition-duration: 3300ms; }

[data-duration="3350"] {
  transition-duration: 3350ms; }

[data-duration="3400"] {
  transition-duration: 3400ms; }

[data-duration="3450"] {
  transition-duration: 3450ms; }

[data-duration="3500"] {
  transition-duration: 3500ms; }

[data-duration="3550"] {
  transition-duration: 3550ms; }

[data-duration="3600"] {
  transition-duration: 3600ms; }

[data-duration="3650"] {
  transition-duration: 3650ms; }

[data-duration="3700"] {
  transition-duration: 3700ms; }

[data-duration="3750"] {
  transition-duration: 3750ms; }

[data-duration="3800"] {
  transition-duration: 3800ms; }

[data-duration="3850"] {
  transition-duration: 3850ms; }

[data-duration="3900"] {
  transition-duration: 3900ms; }

[data-duration="3950"] {
  transition-duration: 3950ms; }

[data-duration="4000"] {
  transition-duration: 4000ms; }

[data-duration="4050"] {
  transition-duration: 4050ms; }

[data-duration="4100"] {
  transition-duration: 4100ms; }

[data-duration="4150"] {
  transition-duration: 4150ms; }

[data-duration="4200"] {
  transition-duration: 4200ms; }

[data-duration="4250"] {
  transition-duration: 4250ms; }

[data-duration="4300"] {
  transition-duration: 4300ms; }

[data-duration="4350"] {
  transition-duration: 4350ms; }

[data-duration="4400"] {
  transition-duration: 4400ms; }

[data-duration="4450"] {
  transition-duration: 4450ms; }

[data-duration="4500"] {
  transition-duration: 4500ms; }

[data-duration="4550"] {
  transition-duration: 4550ms; }

[data-duration="4600"] {
  transition-duration: 4600ms; }

[data-duration="4650"] {
  transition-duration: 4650ms; }

[data-duration="4700"] {
  transition-duration: 4700ms; }

[data-duration="4750"] {
  transition-duration: 4750ms; }

[data-duration="4800"] {
  transition-duration: 4800ms; }

[data-duration="4850"] {
  transition-duration: 4850ms; }

[data-duration="4900"] {
  transition-duration: 4900ms; }

[data-duration="4950"] {
  transition-duration: 4950ms; }

[data-duration="5000"] {
  transition-duration: 5000ms; }

[data-delay="50"] {
  transition-delay: 50ms; }

[data-delay="100"] {
  transition-delay: 100ms; }

[data-delay="150"] {
  transition-delay: 150ms; }

[data-delay="200"] {
  transition-delay: 200ms; }

[data-delay="250"] {
  transition-delay: 250ms; }

[data-delay="300"] {
  transition-delay: 300ms; }

[data-delay="350"] {
  transition-delay: 350ms; }

[data-delay="400"] {
  transition-delay: 400ms; }

[data-delay="450"] {
  transition-delay: 450ms; }

[data-delay="500"] {
  transition-delay: 500ms; }

[data-delay="550"] {
  transition-delay: 550ms; }

[data-delay="600"] {
  transition-delay: 600ms; }

[data-delay="650"] {
  transition-delay: 650ms; }

[data-delay="700"] {
  transition-delay: 700ms; }

[data-delay="750"] {
  transition-delay: 750ms; }

[data-delay="800"] {
  transition-delay: 800ms; }

[data-delay="850"] {
  transition-delay: 850ms; }

[data-delay="900"] {
  transition-delay: 900ms; }

[data-delay="950"] {
  transition-delay: 950ms; }

[data-delay="1000"] {
  transition-delay: 1000ms; }

[data-delay="1050"] {
  transition-delay: 1050ms; }

[data-delay="1100"] {
  transition-delay: 1100ms; }

[data-delay="1150"] {
  transition-delay: 1150ms; }

[data-delay="1200"] {
  transition-delay: 1200ms; }

[data-delay="1250"] {
  transition-delay: 1250ms; }

[data-delay="1300"] {
  transition-delay: 1300ms; }

[data-delay="1350"] {
  transition-delay: 1350ms; }

[data-delay="1400"] {
  transition-delay: 1400ms; }

[data-delay="1450"] {
  transition-delay: 1450ms; }

[data-delay="1500"] {
  transition-delay: 1500ms; }

[data-delay="1550"] {
  transition-delay: 1550ms; }

[data-delay="1600"] {
  transition-delay: 1600ms; }

[data-delay="1650"] {
  transition-delay: 1650ms; }

[data-delay="1700"] {
  transition-delay: 1700ms; }

[data-delay="1750"] {
  transition-delay: 1750ms; }

[data-delay="1800"] {
  transition-delay: 1800ms; }

[data-delay="1850"] {
  transition-delay: 1850ms; }

[data-delay="1900"] {
  transition-delay: 1900ms; }

[data-delay="1950"] {
  transition-delay: 1950ms; }

[data-delay="2000"] {
  transition-delay: 2000ms; }

[data-delay="2050"] {
  transition-delay: 2050ms; }

[data-delay="2100"] {
  transition-delay: 2100ms; }

[data-delay="2150"] {
  transition-delay: 2150ms; }

[data-delay="2200"] {
  transition-delay: 2200ms; }

[data-delay="2250"] {
  transition-delay: 2250ms; }

[data-delay="2300"] {
  transition-delay: 2300ms; }

[data-delay="2350"] {
  transition-delay: 2350ms; }

[data-delay="2400"] {
  transition-delay: 2400ms; }

[data-delay="2450"] {
  transition-delay: 2450ms; }

[data-delay="2500"] {
  transition-delay: 2500ms; }

[data-delay="2550"] {
  transition-delay: 2550ms; }

[data-delay="2600"] {
  transition-delay: 2600ms; }

[data-delay="2650"] {
  transition-delay: 2650ms; }

[data-delay="2700"] {
  transition-delay: 2700ms; }

[data-delay="2750"] {
  transition-delay: 2750ms; }

[data-delay="2800"] {
  transition-delay: 2800ms; }

[data-delay="2850"] {
  transition-delay: 2850ms; }

[data-delay="2900"] {
  transition-delay: 2900ms; }

[data-delay="2950"] {
  transition-delay: 2950ms; }

[data-delay="3000"] {
  transition-delay: 3000ms; }

[data-delay="3050"] {
  transition-delay: 3050ms; }

[data-delay="3100"] {
  transition-delay: 3100ms; }

[data-delay="3150"] {
  transition-delay: 3150ms; }

[data-delay="3200"] {
  transition-delay: 3200ms; }

[data-delay="3250"] {
  transition-delay: 3250ms; }

[data-delay="3300"] {
  transition-delay: 3300ms; }

[data-delay="3350"] {
  transition-delay: 3350ms; }

[data-delay="3400"] {
  transition-delay: 3400ms; }

[data-delay="3450"] {
  transition-delay: 3450ms; }

[data-delay="3500"] {
  transition-delay: 3500ms; }

[data-delay="3550"] {
  transition-delay: 3550ms; }

[data-delay="3600"] {
  transition-delay: 3600ms; }

[data-delay="3650"] {
  transition-delay: 3650ms; }

[data-delay="3700"] {
  transition-delay: 3700ms; }

[data-delay="3750"] {
  transition-delay: 3750ms; }

[data-delay="3800"] {
  transition-delay: 3800ms; }

[data-delay="3850"] {
  transition-delay: 3850ms; }

[data-delay="3900"] {
  transition-delay: 3900ms; }

[data-delay="3950"] {
  transition-delay: 3950ms; }

[data-delay="4000"] {
  transition-delay: 4000ms; }

[data-delay="4050"] {
  transition-delay: 4050ms; }

[data-delay="4100"] {
  transition-delay: 4100ms; }

[data-delay="4150"] {
  transition-delay: 4150ms; }

[data-delay="4200"] {
  transition-delay: 4200ms; }

[data-delay="4250"] {
  transition-delay: 4250ms; }

[data-delay="4300"] {
  transition-delay: 4300ms; }

[data-delay="4350"] {
  transition-delay: 4350ms; }

[data-delay="4400"] {
  transition-delay: 4400ms; }

[data-delay="4450"] {
  transition-delay: 4450ms; }

[data-delay="4500"] {
  transition-delay: 4500ms; }

[data-delay="4550"] {
  transition-delay: 4550ms; }

[data-delay="4600"] {
  transition-delay: 4600ms; }

[data-delay="4650"] {
  transition-delay: 4650ms; }

[data-delay="4700"] {
  transition-delay: 4700ms; }

[data-delay="4750"] {
  transition-delay: 4750ms; }

[data-delay="4800"] {
  transition-delay: 4800ms; }

[data-delay="4850"] {
  transition-delay: 4850ms; }

[data-delay="4900"] {
  transition-delay: 4900ms; }

[data-delay="4950"] {
  transition-delay: 4950ms; }

[data-delay="5000"] {
  transition-delay: 5000ms; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
caption, article, aside, dialog, figure, footer, header,
hgroup, menu, nav, section,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

body {
  line-height: 1; }

article, aside, dialog, figure, footer, header,
hgroup, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

img {
  vertical-align: top; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted #000;
  cursor: help; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle; }

ul li,
ol li {
  list-style-type: none; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box; }

/*--------------------------------------------
  BASE
--------------------------------------------*/
@-ms-viewport {
  width: auto;
  initial-scale: 1; }

html {
  font-size: 1vw;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }
  @media screen and (min-width: 1920px) {
    html {
      font-size: 20px; } }
  @media screen and (max-width: 1919px) {
    html {
      font-size: 1vw; } }
  @media screen and (max-width: 1399px) {
    html {
      font-size: 1.125vw; } }
  @media screen and (max-width: 1199px) {
    html {
      font-size: 1.2vw; } }
  @media screen and (max-width: 991px) {
    html {
      font-size: 1.5625vw; } }
  @media screen and (max-width: 767px) {
    html {
      font-size: 2vw; } }
  @media screen and (max-width: 575px) {
    html {
      font-size: 3.75vw; } }

body {
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "BIZ UDPGothic", Meiryo, sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #231815;
  line-height: 1.875;
  -webkit-font-smoothing: antialiased; }
  @media screen and (max-width: 767px) {
    body {
      line-height: 1.6; } }

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

main {
  display: block; }

input,
button,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']),
select,
textarea {
  width: 100%;
  font-size: 1.4rem;
  border: solid 2px #828282;
  line-height: 1.3;
  display: inline-block;
  padding: .5em .75em; }
  input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']):focus,
  select:focus,
  textarea:focus {
    outline: none; }
  @media screen and (max-width: 767px) {
    input:not([type='radio']):not([type='checkbox']):not([type='button']):not([type='submit']):not([type='reset']),
    select,
    textarea {
      border-width: 1px; } }

::placeholder {
  color: #cccccc; }

select {
  padding: .75em 1.5em .75em .75em; }
  .ios select {
    -webkit-appearance: button;
    appearance: button; }

input[type="text"],
input[type="number"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="url"],
input[type="search"],
textarea {
  line-height: 1;
  vertical-align: middle; }
  input[type="text"]:fucus,
  input[type="number"]:fucus,
  input[type="password"]:fucus,
  input[type="email"]:fucus,
  input[type="tel"]:fucus,
  input[type="url"]:fucus,
  input[type="search"]:fucus,
  textarea:fucus {
    outline: none;
    background: #d4ebf7; }

textarea {
  min-height: 12.5em; }
  @media screen and (max-width: 767px) {
    textarea {
      min-height: 10em; } }

img {
  vertical-align: middle; }

table {
  width: 100%; }

a {
  color: #333;
  text-decoration: none; }
  @media screen and (min-width: 992px) {
    a {
      transition: all 0.6s; } }

svg {
  width: 1em;
  height: 1em;
  fill: #231815; }

h1, h2, h3, h4, h5, h6, th, dt, strong, b {
  font-weight: 700;
  line-height: 1; }

@-ms-viewport {
  width: device-width; }

[role="button"] {
  cursor: pointer; }

.hidden {
  display: none !important; }

.show {
  display: block !important; }

.visible-ss,
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important; }

.visible-ss-i,
.visible-xs-i,
.visible-sm-i,
.visible-md-i,
.visible-lg-i {
  display: none !important; }

.visible-ss-ib,
.visible-xs-ib,
.visible-sm-ib,
.visible-md-ib,
.visible-lg-ib {
  display: none !important; }

@media screen and (max-width: 575px) {
  .visible-ss {
    display: block !important; }
  table.visible-ss {
    display: table !important; }
  tr.visible-ss {
    display: table-row !important; }
  th.visible-ss,
  td.visible-ss {
    display: table-cell !important; }
  .visible-ss-i {
    display: inline !important; }
  .visible-ss-ib {
    display: inline-block !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .visible-xs {
    display: block !important; }
  table.visible-xs {
    display: table !important; }
  tr.visible-xs {
    display: table-row !important; }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important; }
  .visible-xs-i {
    display: inline !important; }
  .visible-xs-ib {
    display: inline-block !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important; }
  table.visible-sm {
    display: table !important; }
  tr.visible-sm {
    display: table-row !important; }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important; }
  .visible-sm-i {
    display: inline !important; }
  .visible-sm-ib {
    display: inline-block !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important; }
  table.visible-md {
    display: table !important; }
  tr.visible-md {
    display: table-row !important; }
  th.visible-md,
  td.visible-md {
    display: table-cell !important; }
  .visible-md-i {
    display: inline !important; }
  .visible-md-ib {
    display: inline-block !important; } }

@media screen and (min-width: 1200px) {
  .visible-lg {
    display: block !important; }
  table.visible-lg {
    display: table !important; }
  tr.visible-lg {
    display: table-row !important; }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important; }
  .visible-lg-i {
    display: inline !important; }
  .visible-lg-ib {
    display: inline-block !important; } }

@media screen and (max-width: 575px) {
  .hidden-ss {
    display: none !important; } }

@media screen and (min-width: 576px) and (max-width: 767px) {
  .hidden-xs {
    display: none !important; } }

@media screen and (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important; } }

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important; } }

@media screen and (min-width: 1200px) {
  .hidden-lg {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .visible-sp {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .visible-tb {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .visible-pc {
    display: none !important; } }

@media screen and (max-width: 767px) {
  .hidden-sp {
    display: none !important; } }

@media screen and (max-width: 991px) {
  .hidden-tb {
    display: none !important; } }

@media screen and (min-width: 992px) {
  .hidden-pc {
    display: none !important; } }

/*--------------------------------------------
  plugins
--------------------------------------------*/
/*
SP MENU SAMPLE - HTML BTN + JS
------------------------------

<nav class="slideout-menu" id="js-so_menu">
  <div class="menu-inner">
  </div>
</nav>

<div class="slideout-panel" id="js-so_panel"></div>

<header></header>


<button type="button" class="slideout-hamburger" id="js-so-toggle">
  <span class="sr-only">MENU</span>
  <span class="hamburger-icon"></span>
</button>


// slideout JS
var slideout = new Slideout({
  'panel': document.getElementById('js-so_panel'),
  'menu': document.getElementById('js-so_menu'),
  'duration': 10,
});
var nodeList = document.querySelectorAll('#js-so-toggle, #js-so_panel');
var node = Array.prototype.slice.call(nodeList,0);
node.forEach(function(elem, index){
  elem.addEventListener('click', function() {
    slideout.toggle();
  });
});
*/
.slideout-open,
.slideout-open body {
  overflow: hidden; }

.slideout-menu {
  position: fixed;
  top: 0;
  right: -100%;
  bottom: 0;
  z-index: 101;
  width: 100%;
  background-color: #333;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1); }

.slideout-open .slideout-menu {
  right: 0; }

.slideout-menu .menu-inner {
  padding: 6em 1.5em; }

.slideout-panel {
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  will-change: transform;
  transform: translateX(0) !important;
  opacity: 0;
  transition: opacity 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none; }

.slideout-open .slideout-panel {
  z-index: 100;
  opacity: 1;
  pointer-events: auto; }

/*!------------------------------------*\
    Hamburger
\*!------------------------------------*/
.slideout-hamburger {
  display: block;
  width: 4.5em;
  height: 100%;
  padding: 2em 1em;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  border: 0;
  outline: 0;
  background-color: #222;
  cursor: pointer; }

.hamburger-icon {
  position: relative;
  display: block; }

.hamburger-icon,
.hamburger-icon::before,
.hamburger-icon::after {
  width: 100%;
  height: .2rem;
  -webkit-transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 0.6s cubic-bezier(0.19, 1, 0.22, 1);
  background-color: #fff; }

.hamburger-icon::before,
.hamburger-icon::after {
  content: '';
  display: block;
  position: absolute;
  top: -.6em;
  left: 0; }

.hamburger-icon::after {
  top: .6em; }

.slideout-open .hamburger-icon {
  background-color: transparent; }

.slideout-open .hamburger-icon::before,
.slideout-open .hamburger-icon::after {
  top: 0; }

.slideout-open .hamburger-icon::before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg); }

.slideout-open .hamburger-icon::after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg); }

.sr-only {
  position: absolute;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/plugins/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"), url("../fonts/slick.woff") format("woff"), url("../fonts/slick.ttf") format("truetype"), url("../fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/*--------------------------------------------
  module
--------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?fsx2ee");
  src: url("../fonts/icomoon.eot?fsx2ee#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?fsx2ee") format("truetype"), url("../fonts/icomoon.woff?fsx2ee") format("woff"), url("../fonts/icomoon.svg?fsx2ee#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 94%;
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto; }
  .c-inner.-base {
    max-width: 1200px; }

.c-key_under {
  position: relative; }
  .c-key_under__img.c-img_ofi {
    padding-top: 26%;
    z-index: 1; }
  .c-key_under__text {
    position: absolute;
    left: 0;
    top: -.1em;
    width: 100%;
    color: #fff;
    font-family: "Lato", sans-serif;
    z-index: 2;
    font-size: 11rem;
    line-height: .8;
    font-weight: 300; }
  @media screen and (max-width: 991px) {
    .c-key_under__text {
      font-size: 9rem; } }
  @media screen and (max-width: 767px) {
    .c-key_under__img.c-img_ofi {
      padding-top: 35%; } }
  @media screen and (max-width: 575px) {
    .c-key_under__img.c-img_ofi {
      padding-top: 45%; }
    .c-key_under__text {
      font-size: 4.5rem;
      top: -.05em; } }

.c-bg_gray {
  background: #ebebeb; }

.c-lead {
  line-height: 2.5; }
  @media screen and (max-width: 575px) {
    .c-lead {
      line-height: 2; } }

/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-lead_ttl {
  font-size: 4.5rem;
  margin-bottom: .55em; }
  .c-lead_ttl .ttl {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    line-height: 1;
    margin-bottom: .65em; }
    .c-lead_ttl .ttl:last-child {
      margin-bottom: 0; }
    .c-lead_ttl .ttl span {
      display: block; }
    .c-lead_ttl .ttl .en {
      line-height: .8;
      font-family: "Lato", sans-serif;
      letter-spacing: .05em; }
    .c-lead_ttl .ttl .ja {
      line-height: 1;
      font-size: .333em;
      margin-left: 1.5em;
      letter-spacing: .5em;
      font-weight: 400; }
  .c-lead_ttl .catch {
    font-size: .47em;
    font-weight: 400;
    letter-spacing: .5em; }
    .c-lead_ttl .catch.-sm {
      font-size: .355em; }
  @media screen and (max-width: 991px) {
    .c-lead_ttl {
      font-size: 3.6rem; } }
  @media screen and (max-width: 767px) {
    .c-lead_ttl .ttl {
      display: block; }
      .c-lead_ttl .ttl .en {
        line-height: 1.1; }
      .c-lead_ttl .ttl .ja {
        margin-left: 0;
        margin-top: 1em; }
    .c-lead_ttl .catch {
      letter-spacing: .4em;
      line-height: 1.4; } }
  @media screen and (max-width: 575px) {
    .c-lead_ttl {
      font-size: 2.6rem; }
      .c-lead_ttl .ttl .ja {
        font-size: .5em; }
      .c-lead_ttl .catch {
        font-size: .5em;
        letter-spacing: 0.15em; }
        .c-lead_ttl .catch.-sm {
          font-size: .4em; } }

.c-main_ttl {
  font-size: 3.9rem;
  line-height: 1;
  margin-bottom: .5em;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  letter-spacing: .01em; }
  @media screen and (max-width: 991px) {
    .c-main_ttl {
      font-size: 3.2rem; } }
  @media screen and (max-width: 767px) {
    .c-main_ttl {
      font-size: 2.4rem;
      line-height: 1.1; } }

.c-bg_ttl {
  color: #E6E6EA;
  font-family: "Lato", sans-serif;
  line-height: 1;
  font-size: 10.75rem;
  font-weight: 400;
  margin-bottom: .35em;
  white-space: nowrap;
  overflow: hidden; }
  .c-bg_ttl.-wh {
    color: #fff; }
  @media screen and (max-width: 1199px) {
    .c-bg_ttl {
      font-size: 9.35rem; } }
  @media screen and (max-width: 991px) {
    .c-bg_ttl {
      font-size: 7.25rem; } }
  @media screen and (max-width: 767px) {
    .c-bg_ttl {
      font-size: 5.6rem; } }
  @media screen and (max-width: 575px) {
    .c-bg_ttl {
      font-size: 4rem;
      white-space: pre-wrap; } }

.c-sub_ttl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  font-size: 1.5rem;
  margin-bottom: 1.25em; }
  .c-sub_ttl .lg {
    margin-right: 1.75em; }
  .c-sub_ttl .sm {
    font-size: .73em;
    font-weight: 400; }
  @media screen and (max-width: 767px) {
    .c-sub_ttl {
      display: block; }
      .c-sub_ttl span {
        display: block; }
      .c-sub_ttl .lg {
        padding-top: .25em;
        margin-bottom: .5em; } }
  @media screen and (max-width: 575px) {
    .c-sub_ttl {
      margin-bottom: 1em; } }

/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }
  .c-btnwrap.-fx .link_btn {
    width: calc((100% - 4rem) / 2); }
    .c-btnwrap.-fx .link_btn a {
      display: block; }
    @media screen and (min-width: 768px) {
      .c-btnwrap.-fx .link_btn:nth-child(n+2) {
        margin-left: 4rem; } }
    @media screen and (max-width: 767px) {
      .c-btnwrap.-fx .link_btn:nth-child(n+2) {
        margin-top: 2rem; } }
  @media screen and (max-width: 767px) {
    .c-btnwrap {
      display: block; }
      .c-btnwrap.-fx .link_btn {
        width: 100%; } }

.c-btn01 {
  line-height: 1;
  text-align: center; }
  .c-btn01 a,
  .c-btn01 .link_btn {
    position: relative;
    display: inline-block;
    background: #231815;
    padding: 1em 5em;
    color: #fff;
    font-family: "Lato", sans-serif;
    overflow: hidden;
    cursor: pointer; }
    .c-btn01 a span,
    .c-btn01 .link_btn span {
      position: relative;
      z-index: 2; }
    .c-btn01 a:before,
    .c-btn01 .link_btn:before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      background: #B5070D;
      left: 0;
      top: 0;
      z-index: 1;
      transform: scaleY(0);
      transition: inherit; }
    .c-btn01 a:after,
    .c-btn01 .link_btn:after {
      position: absolute;
      content: "＞";
      right: 1em;
      top: 50%;
      transform: translateY(-50%);
      z-index: 2; }
    @media screen and (min-width: 992px) {
      .c-btn01 a:hover:before,
      .c-btn01 .link_btn:hover:before {
        transform: scaleY(1); } }

/*--------------------------------------------
  TABLE
--------------------------------------------*/
@media screen and (max-width: 991px) {
  .c-tbl_responsive .rwd_sheet {
    overflow-x: auto;
    overflow-y: visible; }
    .c-tbl_responsive .rwd_sheet table {
      min-width: 100%;
      width: auto; }
      .c-tbl_responsive .rwd_sheet table th,
      .c-tbl_responsive .rwd_sheet table td {
        white-space: nowrap; }
  .c-tbl_responsive:after {
    display: block;
    content: "横にスクロールできます";
    text-align: center;
    margin-top: .5em; } }

/*--------------------------------------------
 PARTS - NEWS
--------------------------------------------*/
.c-news_list li {
  border-top: solid 1px #231815;
  border-bottom: solid 1px #231815;
  margin-top: -1px; }
  .c-news_list li a {
    position: relative;
    padding: 1.5em;
    align-items: flex-start; }
    .c-news_list li a > * {
      position: relative;
      z-index: 2;
      padding: .25em 0 .15em;
      line-height: 1.5; }
    .c-news_list li a:before {
      position: absolute;
      content: "";
      top: 0;
      left: initial;
      right: 0;
      width: 0;
      height: 100%;
      background: #f9f9f9;
      transition: .6s; }
    .c-news_list li a time {
      width: 7em;
      font-family: "Lato", sans-serif; }
    .c-news_list li a .badge {
      width: 4em;
      margin-right: 2em; }
      .c-news_list li a .badge span {
        display: block;
        width: 100%;
        background: #fff;
        border: solid 1px #231815;
        font-family: "Lato", sans-serif;
        text-align: center; }
    .c-news_list li a .title {
      width: calc(100% - 13em);
      font-weight: 400; }
    @media screen and (min-width: 992px) {
      .c-news_list li a:hover:before {
        left: 0;
        right: initial;
        width: 100%; } }

@media screen and (max-width: 767px) {
  .c-news_list li a .title {
    width: 100%;
    padding-top: 1em; } }

@media screen and (max-width: 575px) {
  .c-news_list li a {
    padding: 1em; }
    .c-news_list li a .badge {
      font-size: .95rem;
      padding: .2em .25em .15em; } }

/*--------------------------------------------
 PARTS - MAGAZINE
--------------------------------------------*/
.c-magazine_list li {
  width: 22%;
  margin-right: 4%; }
  @media screen and (max-width: 1199px) {
    .c-magazine_list li {
      width: 23.5%;
      margin-right: 2%; } }
  @media screen and (min-width: 768px) {
    .c-magazine_list li:nth-child(4n) {
      margin-right: 0; }
    .c-magazine_list li:nth-child(n+5) {
      margin-top: 3rem; } }
  @media screen and (max-width: 767px) {
    .c-magazine_list li {
      width: 30%;
      margin-right: 5%; } }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    .c-magazine_list li:nth-child(3n) {
      margin-right: 0; }
    .c-magazine_list li:nth-child(n+4) {
      margin-top: 3rem; } }
  @media screen and (max-width: 575px) {
    .c-magazine_list li {
      width: 47%;
      margin-right: 6%; }
      .c-magazine_list li:nth-child(even) {
        margin-right: 0; }
      .c-magazine_list li:nth-child(n+3) {
        margin-top: 3rem; } }

.c-magazine_list__inner {
  display: block; }
  @media screen and (min-width: 992px) {
    .c-magazine_list__inner:hover {
      opacity: .5; } }

.c-magazine_list__info {
  line-height: 1.5;
  margin-bottom: .75em;
  font-size: .9rem; }
  .c-magazine_list__info > * {
    display: inline-block;
    vertical-align: middle; }
  .c-magazine_list__info .badge {
    width: 4em;
    background: #fff;
    border: solid 1px #231815;
    font-family: "Lato", sans-serif;
    text-align: center;
    margin-left: 1.25em; }

.c-magazine_list__img img {
  max-width: 100%; }

.c-magazine_list__title {
  margin-top: 1em; }
  .c-magazine_list__title .category {
    line-height: 1.4; }
  .c-magazine_list__title h2 {
    line-height: 1.4;
    font-weight: 400; }

/*--------------------------------------------
  PARTS - FORM
--------------------------------------------*/
.c-form_box table tr {
  border-bottom: solid 0.75rem #fff; }

.c-form_box__step {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.5rem;
  counter-reset: step 0;
  margin-bottom: 6.575rem; }
  .c-form_box__step:after {
    position: absolute;
    content: "";
    left: 1em;
    top: 50%;
    width: calc(100% - 2em);
    height: 2px;
    transform: translateY(-50%);
    background: #828282;
    z-index: 1; }
  .c-form_box__step li {
    position: relative;
    counter-increment: step 1;
    z-index: 2;
    background: #fff;
    padding: 0 1.5em;
    font-size: 1.5rem; }
    .c-form_box__step li:nth-last-child(n+2):before {
      content: "STEP" counter(step);
      margin-right: .5em; }
    .c-form_box__step li:first-child {
      padding-left: 0; }
    .c-form_box__step li:last-child {
      padding-right: 0; }

.c-form_box__label {
  text-align: left;
  line-height: 1.35;
  vertical-align: top;
  width: 20%; }
  .c-form_box__label .form_label .ja {
    padding-top: .5em; }
  .c-form_box__label .form_label .en {
    font-size: .85rem;
    font-family: "Lato", sans-serif; }

.c-form_box__input {
  width: 80%;
  vertical-align: top;
  padding-left: 1em; }
  .c-form_box__input * {
    width: 100%; }

.c-form_box__privacy {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  font-size: 1.25rem;
  line-height: 1; }
  .c-form_box__privacy > span {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: .5em; }
  .c-form_box__privacy label {
    display: block;
    cursor: pointer; }
  .c-form_box__privacy a {
    text-decoration: underline; }
    @media screen and (min-width: 992px) {
      .c-form_box__privacy a:hover {
        text-decoration: none; } }
  .c-form_box__privacy input[type="checkbox"] {
    display: none; }
    .c-form_box__privacy input[type="checkbox"] + span {
      position: relative;
      width: 100%;
      height: 100%;
      display: block; }
      .c-form_box__privacy input[type="checkbox"] + span:before, .c-form_box__privacy input[type="checkbox"] + span:after {
        position: absolute;
        content: ""; }
      .c-form_box__privacy input[type="checkbox"] + span:before {
        left: 0;
        top: 0;
        width: 1em;
        height: 1em;
        border: solid 2px #828282; }
      .c-form_box__privacy input[type="checkbox"] + span:after {
        left: 0;
        top: 0;
        width: 1em;
        height: .5em;
        border-left: solid 4px #B5070D;
        border-bottom: solid 4px #B5070D;
        transform: rotate(-45deg);
        visibility: hidden; }
    .c-form_box__privacy input[type="checkbox"]:checked + span:after {
      visibility: visible; }

.c-form_box__btn {
  margin-top: 7.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; }
  .c-form_box__btn .link_btn {
    display: inline-block;
    padding: 1em 0;
    text-align: center;
    width: 15rem;
    background: #231815;
    cursor: pointer;
    color: #fff;
    border: solid 1px #231815;
    transition: .6s;
    border-radius: 0; }
    .c-form_box__btn .link_btn.-back {
      background: #aaa0a5;
      border: solid 1px #aaa0a5;
      margin-left: 2em; }
    @media screen and (min-width: 992px) {
      .c-form_box__btn .link_btn:hover {
        background: #fff;
        color: #231815; }
      .c-form_box__btn .link_btn.-back:hover {
        background: #fff;
        color: #aaa0a5; } }

.c-form_box.-confirm .c-form_box__privacy {
  display: none; }

@media screen and (min-width: 992px) {
  .c-form_box {
    max-width: 75%;
    margin: 0 auto; } }

@media screen and (max-width: 1199px) {
  .c-form_box__step {
    margin-bottom: 3.5rem; }
  .c-form_box__privacy {
    margin-top: 3rem; }
  .c-form_box__btn {
    margin-top: 4rem; } }

@media screen and (max-width: 767px) {
  .c-form_box__label {
    width: 30%; }
  .c-form_box__input {
    width: 70%; } }

@media screen and (max-width: 575px) {
  .c-form_box table tr {
    display: block;
    border-bottom: none; }
    .c-form_box table tr:nth-child(n+2) {
      margin-top: 2rem; }
  .c-form_box__step {
    margin-bottom: 2rem; }
    .c-form_box__step li {
      text-align: center;
      padding: 0 1em;
      font-size: 1.25rem; }
      .c-form_box__step li:nth-last-child(n+2):before {
        display: block;
        margin-right: 0em; }
  .c-form_box__label {
    display: block;
    width: 100%;
    line-height: 1;
    margin-bottom: .75em; }
    .c-form_box__label .form_label {
      display: flex;
      justify-content: flex-start;
      align-items: center; }
      .c-form_box__label .form_label .ja {
        padding-top: 0; }
      .c-form_box__label .form_label .en {
        margin-left: 1.5em; }
  .c-form_box__input {
    width: 100%;
    display: block;
    padding-left: 0; }
  .c-form_box__privacy {
    margin-top: 2rem;
    font-size: 1.1rem; }
    .c-form_box__privacy input[type="checkbox"] + span {
      padding-left: 1.25em; }
  .c-form_box__btn {
    display: block;
    margin-top: 3rem;
    text-align: center; }
    .c-form_box__btn .link_btn {
      width: 80%; }
      .c-form_box__btn .link_btn.-back {
        margin-left: 0;
        margin-top: 1.5rem; } }

/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden; }
  .c-gmap iframe,
  .c-gmap object,
  .c-gmap embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden; }
  .c-img_ofi .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none; }
    .c-img_ofi .ofi.cover {
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;'; }
    .c-img_ofi .ofi.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;'; }

/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto; }

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto; }

.c-ta_left {
  text-align: left; }

.c-ta_center {
  text-align: center; }

.c-ta_right {
  text-align: right; }

@media screen and (min-width: 576px) {
  .c-ta_justify {
    display: flex;
    justify-content: space-between;
    align-items: center; } }

.c-pe_none {
  pointer-events: none; }

.c-flex {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap; }

@media screen and (min-width: 992px) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default; } }

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor strong {
  font-weight: bold; }

.wp-editor em {
  font-style: italic; }

.wp-editor blockquote {
  display: block;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 40px;
  -webkit-margin-end: 40px; }

.wp-editor img.alignleft {
  margin: 0 auto 0 0; }

.wp-editor img.aligncenter {
  display: block;
  margin: 0 auto; }

.wp-editor img.alignright {
  display: block;
  margin: 0 0 0 auto; }

.wp-editor img[class*="wp-image-"],
.wp-editor img[class*="attachment-"] {
  height: auto;
  max-width: 100%; }

.wp-editor hr {
  margin: 2em 0; }

.wp-editor * + p {
  margin-top: 1em; }

.wp-editor * + ul,
.wp-editor * + ol,
.wp-editor * + .c-tbl_responsive {
  margin-top: 2em; }

.wp-editor * + h1,
.wp-editor * + h2,
.wp-editor * + h3,
.wp-editor * + h4,
.wp-editor * + h5,
.wp-editor * + h6 {
  margin-top: 1.5em; }

.wp-editor h2,
.wp-editor h3,
.wp-editor h4,
.wp-editor h5,
.wp-editor h6 {
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: .1em; }

.wp-editor h2 {
  font-size: 1.4rem; }

.wp-editor h3 {
  font-size: 1.2rem; }

.wp-editor h4 {
  font-size: 1rem; }

.wp-editor h5 {
  font-size: .9rem; }

.wp-editor h6 {
  font-size: .85rem; }

.wp-editor a {
  color: #e50012;
  word-break: break-all; }
  .wp-editor a:focus {
    opacity: .7; }
  @media screen and (min-width: 992px) {
    .wp-editor a:hover {
      opacity: .7; } }

.wp-editor ul,
.wp-editor ol {
  padding-left: 2em;
  line-height: 1.5; }
  .wp-editor ul > li:not(:first-child),
  .wp-editor ol > li:not(:first-child) {
    margin-top: .6em; }

.wp-editor ul > li {
  list-style-type: disc; }

.wp-editor ol > li {
  list-style-type: decimal; }

.wp-editor table tr > th,
.wp-editor table tr > td {
  padding: 1em;
  border: 1px solid #ddd; }

.wp-editor table tr > th {
  background: #eee; }

@media screen and (max-width: 991px) {
  .wp-editor * + p,
  .wp-editor * + ul,
  .wp-editor * + ol,
  .wp-editor * + .c-tbl_responsive {
    margin-top: 1.2em; }
  .wp-editor * + h1,
  .wp-editor * + h2,
  .wp-editor * + h3,
  .wp-editor * + h4,
  .wp-editor * + h5,
  .wp-editor * + h6 {
    margin-top: 2.5em; }
  .wp-editor ul > li:not(:first-child),
  .wp-editor ol > li:not(:first-child) {
    margin-top: .4em; } }

/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi {
  margin-top: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1; }
  .wp-pagenavi > * {
    width: 2em;
    height: 2em;
    line-height: 2;
    text-align: center;
    border-radius: 50%; }
    .wp-pagenavi > *:nth-child(n+2) {
      margin-left: 1em; }
  .wp-pagenavi .current {
    background: #B5070D;
    color: #fff; }
  @media screen and (min-width: 992px) {
    .wp-pagenavi a:hover {
      background: #B5070D;
      color: #fff; } }
  @media screen and (max-width: 991px) {
    .wp-pagenavi {
      margin-top: 3rem; } }

/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
.l-page {
  padding-top: 5rem; }
  @media screen and (max-width: 991px) {
    .l-page {
      padding-top: 7rem; } }
  @media screen and (max-width: 575px) {
    .l-page {
      padding-top: 4.6rem; } }

.l-breadcrumb {
  line-height: 1; }
  .l-breadcrumb__list {
    justify-content: flex-end;
    align-items: center; }
    .l-breadcrumb__list li {
      position: relative;
      padding-right: 2em; }
      .l-breadcrumb__list li:after {
        position: absolute;
        content: "";
        right: .5em;
        top: 50%;
        width: 1em;
        height: 2px;
        background: #b7b8b9;
        transform: translateY(-50%); }
      .l-breadcrumb__list li a {
        position: relative;
        padding: 5px 0; }
        .l-breadcrumb__list li a:after {
          position: absolute;
          content: "";
          left: 0;
          bottom: 0;
          width: 100%;
          height: 1px;
          background: #231815;
          transition: all 0.6s; }
        .l-breadcrumb__list li a:hover {
          color: #b7b8b9; }
          .l-breadcrumb__list li a:hover:after {
            background: #b7b8b9;
            transform: scaleX(0); }
      .l-breadcrumb__list li:last-child {
        padding-right: 0; }
        .l-breadcrumb__list li:last-child:after {
          display: none; }
        .l-breadcrumb__list li:last-child a {
          pointer-events: none;
          color: #b7b8b9; }
          .l-breadcrumb__list li:last-child a:after {
            display: none; }

/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header {
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0 0.25rem 0.25rem rgba(0, 0, 0, 0.1); }
  .l-header__inner {
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0; }
  .l-header__logo a {
    display: block; }
    @media screen and (min-width: 992px) {
      .l-header__logo a:hover {
        opacity: .5; } }
  .l-header__logo img {
    width: auto;
    height: 3rem; }
  .l-header__toggle {
    position: relative;
    z-index: 102; }
    .l-header__toggle .so_toggle {
      position: absolute;
      background: transparent;
      width: 3.6rem;
      height: 3.6rem;
      padding: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }
      .l-header__toggle .so_toggle .hamburger-icon {
        background: transparent;
        top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before, .l-header__toggle .so_toggle .hamburger-icon:after {
          background: #231815;
          top: 0; }
        .l-header__toggle .so_toggle .hamburger-icon:before {
          transform: translateY(-4px); }
        .l-header__toggle .so_toggle .hamburger-icon:after {
          transform: translateY(4px); }
  .slideout-open .l-header__toggle {
    background: rgba(255, 255, 255, 0); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before, .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      background: #fff; }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:before {
      transform: rotate(45deg); }
    .slideout-open .l-header__toggle .so_toggle .hamburger-icon:after {
      transform: rotate(-45deg); }
  @media screen and (max-width: 991px) {
    .l-header__toggle {
      width: 5rem;
      height: 5rem; }
      .l-header__toggle .so_toggle {
        position: absolute;
        width: 72%;
        height: 72%; } }
  @media screen and (max-width: 575px) {
    .l-header__inner {
      padding: .5rem 0; }
    .l-header__logo img {
      height: 2.5rem; }
    .l-header__toggle {
      width: 3.6rem;
      height: 3.6rem; } }

/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi {
  font-size: .9rem;
  font-family: "Lato", sans-serif;
  line-height: 1;
  transition-property: transform; }
  .l-gnavi li a {
    display: block;
    padding: .5em 0;
    letter-spacing: .1em;
    transition-property: color; }
    .l-gnavi li a span {
      display: block;
      transition: all 0.6s;
      transition-property: transform;
      transform: translateY(0); }
    @media screen and (min-width: 992px) {
      .l-gnavi li a:hover span {
        color: #B5070D;
        transform: translateY(-0.5em); } }
  .l-gnavi li:nth-child(n+2) {
    margin-left: 2.5rem; }
  @media screen and (min-width: 992px) {
    .l-gnavi {
      position: static;
      background: transparent;
      width: auto;
      overflow: hidden; } }
  @media screen and (max-width: 1199px) {
    .l-gnavi {
      font-size: 1rem; } }
  @media screen and (max-width: 991px) {
    .l-gnavi {
      background: rgba(0, 0, 0, 0.75);
      padding: 7.5rem;
      font-size: 2.5rem;
      right: 0;
      transform: translateX(100%); }
      .l-gnavi ul {
        display: block; }
      .l-gnavi li:nth-child(n+2) {
        margin-left: 0;
        margin-top: 1em; }
      .l-gnavi li a {
        color: #fff;
        padding: 0; }
      .slideout-open .l-gnavi {
        transform: translateX(0); } }
  @media screen and (max-width: 767px) {
    .l-gnavi {
      padding: 7.5rem 5rem; } }
  @media screen and (max-width: 575px) {
    .l-gnavi {
      padding: 7.5rem 3rem; } }

/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer {
  padding: 6.5rem 0;
  background: #b4b4ba;
  color: #fff; }
  .l-footer__logo {
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 1.25em; }
  .l-footer__contact {
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem; }
    .l-footer__contact .mail {
      width: 44%; }
      .l-footer__contact .mail a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: solid 1px #fff;
        font-size: 1rem;
        color: #fff;
        line-height: 1;
        padding: 1.5em 1em; }
        .l-footer__contact .mail a:before {
          display: block;
          font-size: 3em;
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e91b"; }
        .l-footer__contact .mail a span {
          display: block;
          margin-left: 1em;
          text-align: left; }
        @media screen and (min-width: 1200px) {
          .l-footer__contact .mail a {
            max-width: 75%; } }
        @media screen and (min-width: 992px) {
          .l-footer__contact .mail a:hover {
            background: #fff;
            color: #b4b4ba; } }
    .l-footer__contact .tel {
      width: 50%;
      line-height: 1; }
      .l-footer__contact .tel p {
        margin-bottom: .75em; }
      .l-footer__contact .tel a {
        position: relative;
        font-size: 4rem;
        padding-left: .75em;
        padding-bottom: .125em;
        color: #fff;
        display: block;
        font-weight: 700;
        letter-spacing: -0.05em; }
        .l-footer__contact .tel a:before {
          position: absolute;
          left: 0;
          top: 0;
          font-size: 1em;
          transform: scale(0.815);
          font-family: 'icomoon' !important;
          speak: never;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e91c"; }
  .l-footer__office li {
    justify-content: space-between;
    align-items: stretch; }
    .l-footer__office li:nth-child(n+2) {
      margin-top: 2rem; }
    .l-footer__office li .name {
      width: 44%;
      font-size: 2rem;
      line-height: 1; }
      .l-footer__office li .name .en {
        font-weight: 700;
        letter-spacing: .1em; }
      .l-footer__office li .name .ja {
        font-size: .5em;
        margin-top: .5em; }
    .l-footer__office li .info {
      width: 50%;
      font-size: 1.1rem;
      line-height: 1.875; }
      .l-footer__office li .info span {
        display: inline-block; }
        .l-footer__office li .info span:nth-last-child(n+2) {
          margin-right: .75em; }
  .l-footer__copy {
    margin-top: 3rem;
    text-align: center; }
  @media screen and (max-width: 1199px) {
    .l-footer__contact .mail a {
      font-size: 1.125em; } }
  @media screen and (max-width: 991px) {
    .l-footer {
      padding: 4rem 0; }
      .l-footer__contact .mail {
        width: 40%; }
        .l-footer__contact .mail a:before {
          font-size: 2.5rem; }
      .l-footer__contact .tel {
        width: 52%; }
        .l-footer__contact .tel a {
          font-size: 3.6rem; }
      .l-footer__office .info span.postal {
        display: block;
        margin-right: 0; } }
  @media screen and (max-width: 767px) {
    .l-footer__logo {
      text-align: center; }
    .l-footer__contact {
      display: block; }
      .l-footer__contact .mail {
        width: 100%;
        margin-bottom: 3rem; }
        .l-footer__contact .mail a {
          text-align: center; }
          .l-footer__contact .mail a span {
            position: relative;
            display: inline-block;
            font-size: 1.25rem; }
      .l-footer__contact .tel {
        width: 100%;
        text-align: center; }
        .l-footer__contact .tel a {
          display: inline-block; }
    .l-footer__office li {
      display: block; }
      .l-footer__office li:nth-child(n+2) {
        margin-top: 3rem; }
      .l-footer__office li .name {
        position: relative;
        width: 100%;
        margin-bottom: .35em; }
        .l-footer__office li .name .ja {
          margin-top: .75em; }
      .l-footer__office li .info {
        width: 100%; }
        .l-footer__office li .info span.postal {
          display: inline-block; } }
  @media screen and (max-width: 575px) {
    .l-footer {
      padding: 4rem 0; }
      .l-footer__logo {
        font-size: 1.75rem;
        margin-bottom: 1em; }
      .l-footer__contact {
        margin-bottom: 2rem; }
        .l-footer__contact .mail {
          margin-bottom: 2rem; }
        .l-footer__contact .tel p {
          line-height: 1.6; }
        .l-footer__contact .tel a {
          font-size: 2.4rem; }
      .l-footer__office li:nth-child(n+2) {
        margin-top: 2rem; }
      .l-footer__office li .info span:nth-last-child(n+2) {
        margin-right: .5em; }
      .l-footer__office li .info span.postal {
        display: block; } }

/*--------------------------------------------
  PAGE
--------------------------------------------*/
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key {
  position: relative;
  width: 100%;
  height: 52vw; }
  @media screen {
    .p-idx_key__inner:not(.slick-initialized) {
      display: none; } }

.p-idx_key_slide__inner {
  position: relative;
  display: block;
  height: 52vw;
  overflow: hidden; }

.p-idx_key_slide__img {
  position: relative;
  z-index: 1;
  padding-top: 52%; }

.p-idx_key_slide__text {
  position: absolute;
  color: #fff;
  z-index: 2;
  width: 94%;
  left: 3%; }
  .p-idx_key_slide__text h2 {
    line-height: 1;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    letter-spacing: .03em; }
    .p-idx_key_slide__text h2 span {
      display: block;
      opacity: 0;
      transition-property: opacity,transform;
      transition-timing-function: ease-in; }

.p-idx_key_slide.-slide01 .p-idx_key_slide__text {
  top: 50%;
  transform: translateY(-50%);
  overflow: hidden; }
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 {
    font-size: 12.5rem; }
    .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 span {
      transform: translateX(0.5em); }

.p-idx_key_slide.-slide02 .p-idx_key_slide__text {
  bottom: 1rem; }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 {
    font-size: 12rem; }
    .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 span {
      transform: translateY(0.5em); }
      .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 span.sm {
        padding-left: .025em;
        font-size: .56em;
        letter-spacing: .025em; }
      .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 span.lg {
        font-size: 1em; }

.p-idx_key_slide.-slide03 .p-idx_key_slide__text {
  top: 3rem; }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 {
    text-align: center;
    font-size: 12rem; }
    .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 span {
      transform: translateY(0.5em); }

.p-idx_key_slide.-slide04 {
  background: #fff; }
  @media screen and (min-width: 992px) {
    .p-idx_key_slide.-slide04 a:hover {
      opacity: .5; } }
  .p-idx_key_slide.-slide04 .p-idx_key_slide__text {
    width: 90%;
    left: 5%;
    bottom: 5rem; }
    .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 {
      font-size: 2.25rem;
      font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro", "BIZ UDPGothic", Meiryo, sans-serif; }
      .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 span {
        transform: translateY(0.5em); }
        .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 span:nth-child(n+2) {
          margin-top: .75em; }

@media screen {
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 span {
    transform: translateX(0.5em); }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 span {
    transform: translateY(0.5em); }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 span {
    transform: translateY(0.5em); }
  .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 span {
    transform: translateY(0.5em); }
  .slick-slide.is-anime .p-idx_key_slide__img img {
    animation: fadezoom 8s 0s forwards; }
  .slick-slide.is-anime .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 span {
    opacity: 1;
    transform: translateX(0); }
  .slick-slide.is-anime .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 span {
    opacity: 1;
    transform: translateY(0); }
  .slick-slide.is-anime .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 span {
    opacity: 1;
    transform: translateY(0); }
  .slick-slide.is-anime .p-idx_key_slide.-slide04 .p-idx_key_slide__text .logo {
    opacity: 1; }
  .slick-slide.is-anime .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 span {
    opacity: 1;
    transform: translateY(0); } }

@media screen and (max-width: 1199px) {
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 {
    font-size: 10rem; }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 {
    font-size: 10rem; }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 {
    font-size: 10rem; }
  .p-idx_key_slide.-slide04 .p-idx_key_slide__text .logo {
    width: 20rem; }
  .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 {
    font-size: 2.25rem; } }

@media screen and (max-width: 991px) {
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 {
    font-size: 8rem; }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 {
    font-size: 8rem; }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 {
    font-size: 8rem; }
  .p-idx_key_slide.-slide04 .p-idx_key_slide__text {
    left: 3%;
    bottom: 4rem; }
    .p-idx_key_slide.-slide04 .p-idx_key_slide__text h2 {
      font-size: 1.75rem;
      margin-top: 1em; } }

@media screen and (max-width: 767px) {
  .p-idx_key_slide__inner {
    height: 56vw; }
  .p-idx_key_slide__img {
    padding-top: 56%; }
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 {
    font-size: 7rem; }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 {
    font-size: 7rem; }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 {
    font-size: 6.5rem; } }

@media screen and (max-width: 575px) {
  .p-idx_key_slide.-slide01 .p-idx_key_slide__text h2 {
    font-size: 4rem; }
  .p-idx_key_slide.-slide02 .p-idx_key_slide__text h2 {
    font-size: 4rem; }
  .p-idx_key_slide.-slide03 .p-idx_key_slide__text {
    top: 2rem; }
    .p-idx_key_slide.-slide03 .p-idx_key_slide__text h2 {
      font-size: 3.5rem; } }

@keyframes fadezoom {
  0% {
    transform: scale(1); }
  100% {
    transform: scale(1.1); } }

/*-------------------------------
  INDEX > ABOUT
-------------------------------*/
.p-idx_about {
  padding: 5rem 0 4rem;
  overflow: hidden; }
  .p-idx_about__inner {
    width: 100%;
    max-width: 100%; }
  .p-idx_about__body {
    position: relative;
    padding: 0 3%; }
  .p-idx_about__img {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0; }
    .p-idx_about__img svg {
      width: 47.5rem;
      height: 33.15rem; }
      .p-idx_about__img svg path {
        fill: none;
        stroke: #6e6e7e;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px; }
  .p-idx_about__text {
    position: relative;
    z-index: 2;
    padding-bottom: 20rem;
    transition: 1s; }
    .p-idx_about__text .c-lead {
      line-height: 3; }
  .p-idx_about__btn {
    margin-top: 2rem; }
  @media screen {
    .p-idx_about__text .c-lead_ttl .ttl, .p-idx_about__text .c-lead_ttl .catch {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_about__text p {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_about.is-anime .p-idx_about__text .c-lead_ttl .ttl, .p-idx_about.is-anime .p-idx_about__text .c-lead_ttl .catch {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_about.is-anime .p-idx_about__text p {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 1199px) {
    .p-idx_about__img svg {
      width: 38rem;
      height: 26.6rem; }
    .p-idx_about__text {
      padding-bottom: 10rem; } }
  @media screen and (max-width: 991px) {
    .p-idx_about__body {
      padding: 0; }
    .p-idx_about__text {
      padding: 0 3%; }
    .p-idx_about__img {
      position: static;
      margin-top: 2rem;
      text-align: center; } }
  @media screen and (max-width: 767px) {
    .p-idx_about__text .c-lead_ttl .catch {
      letter-spacing: 0.35em; }
    .p-idx_about__text .c-lead {
      line-height: 2.5; } }
  @media screen and (max-width: 575px) {
    .p-idx_about {
      padding: 4rem 0; }
      .p-idx_about__img {
        position: relative;
        padding-top: 60%; }
        .p-idx_about__img svg {
          position: absolute;
          width: 100%;
          height: 100%;
          right: 0;
          top: 0; }
      .p-idx_about__text .c-lead {
        line-height: 2; } }

/*-------------------------------
  INDEX > SERVICE
-------------------------------*/
.p-idx_service {
  padding: 4rem 0;
  overflow: hidden; }
  .p-idx_service .c-lead {
    margin-bottom: 2.5em; }
  .p-idx_service__list {
    justify-content: space-between;
    margin-bottom: 3rem; }
  @media screen {
    .p-idx_service .c-bg_ttl {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_service .c-main_ttl,
    .p-idx_service .c-lead {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_service.is-anime .c-bg_ttl {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_service.is-anime .c-main_ttl,
    .p-idx_service.is-anime .c-lead {
      transform: translateY(0);
      opacity: 1; } }

.p-idx_service_item {
  width: calc((100% - .7rem) / 3);
  background: #fff;
  margin-top: .35rem;
  padding: 3rem 1.5rem; }
  .p-idx_service_item.-full {
    width: 100%; }
  .p-idx_service_item p {
    text-align: center; }
  .p-idx_service_item__cat {
    font-family: "Lato", sans-serif;
    line-height: 1;
    text-align: center;
    font-weight: 700; }
    .p-idx_service_item__cat.-sm {
      font-size: .85rem; }
  .p-idx_service_item__title {
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 1em;
    text-align: center; }
    .p-idx_service_item__title.-sm {
      font-size: 1.75rem;
      margin-bottom: .25em; }
  .p-idx_service_item__ico {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 10.75rem; }
    .p-idx_service_item__ico svg {
      width: 100%; }
    .p-idx_service_item__ico.-design svg {
      height: 5.85rem; }
    .p-idx_service_item__ico.-sales svg {
      height: 6.6rem; }
    .p-idx_service_item__ico.-exhibition svg {
      height: 5.75rem; }
    .p-idx_service_item__ico.-print {
      padding-left: 1.5rem; }
      .p-idx_service_item__ico.-print svg {
        height: 5.9rem; }
    .p-idx_service_item__ico.-video svg {
      height: 4.3rem; }
    .p-idx_service_item__ico.-web svg {
      height: 4.73rem; }
    .p-idx_service_item__ico.-event svg {
      height: 5.9rem; }
    .p-idx_service_item__ico.-campaign svg {
      height: 5.9rem; }
  .p-idx_service_item__list {
    justify-content: space-between; }
    .p-idx_service_item__list li {
      width: 20%; }
  @media screen {
    .p-idx_service_item {
      transition-property: opacity;
      opacity: 0; }
      .is-anime .p-idx_service_item {
        opacity: 1; } }
  @media screen and (max-width: 1399px) {
    .p-idx_service_item {
      padding: 3rem 1.25rem; } }
  @media screen and (max-width: 1199px) {
    .p-idx_service_item:not(.-full) p {
      text-align: left; } }
  @media screen and (max-width: 991px) {
    .p-idx_service_item {
      width: 100%; }
      .p-idx_service_item:not(.-full) .p-idx_service_item__inner {
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .p-idx_service_item__img {
        width: 30%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column-reverse; }
        .p-idx_service_item__img .p-idx_service_item__cat {
          margin-top: 1em;
          margin-bottom: 0; }
      .p-idx_service_item__text {
        width: 65%; }
        .p-idx_service_item__text .p-idx_service_item__title {
          text-align: left; }
      .p-idx_service_item__title {
        margin-bottom: .5em; }
        .p-idx_service_item__title.-sm {
          font-size: 1.35rem;
          text-align: center; }
      .p-idx_service_item__ico {
        height: auto; }
      .p-idx_service_item__cat {
        margin-bottom: .5em;
        line-height: 1.5; }
      .p-idx_service_item__list {
        margin-top: 2rem;
        justify-content: center; }
        .p-idx_service_item__list .p-idx_service_item__ico {
          position: relative;
          width: 40%;
          padding-top: 40%;
          margin: 0 auto;
          height: 0;
          text-align: center;
          margin-bottom: .5rem; }
          .p-idx_service_item__list .p-idx_service_item__ico svg {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100% !important; }
        .p-idx_service_item__list .p-idx_service_item__text {
          width: 100%; } }
  @media screen and (max-width: 767px) {
    .p-idx_service_item__title.-sm {
      font-size: 1.125rem; } }
  @media screen and (max-width: 575px) {
    .p-idx_service_item:not(.-full) .p-idx_service_item__inner {
      display: block; }
    .p-idx_service_item__img {
      width: 100%; }
    .p-idx_service_item__text {
      width: 100%; }
      .p-idx_service_item__text .p-idx_service_item__title {
        text-align: center; }
    .p-idx_service_item__ico {
      margin-bottom: .5rem; }
    .p-idx_service_item__title {
      font-size: 1.75rem; }
      .p-idx_service_item__title.-sm {
        text-align: left; }
    .p-idx_service_item__cat.-sm {
      text-align: left; }
    .p-idx_service_item__list {
      display: block; }
      .p-idx_service_item__list li {
        width: 100%;
        background: #f5f5f5;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .p-idx_service_item__list li .p-idx_service_item__ico {
          width: 20%;
          padding-top: 20%;
          margin-bottom: 0;
          margin: 0; }
        .p-idx_service_item__list li .p-idx_service_item__text {
          width: 80%;
          padding-left: 1.5rem; }
        .p-idx_service_item__list li:nth-child(n+2) {
          margin-top: .5rem; } }

/*-------------------------
  INDEX > WORKS
-------------------------*/
.p-idx_works {
  padding: 7.5rem 0 5rem;
  overflow: hidden; }
  .p-idx_works .c-lead {
    margin-bottom: 4.5em; }
  .p-idx_works__list {
    position: relative;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    margin-bottom: 4rem; }
  @media screen {
    .p-idx_works .c-main_ttl,
    .p-idx_works .c-lead {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_works.is-anime .c-main_ttl,
    .p-idx_works.is-anime .c-lead {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_works .c-lead {
      margin-bottom: 2.5rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_works {
      padding: 5rem 0; }
      .p-idx_works__list {
        justify-content: space-between;
        margin-bottom: 3rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_works__list {
      margin-bottom: 2rem; } }

.p-idx_works_item {
  width: calc((100% - 2px) / 3);
  margin-right: 1px; }
  .p-idx_works_item:nth-child(3n) {
    margin-right: 0; }
  .p-idx_works_item:nth-child(n+4) {
    margin-top: 1px; }
  .p-idx_works_item__img {
    position: relative;
    z-index: 1; }
  .p-idx_works_item__text {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    padding: 2.5rem;
    z-index: 2; }
  .p-idx_works_item__info {
    font-weight: 400;
    color: #fff; }
    .p-idx_works_item__info span {
      display: block; }
      .p-idx_works_item__info span:nth-child(n+2) {
        margin-top: .5em; }
  .p-idx_works_item a {
    position: relative;
    display: block; }
    @media screen and (min-width: 992px) {
      .p-idx_works_item a .p-idx_works_item__text {
        transition: .6s;
        transform: scale(0); }
      .p-idx_works_item a:hover .p-idx_works_item__text {
        transform: scale(1); } }
  @media screen {
    .p-idx_works_item {
      transition-property: opacity;
      opacity: 0; }
      .is-anime .p-idx_works_item {
        opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_works_item {
      width: calc(50% - 1px);
      margin-right: 0; }
      .p-idx_works_item__text {
        position: static;
        padding: 1.25rem 1rem;
        background: none; }
      .p-idx_works_item__info {
        color: #231815;
        line-height: 1.6; } }
  @media screen and (max-width: 575px) {
    .p-idx_works_item {
      width: 100%; } }

/*-------------------------
  INDEX > PROJECT
-------------------------*/
.p-idx_business {
  padding: 7.5rem 0 8rem;
  overflow: hidden; }
  .p-idx_business .c-lead {
    margin-bottom: 3.3em; }
  @media screen {
    .p-idx_business .c-main_ttl,
    .p-idx_business .c-lead {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_business.is-anime .c-main_ttl,
    .p-idx_business.is-anime .c-lead {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 767px) {
    .p-idx_business {
      padding: 5rem 0; } }

.p-idx_business_item {
  padding: 6rem 4rem;
  background: #E1D9DC; }
  .p-idx_business_item:nth-child(n+2) {
    margin-top: 5rem; }
  .p-idx_business_item__img {
    background: #fff;
    margin-bottom: 4rem; }
    .p-idx_business_item__img a {
      display: block; }
      .p-idx_business_item__img a img {
        width: 100%; }
      @media screen and (min-width: 992px) {
        .p-idx_business_item__img a:hover {
          opacity: .5; } }
  .p-idx_business_item__bnr {
    background: #fff;
    margin-top: 4rem; }
    .p-idx_business_item__bnr a {
      display: block; }
      .p-idx_business_item__bnr a img {
        width: 100%; }
      @media screen and (min-width: 992px) {
        .p-idx_business_item__bnr a:hover {
          opacity: .5; } }
  .p-idx_business_item .c-lead {
    margin-bottom: 0; }
  @media screen {
    .p-idx_business_item {
      transition-property: transform,opacity;
      transform: translateY(3rem);
      opacity: 0; }
      .is-anime .p-idx_business_item {
        transform: translateY(0);
        opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_business_item {
      padding: 3rem 2rem; }
      .p-idx_business_item:nth-child(n+2) {
        margin-top: 4rem; }
      .p-idx_business_item__img {
        margin-bottom: 3rem; }
      .p-idx_business_item__bnr {
        margin-top: 3rem; } }
  @media screen and (max-width: 575px) {
    .p-idx_business_item {
      padding: 2rem; }
      .p-idx_business_item__img {
        margin-bottom: 2rem; }
      .p-idx_business_item__bnr {
        margin-top: 2rem; }
      .p-idx_business_item:nth-child(n+2) {
        margin-top: 3rem; } }

/*-------------------------
  INDEX > CONTENTS
-------------------------*/
.p-idx_contents {
  padding-bottom: 10rem;
  overflow: hidden; }
  .p-idx_contents__box {
    justify-content: space-between;
    align-items: stretch; }
    .p-idx_contents__box:nth-child(n+2) {
      margin-top: 10rem; }
  .p-idx_contents__text {
    width: 60%; }
    .p-idx_contents__text .c-main_ttl {
      margin-bottom: .75em; }
    .p-idx_contents__text .c-sub_ttl {
      margin-bottom: 1.75em; }
  .p-idx_contents__bnr {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: column; }
    .p-idx_contents__bnr a {
      display: block; }
      .p-idx_contents__bnr a img {
        max-width: 100%; }
      @media screen and (min-width: 992px) {
        .p-idx_contents__bnr a:hover {
          opacity: .5; } }
  @media screen {
    .p-idx_contents .c-bg_ttl {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_contents__text .c-main_ttl,
    .p-idx_contents__text .c-sub_ttl,
    .p-idx_contents__text .c-lead {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_contents__bnr {
      transition-property: opacity;
      opacity: 0; }
    .p-idx_contents.is-anime .c-bg_ttl {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_contents.is-anime .p-idx_contents__text .c-main_ttl,
    .p-idx_contents.is-anime .p-idx_contents__text .c-sub_ttl,
    .p-idx_contents.is-anime .p-idx_contents__text .c-lead {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_contents.is-anime .p-idx_contents__bnr {
      opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_contents__text .c-sub_ttl {
      margin-bottom: 1.25em; } }
  @media screen and (max-width: 767px) {
    .p-idx_contents {
      padding-bottom: 7.5rem; }
      .p-idx_contents__box:nth-child(n+2) {
        margin-top: 4rem; }
      .p-idx_contents__text {
        width: 100%; }
      .p-idx_contents__bnr {
        margin-top: 2rem;
        width: 45%; } }
  @media screen and (max-width: 575px) {
    .p-idx_contents {
      padding-bottom: 5rem; }
      .p-idx_contents__bnr {
        width: 100%; } }

/*-------------------------
  INDEX > MEMBER
-------------------------*/
.p-idx_member {
  padding-bottom: 7.5rem;
  overflow: hidden; }
  .p-idx_member__bnr {
    margin-top: 2.5rem; }
    .p-idx_member__bnr a img {
      max-width: 100%; }
    @media screen and (min-width: 992px) {
      .p-idx_member__bnr a:hover {
        opacity: .5; } }
  @media screen {
    .p-idx_member .c-bg_ttl,
    .p-idx_member .c-sub_ttl,
    .p-idx_member p {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_member__bnr {
      transition-property: transform,opacity;
      transform: translateY(3rem);
      opacity: 0; }
    .p-idx_member.is-anime .c-bg_ttl,
    .p-idx_member.is-anime .c-sub_ttl,
    .p-idx_member.is-anime p {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_member.is-anime .p-idx_member__bnr {
      transform: translateY(0);
      opacity: 1; } }
  @media screen and (max-width: 767px) {
    .p-idx_member {
      padding-bottom: 5rem; } }

/*-------------------------
  INDEX > NEWS
-------------------------*/
.p-idx_news {
  padding: 5rem 0;
  overflow: hidden; }
  .p-idx_news__btn {
    margin-top: 4rem; }
  @media screen {
    .p-idx_news .c-bg_ttl {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_news .c-news_list {
      transition-property: opacity;
      opacity: 0; }
    .p-idx_news.is-anime .c-bg_ttl {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_news.is-anime .c-news_list {
      opacity: 1; } }
  @media screen and (max-width: 767px) {
    .p-idx_news {
      padding: 4rem 0; } }
  @media screen and (max-width: 767px) {
    .p-idx_news__btn {
      margin-top: 3rem; } }

/*-------------------------
  INDEX > ACCESS
-------------------------*/
.p-idx_access {
  padding: 7rem 0 9rem;
  overflow: hidden; }
  .p-idx_access h3 {
    line-height: 1;
    margin-bottom: 1em;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Lato", sans-serif;
    letter-spacing: .1em; }
  .p-idx_access p {
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1.5em; }
  .p-idx_access .c-gmap {
    padding-top: 0;
    height: 400px; }
  @media screen {
    .p-idx_access .c-bg_ttl
h3,
    .p-idx_access p {
      transition-property: transform, opacity;
      transform: translateX(2rem);
      opacity: 0; }
    .p-idx_access .c-gmap {
      transition-property: opacity;
      opacity: 0; }
    .p-idx_access.is-anime .c-bg_ttl
h3,
    .p-idx_access.is-anime p {
      transform: translateY(0);
      opacity: 1; }
    .p-idx_access.is-anime .c-gmap {
      opacity: 1; } }
  @media screen and (max-width: 991px) {
    .p-idx_access {
      padding: 3rem 0 5rem; } }
  @media screen and (max-width: 767px) {
    .p-idx_access {
      padding: 3rem 0 4rem; } }
  @media screen and (max-width: 575px) {
    .p-idx_access {
      padding: 0 0 4rem; } }

/*============================================

  COMPANY

============================================*/
/*-------------------------------
  COMPANY > MESSAGE
-------------------------------*/
.p-co_message {
  padding: 5rem 0 7.5rem; }
  .p-co_message .c-lead_ttl {
    width: 100%; }
    .p-co_message .c-lead_ttl .catch {
      letter-spacing: 0.15em; }
  .p-co_message__inner {
    justify-content: space-between; }
  .p-co_message__text {
    max-width: 53%; }
    .p-co_message__text .c-lead:nth-child(n+2) {
      margin-top: 2em; }
    .p-co_message__text .sign {
      text-align: right;
      margin-top: 4em; }
      .p-co_message__text .sign span {
        display: inline-block;
        vertical-align: middle; }
      .p-co_message__text .sign img {
        width: auto;
        height: 2.8em;
        margin-left: 2em;
        vertical-align: middle; }
  .p-co_message__img {
    max-width: 45%; }
    .p-co_message__img img {
      max-width: 100%; }
  @media screen and (max-width: 1199px) {
    .p-co_message__text {
      max-width: 60%; }
      .p-co_message__text .c-lead {
        line-height: 2.25; }
      .p-co_message__text .sign {
        margin-top: 2em; }
    .p-co_message__img {
      max-width: 40%; } }
  @media screen and (max-width: 991px) {
    .p-co_message__inner {
      align-items: flex-start; }
    .p-co_message__text {
      max-width: 75%; }
    .p-co_message__img {
      max-width: 25%; } }
  @media screen and (max-width: 767px) {
    .p-co_message {
      padding: 4rem 0; }
      .p-co_message__inner {
        position: relative;
        display: block; }
      .p-co_message__text {
        max-width: 100%;
        margin-bottom: 2em; }
        .p-co_message__text .c-lead:nth-child(n+2) {
          margin-top: 1.5em; }
      .p-co_message__img {
        width: 50%;
        max-width: 50%;
        margin: 0 auto; }
        .p-co_message__img .sign {
          display: block;
          text-align: center; } }
  @media screen and (max-width: 575px) {
    .p-co_message {
      padding: 4rem 0 5rem; }
      .p-co_message__text .c-lead:nth-child(n+2) {
        margin-top: 1em; }
      .p-co_message__img {
        width: 100%;
        max-width: 100%; } }

/*-------------------------------
  COMPANY > PHILOSOPHY
-------------------------------*/
.p-co_philosophy {
  padding: 5rem 0; }
  .p-co_philosophy__inner > .c-lead {
    margin-bottom: 3em;
    font-size: 1.1rem; }
  @media screen and (max-width: 991px) {
    .p-co_philosophy {
      padding: 0 0 4rem; } }
  @media screen and (max-width: 767px) {
    .p-co_philosophy .c-lead_ttl .ttl .en {
      font-size: .95em; } }
  @media screen and (max-width: 575px) {
    .p-co_philosophy {
      padding: 0 0 5rem; }
      .p-co_philosophy .c-lead_ttl .catch {
        letter-spacing: .03em; } }

.p-co_philosophy_item {
  padding: 4.5rem 2.5rem;
  width: 33.33333%; }
  .p-co_philosophy_item:nth-child(1) {
    background: #aaa0a5; }
  .p-co_philosophy_item:nth-child(2) {
    width: 33.33334%;
    background: #c8b9be; }
  .p-co_philosophy_item:nth-child(3) {
    background: #b9a0aa; }
  .p-co_philosophy_item__title {
    font-size: 2.5rem;
    margin-bottom: 1.2em;
    text-align: center;
    color: #fff;
    font-weight: 400; }
  .p-co_philosophy_item .c-lead {
    font-size: 1.25rem;
    color: #fff;
    line-height: 2; }
  @media screen and (max-width: 1399px) {
    .p-co_philosophy_item .c-lead {
      font-size: 1.15rem; } }
  @media screen and (max-width: 1199px) {
    .p-co_philosophy_item {
      padding: 4.5rem 1.5rem; }
      .p-co_philosophy_item__title {
        font-size: 2.25rem;
        margin-bottom: .8em; }
      .p-co_philosophy_item .c-lead {
        font-size: 1.1rem; } }
  @media screen and (max-width: 991px) {
    .p-co_philosophy_item {
      padding: 2.5rem 1rem; }
      .p-co_philosophy_item__title {
        font-size: 2rem; }
      .p-co_philosophy_item .c-lead {
        font-size: .95rem; } }
  @media screen and (max-width: 767px) {
    .p-co_philosophy_item {
      width: 100%;
      padding: 2rem 0; }
      .p-co_philosophy_item__inner {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: space-between;
        align-items: center; }
      .p-co_philosophy_item:nth-child(2) {
        width: 100%; }
      .p-co_philosophy_item__title {
        font-size: 1.8rem;
        width: 35%; }
      .p-co_philosophy_item .c-lead {
        width: 60%; } }
  @media screen and (max-width: 575px) {
    .p-co_philosophy_item {
      padding: 2.5rem 1.5rem; }
      .p-co_philosophy_item__inner {
        display: block;
        padding: 0; }
      .p-co_philosophy_item__title {
        width: 100%;
        margin-bottom: 1em; } }

/*-------------------------------
  COMPANY > POLICY
-------------------------------*/
.p-co_policy {
  padding: 4rem 0 5rem; }
  .p-co_policy .c-lead_ttl {
    width: 100%; }
  .p-co_policy__inner {
    justify-content: space-between; }
  .p-co_policy__text {
    padding-top: 4rem; }
    .p-co_policy__text .c-lead {
      font-size: 1.25rem; }
  .p-co_policy__img {
    margin-top: -3rem;
    padding-right: 2%; }
    .p-co_policy__img svg {
      width: 37rem;
      height: 20rem; }
  @media screen and (max-width: 1199px) {
    .p-co_policy__img {
      margin-top: 0; } }
  @media screen and (max-width: 991px) {
    .p-co_policy__inner {
      align-items: center; }
    .p-co_policy__text {
      padding-top: 0; }
      .p-co_policy__text .c-lead {
        font-size: 1.2rem; }
    .p-co_policy__img {
      padding-right: 0; }
      .p-co_policy__img svg {
        width: 22.2rem;
        height: 12rem; } }
  @media screen and (max-width: 767px) {
    .p-co_policy__inner {
      display: block; }
    .p-co_policy__img {
      text-align: center;
      margin-top: 2rem; }
      .p-co_policy__img svg {
        width: 25.995rem;
        height: 15rem; } }
  @media screen and (max-width: 575px) {
    .p-co_policy {
      padding: 0 0 5rem; }
      .p-co_policy__text .c-lead {
        font-size: 1.15rem; }
      .p-co_policy__img {
        position: relative;
        width: 100%;
        padding-top: 54%; }
        .p-co_policy__img svg {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%; } }

/*-------------------------------
  COMPANY > GUIDELINE
-------------------------------*/
.p-co_guideline {
  padding: 7.5rem 0; }
  .p-co_guideline__list {
    justify-content: space-between;
    counter-reset: co_count01 0;
    width: 72.22%;
    margin: 5rem auto 0; }
  @media screen and (max-width: 1199px) {
    .p-co_guideline__list {
      width: 80%; } }
  @media screen and (max-width: 991px) {
    .p-co_guideline {
      padding: 0 0 4rem; }
      .p-co_guideline .c-lead_ttl .catch {
        letter-spacing: 0.15em; }
      .p-co_guideline__list {
        width: 100%;
        margin-top: 4rem; } }
  @media screen and (max-width: 575px) {
    .p-co_guideline {
      padding: 0 0 5rem; } }

.p-co_guideline_item {
  position: relative;
  counter-increment: co_count01 1;
  width: 49%;
  padding: 1.5rem 2rem; }
  .p-co_guideline_item:before {
    position: absolute;
    content: counter(co_count01);
    font-size: 10rem;
    text-align: center;
    line-height: .75;
    color: #fff; }
  .p-co_guideline_item__inner {
    position: relative;
    padding-top: 65.625%; }
  .p-co_guideline_item__title {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: .5em; }
  .p-co_guideline_item__text {
    padding: 2.5rem;
    color: #fff; }
    .p-co_guideline_item__text .c-lead {
      line-height: 2; }
  .p-co_guideline_item:nth-child(1) {
    background: #aaa0a5; }
  .p-co_guideline_item:nth-child(2) {
    background: #c8b9be; }
  .p-co_guideline_item:nth-child(3) {
    background: #c8aaaa; }
  .p-co_guideline_item:nth-child(4) {
    background: #b9a0aa; }
  @media screen and (min-width: 768px) {
    .p-co_guideline_item__text {
      position: absolute; }
    .p-co_guideline_item:nth-child(n+3) {
      margin-top: 2%; }
    .p-co_guideline_item:nth-child(1):before {
      bottom: 1.5rem;
      right: 2rem; }
    .p-co_guideline_item:nth-child(1) .p-co_guideline_item__text {
      top: 0;
      left: 0; }
    .p-co_guideline_item:nth-child(2):before {
      bottom: 1.5rem;
      left: 2rem; }
    .p-co_guideline_item:nth-child(2) .p-co_guideline_item__text {
      top: 0;
      right: 0; }
    .p-co_guideline_item:nth-child(3):before {
      top: 1.5rem;
      right: 2rem; }
    .p-co_guideline_item:nth-child(3) .p-co_guideline_item__text {
      bottom: 0;
      left: 0; }
    .p-co_guideline_item:nth-child(4):before {
      top: 1.5rem;
      left: 2rem; }
    .p-co_guideline_item:nth-child(4) .p-co_guideline_item__text {
      bottom: 0;
      right: 0; } }
  @media screen and (max-width: 1399px) {
    .p-co_guideline_item__text {
      padding: 1.5rem; } }
  @media screen and (max-width: 991px) {
    .p-co_guideline_item:before {
      font-size: 8.5rem; }
    .p-co_guideline_item__title {
      margin-bottom: .5em; } }
  @media screen and (max-width: 767px) {
    .p-co_guideline_item {
      padding: 2rem; }
      .p-co_guideline_item:nth-child(n+3) {
        margin-top: 2%; }
      .p-co_guideline_item:before {
        font-size: 7rem;
        right: 2rem;
        top: 2rem; }
      .p-co_guideline_item__text {
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 0; } }
  @media screen and (max-width: 575px) {
    .p-co_guideline_item {
      width: 100%; }
      .p-co_guideline_item:before {
        font-size: 6rem; }
      .p-co_guideline_item:nth-child(n+2) {
        margin-top: 2%; }
      .p-co_guideline_item__inner {
        padding-top: 45%; } }

/*-------------------------------
  COMPANY > PROFILE
-------------------------------*/
.p-co_profile {
  padding: 4rem 0 6rem; }
  .p-co_profile__inner {
    justify-content: space-between; }
  @media screen and (max-width: 991px) {
    .p-co_profile {
      padding: 4rem 0; } }
  @media screen and (max-width: 575px) {
    .p-co_profile {
      padding: 5rem 0; } }

.p-co_profile_item {
  width: 50%; }
  .p-co_profile_item .c-lead_ttl .ttl {
    display: block; }
    .p-co_profile_item .c-lead_ttl .ttl .ja {
      margin-left: 0;
      margin-top: 1em; }
  .p-co_profile_item__sheet th,
  .p-co_profile_item__sheet td {
    vertical-align: top; }
    .p-co_profile_item__sheet th > *,
    .p-co_profile_item__sheet td > * {
      font-weight: 400;
      line-height: 3; }
  .p-co_profile_item__sheet th {
    padding-right: 1.5em;
    text-align: left; }
  .p-co_profile_item__sheet td {
    padding-left: 1.5em; }
    .p-co_profile_item__sheet td ul li:nth-last-child(n+2):after {
      content: "、"; }
  .p-co_profile_item.-information {
    padding-right: 1em; }
    .p-co_profile_item.-information .p-co_profile_item__sheet th {
      width: 9em; }
  .p-co_profile_item.-history {
    padding-left: 1em; }
    .p-co_profile_item.-history .p-co_profile_item__sheet th {
      width: 6em; }
    .p-co_profile_item.-history .p-co_profile_item__sheet td {
      border-left: solid 1px #231815; }
  @media screen and (max-width: 1399px) {
    .p-co_profile_item.-history .p-co_profile_item__sheet th {
      width: 7em; } }
  @media screen and (max-width: 1199px) {
    .p-co_profile_item th > *,
    .p-co_profile_item td > * {
      line-height: 2.5; } }
  @media screen and (max-width: 991px) {
    .p-co_profile_item {
      width: 100%; }
      .p-co_profile_item__sheet th {
        padding-right: 2em; }
      .p-co_profile_item__sheet td {
        padding-left: 2em; }
      .p-co_profile_item.-information {
        padding-right: 0; }
      .p-co_profile_item.-history {
        padding-left: 0;
        margin-top: 4rem; }
        .p-co_profile_item.-history .p-co_profile_item__sheet th {
          width: 9em; } }
  @media screen and (max-width: 575px) {
    .p-co_profile_item__sheet tr {
      border-top: solid 1px #231815;
      border-bottom: solid 1px #231815;
      padding: 1em 0;
      display: block;
      margin-top: -1px; }
    .p-co_profile_item__sheet th,
    .p-co_profile_item__sheet td {
      display: block;
      width: 100%; }
      .p-co_profile_item__sheet th > *,
      .p-co_profile_item__sheet td > * {
        line-height: 2; }
    .p-co_profile_item__sheet th {
      padding-right: 0;
      font-size: 1.125rem; }
    .p-co_profile_item__sheet td {
      padding-left: 0; }
    .p-co_profile_item.-information .p-co_profile_item__sheet th {
      width: 100%; }
    .p-co_profile_item.-history .p-co_profile_item__sheet td {
      border-left: none; } }

/*============================================

  ABOUT

============================================*/
/*-------------------------------
  ABOUT > THEME
-------------------------------*/
.p-abt_theme {
  padding: 5rem 0; }
  .p-abt_theme__list {
    justify-content: center; }
  @media screen and (max-width: 1399px) {
    .p-abt_theme__list {
      justify-content: space-between; } }
  @media screen and (max-width: 1199px) {
    .p-abt_theme .c-lead_ttl .catch {
      letter-spacing: 0.4em; } }
  @media screen and (max-width: 991px) {
    .p-abt_theme {
      padding: 4rem 0; }
      .p-abt_theme__list {
        display: block; } }
  @media screen and (max-width: 767px) {
    .p-abt_theme .c-lead_ttl .catch {
      letter-spacing: 0.25em; } }
  @media screen and (max-width: 575px) {
    .p-abt_theme .c-lead_ttl .ttl .en {
      font-size: .925em; } }

.p-abt_theme_main {
  margin-bottom: 4rem;
  justify-content: space-between;
  align-items: flex-end; }
  .p-abt_theme_main__text {
    width: calc(100% - 30.25rem);
    padding-right: 2rem; }
    .p-abt_theme_main__text .c-lead {
      line-height: 3; }
  .p-abt_theme_main__img {
    padding-right: 3%;
    width: 30.25rem; }
    .p-abt_theme_main__img svg {
      width: 100%;
      height: 18.09rem; }
  @media screen and (max-width: 991px) {
    .p-abt_theme_main {
      margin-bottom: 2rem; }
      .p-abt_theme_main__img {
        width: 40%; }
      .p-abt_theme_main__text {
        width: 58%; }
        .p-abt_theme_main__text .c-lead {
          line-height: 2.5; } }
  @media screen and (max-width: 767px) {
    .p-abt_theme_main__img {
      width: 100%;
      margin-top: 2rem; }
      .p-abt_theme_main__img svg {
        height: 13rem; }
    .p-abt_theme_main__text {
      width: 100%; } }

.p-abt_theme_item {
  position: relative;
  max-width: 40%;
  border-radius: 50%; }
  .p-abt_theme_item__img {
    position: relative;
    z-index: 1; }
    .p-abt_theme_item__img img {
      max-width: 100%; }
  .p-abt_theme_item__inner {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20% 10%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .p-abt_theme_item.-sales {
    margin-left: 20%; }
  .p-abt_theme_item.-exhibition {
    margin-top: -10%; }
  .p-abt_theme_item__title {
    font-size: 2rem;
    text-align: center;
    margin-bottom: .25em; }
    .p-abt_theme_item__title span {
      display: block; }
      .p-abt_theme_item__title span.en {
        font-size: .45em;
        line-height: 1.3;
        margin-bottom: .35em; }
      .p-abt_theme_item__title span.ja {
        line-height: 1.1; }
  .p-abt_theme_item__text {
    font-size: .9rem;
    text-align: center; }
  @media screen and (max-width: 1399px) {
    .p-abt_theme_item {
      width: 33%;
      max-width: 33%; }
      .p-abt_theme_item__img {
        display: none; }
      .p-abt_theme_item__inner {
        position: relative;
        display: block;
        padding: 0; }
      .p-abt_theme_item__title {
        position: relative;
        width: 100%;
        padding-top: 100%;
        font-size: 1.8rem;
        background: url("../img/about/img_theme_design.png") no-repeat center center/contain;
        margin-bottom: 1em; }
        .p-abt_theme_item__title h3 {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); }
      .p-abt_theme_item__text {
        font-size: 1rem; }
      .p-abt_theme_item.-sales {
        margin-left: 0%; }
        .p-abt_theme_item.-sales .p-abt_theme_item__title {
          background-image: url("../img/about/img_theme_sales.png"); }
      .p-abt_theme_item.-exhibition {
        margin-top: 0%; }
        .p-abt_theme_item.-exhibition .p-abt_theme_item__title {
          background-image: url("../img/about/img_theme_exhibition.png"); } }
  @media screen and (max-width: 1199px) {
    .p-abt_theme_item__title h3 span.ja {
      padding-bottom: 1em; } }
  @media screen and (max-width: 991px) {
    .p-abt_theme_item {
      max-width: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .p-abt_theme_item:nth-child(odd) {
        flex-direction: row-reverse; }
      .p-abt_theme_item__img {
        display: block;
        width: 30%; }
      .p-abt_theme_item__inner {
        width: 65%; }
      .p-abt_theme_item__title {
        padding: 0;
        background-image: none;
        text-align: left; }
        .p-abt_theme_item__title h3 {
          position: static;
          transform: translate(0, 0); }
      .p-abt_theme_item__text {
        text-align: left; }
      .p-abt_theme_item.-sales {
        margin-left: 0%; }
        .p-abt_theme_item.-sales .p-abt_theme_item__title {
          background-image: none; }
      .p-abt_theme_item.-exhibition {
        margin-top: 0%; }
        .p-abt_theme_item.-exhibition .p-abt_theme_item__title {
          background-image: none; } }
  @media screen and (max-width: 767px) {
    .p-abt_theme_item__img {
      width: 40%; }
    .p-abt_theme_item__inner {
      width: 55%; } }
  @media screen and (max-width: 575px) {
    .p-abt_theme_item {
      flex-direction: column-reverse; }
      .p-abt_theme_item:nth-child(odd) {
        flex-direction: column-reverse; }
      .p-abt_theme_item:nth-child(n+2) {
        margin-top: 4rem; }
      .p-abt_theme_item__img {
        display: none; }
      .p-abt_theme_item__inner {
        width: 100%; }
      .p-abt_theme_item__title {
        position: relative;
        width: 100%;
        padding-top: 100%;
        background-image: url("../img/about/img_theme_design.png"); }
        .p-abt_theme_item__title h3 {
          position: absolute;
          transform: translate(-50%, -50%);
          text-align: center; }
      .p-abt_theme_item__text {
        font-size: 1.1rem; }
      .p-abt_theme_item.-sales .p-abt_theme_item__title {
        background-image: url("../img/about/img_theme_sales.png"); }
      .p-abt_theme_item.-exhibition .p-abt_theme_item__title {
        background-image: url("../img/about/img_theme_exhibition.png"); } }

/*-------------------------------
  ABOUT > CONSCIOUS
-------------------------------*/
.p-abt_conscious {
  padding: 4rem 0 5rem; }
  .p-abt_conscious .c-lead_ttl {
    width: 100%; }
  .p-abt_conscious__inner {
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row-reverse; }
  .p-abt_conscious__img {
    transform: translateX(-3vw);
    width: 41.05rem; }
    .p-abt_conscious__img svg {
      width: 100%;
      height: 18.88rem; }
  .p-abt_conscious__text {
    width: calc(100% - 41.05rem);
    font-size: 1.1rem;
    padding-bottom: 2em;
    padding-left: 1rem; }
    .p-abt_conscious__text .c-lead {
      line-height: 3.2; }
  @media screen and (max-width: 1399px) {
    .p-abt_conscious {
      padding: 4rem 0; }
      .p-abt_conscious__img {
        width: 38rem; }
        .p-abt_conscious__img svg {
          height: 17.48rem; }
      .p-abt_conscious__text {
        width: calc(100% - 38rem);
        font-size: 1rem; } }
  @media screen and (max-width: 1199px) {
    .p-abt_conscious__img {
      width: 32rem; }
    .p-abt_conscious__text {
      width: calc(100% - 32rem); }
      .p-abt_conscious__text .c-lead {
        line-height: 2.5; } }
  @media screen and (max-width: 991px) {
    .p-abt_conscious {
      padding: 3rem 0 4rem;
      display: block; }
      .p-abt_conscious__text {
        width: 100%; }
      .p-abt_conscious__img {
        width: 100%; } }
  @media screen and (max-width: 767px) {
    .p-abt_conscious .c-lead_ttl .catch {
      letter-spacing: .25em; }
    .p-abt_conscious__img svg {
      height: 13rem; } }
  @media screen and (max-width: 575px) {
    .p-abt_conscious {
      padding-bottom: 0; }
      .p-abt_conscious__text {
        padding-bottom: 0; } }

/*-------------------------------
  ABOUT > MISSION
-------------------------------*/
.p-abt_mission {
  padding: 7.5rem 0; }
  .p-abt_mission .c-lead {
    font-size: 1.1rem;
    line-height: 3;
    margin-bottom: 4.5em; }
  @media screen and (max-width: 1399px) {
    .p-abt_mission {
      padding: 4rem 0 5rem; }
      .p-abt_mission .c-lead {
        margin-bottom: 4.5rem; } }
  @media screen and (max-width: 991px) {
    .p-abt_mission {
      padding: 4rem 0; }
      .p-abt_mission .c-lead {
        margin-bottom: 3em;
        line-height: 2.5; } }
  @media screen and (max-width: 767px) {
    .p-abt_mission .c-lead_ttl .catch {
      letter-spacing: .25em; } }

.p-abt_mission_item {
  position: relative;
  padding-top: 25rem; }
  .p-abt_mission_item__img {
    text-align: center; }
    .p-abt_mission_item__img svg {
      width: 100%;
      height: 27.1rem; }
      .p-abt_mission_item__img svg #fill {
        opacity: 0;
        transition: .6s; }
    .p-abt_mission_item__img.is-anime svg #fill {
      opacity: .6; }
  .p-abt_mission_item__text {
    position: absolute;
    display: none; }
    .p-abt_mission_item__text:after {
      position: absolute;
      content: "";
      background: #6E6E7E;
      z-index: 2;
      height: 2px;
      transition: .6s linear .6s; }
    .p-abt_mission_item__text .inner {
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 22.5rem;
      height: 22.5rem;
      border-radius: 50%;
      color: #fff;
      z-index: 1; }
    .p-abt_mission_item__text h4 {
      text-align: center;
      font-size: 3rem;
      margin-bottom: .5em;
      font-family: "Lato", sans-serif;
      font-weight: 400; }
    .p-abt_mission_item__text p {
      text-align: center; }
    .p-abt_mission_item__text.-idea {
      left: 7%;
      top: 35%; }
      .p-abt_mission_item__text.-idea .inner {
        background: #aaa0a5; }
      .p-abt_mission_item__text.-idea:after {
        width: 12rem;
        right: -11rem;
        top: 50%;
        transform-origin: left center;
        transform: rotate(3deg) scaleX(0); }
    .p-abt_mission_item__text.-passion {
      left: 38%;
      top: 0; }
      .p-abt_mission_item__text.-passion:after {
        left: 70%;
        bottom: -4.5rem;
        width: 2px;
        height: 7rem;
        transform-origin: center top;
        transform: scaleY(0); }
      .p-abt_mission_item__text.-passion .inner {
        background: #c8b9be; }
    .p-abt_mission_item__text.-quality {
      right: 7%;
      top: 44.5%; }
      .p-abt_mission_item__text.-quality:after {
        left: -12.5rem;
        top: 50%;
        width: 14rem;
        transform-origin: right center;
        transform: rotate(5deg) scaleX(0); }
      .p-abt_mission_item__text.-quality .inner {
        background: #b9a0aa; }
    .is-anime ~ .p-abt_mission_item__text .inner {
      opacity: 1; }
    .is-anime ~ .p-abt_mission_item__text.-idea:after {
      transform: rotate(3deg) scaleX(1); }
    .is-anime ~ .p-abt_mission_item__text.-passion:after {
      transform: scaleY(1); }
    .is-anime ~ .p-abt_mission_item__text.-quality:after {
      transform: rotate(5deg) scaleX(1); }
  @media screen and (max-width: 1399px) {
    .p-abt_mission_item__text h4 {
      font-size: 2.5rem; }
    .p-abt_mission_item__text p {
      font-size: .9rem; }
    .p-abt_mission_item__text.-idea:after {
      width: 8rem;
      right: -7rem; }
    .p-abt_mission_item__text.-quality:after {
      width: 10rem;
      left: -8.5rem; } }
  @media screen and (max-width: 1199px) {
    .p-abt_mission_item__text.-idea:after {
      width: 5rem;
      right: -4rem; }
    .p-abt_mission_item__text.-quality:after {
      top: 47.5%;
      width: 7rem;
      left: -6rem; } }
  @media screen and (max-width: 991px) {
    .p-abt_mission_item {
      padding-top: 0;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap; }
      .p-abt_mission_item__img {
        width: 100%;
        margin-bottom: 2rem; }
        .p-abt_mission_item__img svg {
          height: 25rem; }
      .p-abt_mission_item__text {
        width: 33.33%;
        position: static; }
        .p-abt_mission_item__text:after {
          display: none; }
        .p-abt_mission_item__text .inner {
          padding: 2rem 0;
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 0;
          transition-delay: .6s; }
        .p-abt_mission_item__text.-passion {
          width: 33.34%; } }
  @media screen and (max-width: 767px) {
    .p-abt_mission_item__img svg {
      height: 20rem; } }
  @media screen and (max-width: 575px) {
    .p-abt_mission_item {
      display: block; }
      .p-abt_mission_item__text {
        width: 100%; }
        .p-abt_mission_item__text .inner {
          padding: 2rem 1rem; }
        .p-abt_mission_item__text.-passion {
          width: 100%; } }

/*============================================

  RECRUIT

============================================*/
/*-------------------------------
  RECRUIT > INTRO
-------------------------------*/
.p-rec_intro {
  padding: 5rem 0; }
  .p-rec_intro__inner {
    align-items: flex-end; }
  .p-rec_intro__text {
    width: calc(100% - 30.35rem);
    padding-bottom: 2em; }
    .p-rec_intro__text .c-lead_ttl .catch {
      letter-spacing: .4em; }
    .p-rec_intro__text .c-lead {
      line-height: 3; }
  .p-rec_intro__img {
    width: 30.35rem;
    height: 25.4rem; }
    .p-rec_intro__img figure {
      width: 100%;
      height: 100%; }
      .p-rec_intro__img figure svg {
        width: 100%;
        height: 100%; }
        .p-rec_intro__img figure svg #fill_01,
        .p-rec_intro__img figure svg #fill_02 {
          opacity: 0;
          transition: .6s; }
      .p-rec_intro__img figure.is-anime svg #fill_01,
      .p-rec_intro__img figure.is-anime svg #fill_02 {
        opacity: .3; }
  @media screen and (max-width: 1399px) {
    .p-rec_intro__text {
      width: calc(100% - 25rem); }
    .p-rec_intro__img {
      width: 25rem;
      height: 21rem; } }
  @media screen and (max-width: 1199px) {
    .p-rec_intro__text {
      width: calc(100% - 22.5rem); }
      .p-rec_intro__text .c-lead_ttl .catch {
        letter-spacing: .35em; }
    .p-rec_intro__img {
      width: 22.5rem;
      height: 18.8rem; } }
  @media screen and (max-width: 991px) {
    .p-rec_intro {
      padding: 4rem 0; }
      .p-rec_intro__text {
        width: calc(100% - 20rem); }
        .p-rec_intro__text .c-lead_ttl .en {
          line-height: 1.1; }
        .p-rec_intro__text .c-lead_ttl .catch {
          letter-spacing: .25em; }
      .p-rec_intro__img {
        width: 20rem;
        height: 16.7rem; } }
  @media screen and (max-width: 767px) {
    .p-rec_intro {
      display: block; }
      .p-rec_intro__text {
        width: 100%; }
      .p-rec_intro__img {
        margin: 0 auto; } }

/*-------------------------------
  RECRUIT > FLOW
-------------------------------*/
.p-rec_flow {
  padding: 4rem 0 5rem;
  background: #ebe8eb; }
  .p-rec_flow .c-lead {
    margin-bottom: 2em; }
  .p-rec_flow__list {
    justify-content: space-between;
    width: 75%;
    margin: 0 auto; }
  @media screen and (max-width: 991px) {
    .p-rec_flow {
      padding: 4rem 0; }
      .p-rec_flow__list {
        width: 100%; } }
  @media screen and (max-width: 575px) {
    .p-rec_flow__list {
      display: block;
      width: 100%; } }

.p-rec_flow_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column; }
  .p-rec_flow_item__ico {
    display: flex;
    justify-content: center;
    align-items: center; }
    .p-rec_flow_item__ico figure svg {
      width: 100%;
      height: 100%;
      fill: #3c3c3c; }
    .p-rec_flow_item__ico figure.-document {
      width: 4.9rem;
      height: 6rem; }
    .p-rec_flow_item__ico figure.-interview {
      width: 7.5rem;
      height: 6.2rem; }
    .p-rec_flow_item__ico figure.-test {
      width: 5.75rem;
      height: 6rem; }
    .p-rec_flow_item__ico figure.-good {
      width: 4.65rem;
      height: 4.57rem; }
    .p-rec_flow_item__ico figure:nth-child(n+2) {
      position: relative;
      margin-left: 5rem; }
      .p-rec_flow_item__ico figure:nth-child(n+2):before, .p-rec_flow_item__ico figure:nth-child(n+2):after {
        position: absolute;
        content: "";
        background: #231815; }
      .p-rec_flow_item__ico figure:nth-child(n+2):before {
        width: 1.8rem;
        height: 4px;
        left: calc(-5rem + (5rem - 1.8rem) / 2);
        top: 50%;
        transform: translateY(-50%); }
      .p-rec_flow_item__ico figure:nth-child(n+2):after {
        height: 1.8rem;
        width: 4px;
        left: calc(-5rem + (5rem - 1.8rem) / 2 + 1.8rem / 2);
        top: 50%;
        transform: translate(-50%, -50%); }
  .p-rec_flow_item__text {
    margin-top: .5em;
    font-size: 1.1rem; }
  @media screen and (max-width: 767px) {
    .p-rec_flow_item__ico figure.-document {
      width: 3.675rem;
      height: 4.5rem; }
    .p-rec_flow_item__ico figure.-interview {
      width: 5.625rem;
      height: 4.65rem; }
    .p-rec_flow_item__ico figure.-test {
      width: 4.3125rem;
      height: 3.4875rem; }
    .p-rec_flow_item__ico figure.-good {
      width: 3.4875rem;
      height: 3.4275rem; }
    .p-rec_flow_item__ico figure:nth-child(n+2) {
      margin-left: 4rem; }
      .p-rec_flow_item__ico figure:nth-child(n+2):before, .p-rec_flow_item__ico figure:nth-child(n+2):after {
        position: absolute;
        content: "";
        background: #231815; }
      .p-rec_flow_item__ico figure:nth-child(n+2):before {
        width: 1.5rem;
        height: 2px;
        left: calc(-4rem + (4rem - 1.5rem) / 2); }
      .p-rec_flow_item__ico figure:nth-child(n+2):after {
        height: 1.5rem;
        width: 2px;
        left: calc(-4rem + (4rem - 1.5rem) / 2 + 1.5rem / 2); } }
  @media screen and (max-width: 575px) {
    .p-rec_flow_item:nth-last-child(n+2) {
      position: relative;
      padding-bottom: 4rem; }
      .p-rec_flow_item:nth-last-child(n+2):after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: 1rem;
        border-style: solid;
        border-width: 1rem 1rem 0;
        border-color: #3c3c3c transparent;
        transform: translateX(-50%); } }

.p-rec_flow_arrow {
  position: relative;
  width: 2.25rem;
  height: 2px;
  background: #231815;
  align-self: center; }
  .p-rec_flow_arrow:after {
    position: absolute;
    content: "";
    width: 30%;
    height: 100%;
    right: 0;
    bottom: 0;
    transform-origin: right center;
    transform: rotate(30deg);
    background: #231815; }

/*-------------------------------
  RECRUIT > GUIDELINE
-------------------------------*/
.p-rec_guideline {
  padding: 5rem 0 7.5rem; }
  @media screen and (max-width: 991px) {
    .p-rec_guideline {
      padding: 4rem 0 5rem; } }

.p-requirement__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 1;
  padding: .75em 1.5em;
  color: #fff;
  background: #8c8c96;
  font-weight: 400;
  font-size: 1.25rem; }
  .p-requirement__title.-wanted {
    cursor: pointer; }
  .p-requirement__title.-finished {
    background: #c8c0c8; }
    .p-requirement__title.-finished h3 {
      width: calc(100% - 12em); }
    .p-requirement__title.-finished .end {
      width: 12em;
      text-align: right; }
  .p-requirement__title h3 {
    width: calc(100% - 2em);
    font-weight: 400; }
    .p-requirement__title h3 span {
      display: inline-block;
      vertical-align: middle; }
      .p-requirement__title h3 span.type {
        font-size: 1.2em;
        font-family: "Lato", sans-serif;
        width: 10em; }
      .p-requirement__title h3 span.title {
        max-width: calc(100% - 19em);
        min-width: 15em; }
      .p-requirement__title h3 span.number {
        width: 7em;
        text-align: right; }
  .p-requirement__title .toggle {
    position: relative;
    width: 1em;
    height: 1em;
    transition: .6s; }
    .p-requirement__title .toggle:before, .p-requirement__title .toggle:after {
      position: absolute;
      content: "";
      background: #fff;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      transition: .6s; }
    .p-requirement__title .toggle:before {
      width: 2px;
      height: 1em;
      opacity: 1; }
    .p-requirement__title .toggle:after {
      width: 1em;
      height: 2px; }
  .p-requirement__title:not(:first-child) {
    margin-top: 2.3rem; }
  .p-requirement__title.is-act .toggle {
    transform: rotate(360deg); }
    .p-requirement__title.is-act .toggle:before {
      opacity: 0; }

.p-requirement__sheet {
  display: none; }
  .p-requirement__sheet tr {
    border-bottom: solid 5px #fff; }
  .p-requirement__sheet th,
  .p-requirement__sheet td {
    padding: 1.5rem 2.5rem;
    text-align: left;
    line-height: 1.6;
    font-weight: 400;
    vertical-align: top; }
  .p-requirement__sheet th {
    width: 30%;
    background: #dfdcdc;
    font-size: 1.3rem; }
  .p-requirement__sheet td {
    width: 70%;
    background: #f2f0f0;
    font-size: 1.25rem; }

@media screen and (max-width: 767px) {
  .p-requirement__title h3 {
    line-height: 1.4; }
    .p-requirement__title h3 span {
      display: block; }
      .p-requirement__title h3 span.type {
        width: 100%; }
      .p-requirement__title h3 span.title {
        width: 100%;
        line-height: 1.6; }
      .p-requirement__title h3 span.number {
        width: 100%;
        text-align: left; }
  .p-requirement__title.-finished {
    display: block; }
    .p-requirement__title.-finished h3 {
      width: 100%; }
    .p-requirement__title.-finished .end {
      width: 100%;
      text-align: left;
      line-height: 1.6; } }

@media screen and (max-width: 575px) {
  .p-requirement__sheet tr {
    display: block;
    border-bottom: none; }
  .p-requirement__sheet th,
  .p-requirement__sheet td {
    display: block;
    width: 100%;
    padding: 1rem 1rem; }
  .p-requirement__sheet td {
    padding: 1.25rem 1rem; } }

/*============================================

  OTHER PAGE

============================================*/
/*-------------------------------
  MEMBER > ARCHIVE
-------------------------------*/
.p-mem_archive {
  padding: 6rem 0 11rem; }
  .p-mem_archive .c-lead {
    margin-bottom: 2em;
    font-size: 1.1rem; }
  @media screen and (max-width: 991px) {
    .p-mem_archive {
      padding: 4rem 0 5rem; } }

.p-mem_item:nth-child(n+2) {
  margin-top: 4rem; }

.p-mem_item__title {
  font-size: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  line-height: 1;
  border-bottom: solid 1px #231815;
  margin-bottom: 1.25em; }
  .p-mem_item__title span {
    display: block; }
    .p-mem_item__title span.en {
      line-height: .75;
      font-family: "Lato", sans-serif;
      margin-right: 1em;
      letter-spacing: .05em; }
    .p-mem_item__title span.ja {
      font-size: .625em;
      font-weight: 400; }

.p-mem_item__list li {
  width: 25%; }

.p-mem_item__img {
  padding-top: 61.777%; }

.p-mem_item__info {
  line-height: 1.5;
  padding: .75em 0; }

.p-mem_item__name {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 400; }
  .p-mem_item__name span {
    display: block; }
    .p-mem_item__name span.en {
      margin-right: 2em;
      font-family: "Lato", sans-serif;
      font-size: 1.1em; }

.p-mem_item__position {
  margin-top: .25em; }

@media screen and (max-width: 991px) {
  .p-mem_item__list li {
    width: calc(100% / 3); }
  .p-mem_item__info {
    padding: 1em; } }

@media screen and (max-width: 767px) {
  .p-mem_item__list li {
    width: 50%; } }

@media screen and (max-width: 575px) {
  .p-mem_item__title {
    position: relative;
    display: block; }
    .p-mem_item__title span.en {
      line-height: 1.2; }
    .p-mem_item__title span.ja {
      position: absolute;
      right: .5em;
      bottom: 1.2rem; }
  .p-mem_item__list li {
    width: 100%; } }

/*-------------------------------
  SERVICE > ARCHIVE
-------------------------------*/
.p-ser_archive {
  padding: 5rem 0 10rem; }
  .p-ser_archive__text {
    margin-bottom: 4rem; }
    .p-ser_archive__text .c-lead {
      font-size: 1.1rem; }
  .p-ser_archive__list {
    justify-content: flex-start; }
  @media screen and (max-width: 991px) {
    .p-ser_archive {
      padding: 4rem 0 8rem; }
      .p-ser_archive__text {
        margin-bottom: 3rem; }
        .p-ser_archive__text .c-lead_ttl .catch {
          letter-spacing: 0.3em; } }
  @media screen and (max-width: 575px) {
    .p-ser_archive__text .c-lead_ttl .catch {
      letter-spacing: 0.2em; }
    .p-ser_archive__text .c-lead {
      font-size: .95rem; } }

.p-ser_archive_item {
  width: calc(100% / 3); }
  .p-ser_archive_item__inner {
    position: relative;
    display: block; }
    @media screen and (min-width: 992px) {
      .p-ser_archive_item__inner:hover .p-ser_archive_item__text {
        opacity: 1; } }
  .p-ser_archive_item__img {
    padding-top: 76.875%;
    z-index: 1; }
  .p-ser_archive_item__text {
    position: absolute;
    padding: 2.25em 2.5em;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .6s;
    z-index: 2; }
    .p-ser_archive_item__text > div {
      justify-content: flex-start;
      flex-wrap: wrap; }
      .p-ser_archive_item__text > div .ttl {
        white-space: nowrap;
        width: 4em; }
        .p-ser_archive_item__text > div .ttl:after {
          content: "："; }
      .p-ser_archive_item__text > div .text {
        width: calc(100% - 4em); }
  @media screen and (max-width: 991px) {
    .p-ser_archive_item__text {
      position: relative;
      opacity: 1;
      background: none;
      padding: 1em 1.25em;
      color: #231815; }
    .p-ser_archive_item:nth-child(n+4) {
      margin-top: 2rem; } }
  @media screen and (max-width: 767px) {
    .p-ser_archive_item {
      width: 50%; }
      .p-ser_archive_item:nth-child(n+3) {
        margin-top: 2rem; }
      .p-ser_archive_item__text {
        line-height: 1.875; } }
  @media screen and (max-width: 575px) {
    .p-ser_archive_item {
      width: 100%; }
      .p-ser_archive_item:nth-child(n+2) {
        margin-top: 2rem; } }

/*-------------------------------
  SERVICE > SINGLE
-------------------------------*/
.p-ser_single {
  padding: 5rem 0 3rem; }
  .p-ser_single__head {
    padding-bottom: 2.5rem;
    border-bottom: solid 2px #231815;
    margin-bottom: 4rem; }
  .p-ser_single__category {
    font-family: "Lato", sans-serif;
    line-height: 1;
    font-size: 2rem;
    margin-bottom: 1.75em; }
    .p-ser_single__category span {
      display: inline-block;
      padding: .5em .75em;
      transform: translateX(-0.75em);
      border: solid 1px #231815; }
  .p-ser_single__info table th,
  .p-ser_single__info table td {
    vertical-align: top;
    line-height: 1;
    text-align: left; }
  .p-ser_single__info table th {
    white-space: nowrap;
    width: 7em; }
  .p-ser_single__info table td {
    width: calc(100% - 7em); }
    .p-ser_single__info table td > * {
      position: relative;
      padding-left: 1.5em; }
      .p-ser_single__info table td > *:before {
        position: absolute;
        content: "：";
        left: 0;
        top: 0; }
  .p-ser_single__info h1, .p-ser_single__info p {
    font-weight: 400;
    line-height: 1.5; }
  .p-ser_single__list {
    justify-content: center; }
    .p-ser_single__list .video {
      width: 100%; }
      .p-ser_single__list .video video {
        width: 100%;
        height: auto; }
      .p-ser_single__list .video a {
        display: block;
        color: #e50012;
        margin-top: .75em;
        line-height: 1.1; }
        @media screen and (min-width: 992px) {
          .p-ser_single__list .video a:hover {
            opacity: .5;
            text-decoration: none; } }
    .p-ser_single__list figure {
      margin-bottom: 7.5rem; }
      .p-ser_single__list figure a {
        display: block;
        color: #e50012; }
        @media screen and (min-width: 992px) {
          .p-ser_single__list figure a:hover {
            opacity: .5;
            text-decoration: none; } }
      .p-ser_single__list figure img {
        max-width: 100%;
        height: auto; }
      .p-ser_single__list figure span {
        display: block;
        text-decoration: underline;
        word-break: break-all;
        line-height: 1.1;
        padding-top: .75em; }
      .p-ser_single__list figure p {
        margin-top: 1.5em; }
  @media screen and (max-width: 991px) {
    .p-ser_single {
      padding: 4rem 0 2rem; }
      .p-ser_single__category {
        margin-bottom: 1em;
        font-size: 1.5rem; }
        .p-ser_single__category span {
          transform: translateX(0); }
      .p-ser_single__info {
        font-size: 1.1rem; }
        .p-ser_single__info h1 .ttl:after, .p-ser_single__info p .ttl:after {
          margin: 0 .75em; }
      .p-ser_single__list figure {
        margin-bottom: 5rem; } }
  @media screen and (max-width: 767px) {
    .p-ser_single__head {
      padding-bottom: 2rem;
      margin-bottom: 3rem; }
    .p-ser_single__list figure {
      margin-bottom: 4rem; } }
  @media screen and (max-width: 575px) {
    .p-ser_single__list figure {
      margin-bottom: 3rem; }
      .p-ser_single__list figure.-vertical {
        width: 100%; } }

/*-------------------------------
  NEWS > ARCHIVE
-------------------------------*/
.p-news_archive {
  padding: 5rem 0; }
  @media screen and (max-width: 991px) {
    .p-news_archive {
      padding: 4rem 0; } }

/*-------------------------------
  NEWS > SINGLE
-------------------------------*/
.p-news_single {
  padding: 5rem 0; }
  .p-news_single__head {
    padding-bottom: 2rem;
    border-bottom: solid 2px #231815;
    margin-bottom: 3rem; }
  .p-news_single__info {
    line-height: 1.5;
    margin-bottom: 1.5em; }
    .p-news_single__info > * {
      display: inline-block;
      vertical-align: middle; }
    .p-news_single__info .badge {
      width: 4em;
      background: #fff;
      border: solid 1px #231815;
      font-family: "Lato", sans-serif;
      text-align: center;
      margin-left: 2.5em; }
  .p-news_single__title {
    font-size: 2rem;
    font-weight: 400; }
  .p-news_single__body {
    margin-bottom: 3rem; }
  @media screen and (max-width: 991px) {
    .p-news_single {
      padding: 4rem 0; }
      .p-news_single__body {
        margin-bottom: 2rem; } }

/*-------------------------------
  MAGAZINE > ARCHIVE
-------------------------------*/
.p-mag_archive {
  padding: 5rem 0; }
  @media screen and (max-width: 991px) {
    .p-mag_archive {
      padding: 4rem 0; } }

/*-------------------------------
  CONTACT > INPUT
-------------------------------*/
.p-inq_form {
  padding: 5rem 0 7.5rem; }
  .p-inq_form__title {
    font-family: "Lato", sans-serif;
    font-size: 3.75rem;
    line-height: 1.1; }
  .p-inq_form__head {
    margin-bottom: 5rem; }
    .p-inq_form__head .c-lead {
      font-size: 1.75rem;
      margin-top: 1.5em;
      line-height: 1.7; }
  @media screen and (max-width: 1199px) {
    .p-inq_form .c-lead {
      font-size: 1.5rem; } }
  @media screen and (max-width: 991px) {
    .p-inq_form {
      padding: 4rem 0 5rem; }
      .p-inq_form__title {
        font-size: 3rem; }
      .p-inq_form__head {
        margin-bottom: 3rem; }
        .p-inq_form__head .c-lead {
          font-size: 1.25rem; } }

/*-------------------------------
  PRIVACY
-------------------------------*/
.p-privacy_main {
  padding: 5rem 0; }
  .p-privacy_main__head {
    font-size: 2.65rem;
    font-weight: 400;
    padding-bottom: .5em;
    border-bottom: solid 2px #231815;
    max-width: 75%;
    margin: 0 auto 2.5em; }
  .p-privacy_main__body {
    max-width: 75%;
    margin: 0 auto; }

.p-privacy_intro__title {
  background: #EBE8EB;
  text-align: center;
  font-weight: 400;
  font-size: 2.25rem;
  padding: .5em 0;
  margin-bottom: .5em; }

.p-privacy_intro .c-lead {
  font-size: 1.5rem;
  line-height: 1.875;
  margin-bottom: 1em; }

.p-privacy_list__title {
  font-size: 1.8rem;
  border-left: solid 5px #231815;
  padding-left: 12px;
  margin-bottom: 1em; }
  .p-privacy_list__title h3 {
    font-weight: 400; }

.p-privacy_list__text {
  font-size: 1.5rem;
  line-height: 1.875; }
  .p-privacy_list__text:not(:last-child) {
    margin-bottom: 2em; }
  .p-privacy_list__text > p + ol {
    margin-top: 2em; }
  .p-privacy_list__text ol {
    padding-left: 2em; }
    .p-privacy_list__text ol li {
      list-style: decimal; }

/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound_main {
  padding: 5rem 0 7.5rem; }
  .p-notfound_main .c-lead {
    margin-bottom: 2.5em; }
  @media screen and (max-width: 991px) {
    .p-notfound_main {
      padding: 4rem 0 5rem; } }

/*--------------------------------------------
  print
--------------------------------------------*/
