@charset "utf-8";
/*============================================

  INDEX

============================================*/
/*-------------------------------
  INDEX > KEY
-------------------------------*/
.p-idx_key{
  position: relative;
  width: 100%;
  height: 52vw;
  @include mq(screen){
    &__inner{
      &:not(.slick-initialized){
        display: none;
      }
    }
  }
}

.p-idx_key_slide{
  $amp:&;
  &__inner{
    position: relative;
    display: block;
    height: 52vw;
    overflow: hidden;
  }
  &__img{
    position: relative;
    z-index: 1;
    padding-top: 52%;
  }
  &__text{
    position: absolute;
    color: #fff;
    z-index: 2;
    width: 94%;
    left: 3%;
    h2{
      line-height: 1;
      font-weight: 400;
      font-family: $base_font_en;
      letter-spacing: .03em;
      span{
        display: block;
        opacity: 0;
        transition-property: opacity,transform;
        transition-timing-function: ease-in;
      }
    }
  }
  &.-slide01{
    #{$amp}__text{
      top: 50%;
      transform:translateY(-50%);
      overflow: hidden;
      h2{
        font-size: 12.5rem;
        span{
          transform:translateX(.5em);
        }
      }
    }
  }
  &.-slide02{
    #{$amp}__text{
      bottom: 1rem;
      h2{
        font-size: 12rem;
        span{
          transform:translateY(.5em);
          &.sm{
            padding-left: .025em;
            font-size: .56em;
            letter-spacing: .025em;
          }
          &.lg{
            font-size: 1em;
          }
        }
      }
    }
  }
  &.-slide03{
    #{$amp}__text{
      top: 3rem;
      h2{
        text-align: center;
        font-size: 12rem;
        span{
          transform:translateY(.5em);
        }
      }
    }
  }
  &.-slide04{
    background: #fff;
    a{
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
    #{$amp}__text{
      width: 90%;
      left: 5%;
      bottom:5rem;
      h2{
        font-size: 2.25rem;
        font-family: $base_font;
        span{
          transform:translateY(.5em);
          &:nth-child(n+2){
            margin-top: .75em;
          }
        }
      }
    }
  }
  @include mq(screen){
    &.-slide01{
      #{$amp}__text{
        h2{
          span{
            transform:translateX(.5em);
          }
        }
      }
    }
    &.-slide02{
      #{$amp}__text{
        h2{
          span{
            transform:translateY(.5em);
          }
        }
      }
    }
    &.-slide03{
      #{$amp}__text{
        h2{
          span{
            transform:translateY(.5em);
          }
        }
      }
    }
    &.-slide04{
      #{$amp}__text{
        h2{
          span{
            transform:translateY(.5em);
          }
        }
      }
    }
    .slick-slide.is-anime &{
      &__img{
        img{
          animation: fadezoom 8s 0s forwards;
        }
      }
      &.-slide01{
        #{$amp}__text{
          h2{
            span{
              opacity: 1;
              transform:translateX(0);
            }
          }
        }
      }
      &.-slide02{
        #{$amp}__text{
          h2{
            span{
              opacity: 1;
              transform:translateY(0);
            }
          }
        }
      }
      &.-slide03{
        #{$amp}__text{
          h2{
            span{
              opacity: 1;
              transform:translateY(0);
            }
          }
        }
      }
      &.-slide04{
        #{$amp}__text{
          .logo{
            opacity: 1;
          }
          h2{
            span{
              opacity: 1;
              transform:translateY(0);
            }
          }
        }
      }
    }
  }
  @include mq(md){
    &.-slide01{
      #{$amp}__text{
        h2{
          font-size: 10rem;
        }
      }
    }
    &.-slide02{
      #{$amp}__text{
        h2{
          font-size: 10rem;
        }
      }
    }
    &.-slide03{
      #{$amp}__text{
        h2{
          font-size: 10rem;
        }
      }
    }
    &.-slide04{
      #{$amp}__text{
        .logo{
          width: 20rem;
        }
        h2{
          font-size: 2.25rem;
        }
      }
    }
  }
  @include mq(sm){
    &.-slide01{
      #{$amp}__text{
        h2{
          font-size: 8rem;
        }
      }
    }
    &.-slide02{
      #{$amp}__text{
        h2{
          font-size: 8rem;
        }
      }
    }
    &.-slide03{
      #{$amp}__text{
        h2{
          font-size: 8rem;
        }
      }
    }
    &.-slide04{
      #{$amp}__text{
        left: 3%;
        bottom:4rem;
        h2{
          font-size: 1.75rem;
          margin-top: 1em;
        }
      }
    }
  }
  @include mq(xs){
    &__inner{
      height: 56vw;
    }
    &__img{
      padding-top: 56%;
    }
    &.-slide01{
      #{$amp}__text{
        h2{
          font-size: 7rem;
        }
      }
    }
    &.-slide02{
      #{$amp}__text{
        h2{
          font-size: 7rem;
        }
      }
    }
    &.-slide03{
      #{$amp}__text{
        h2{
          font-size: 6.5rem;
        }
      }
    }
  }
  @include mq(ss){
    &.-slide01{
      #{$amp}__text{
        h2{
          font-size: 4rem;
        }
      }
    }
    &.-slide02{
      #{$amp}__text{
        h2{
          font-size: 4rem;
        }
      }
    }
    &.-slide03{
      #{$amp}__text{
        top: 2rem;
        h2{
          font-size: 3.5rem;
        }
      }
    }
  }
}
@keyframes fadezoom {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
/*-------------------------------
  INDEX > ABOUT
-------------------------------*/
.p-idx_about{
  $amp: &;
  padding:$margin_lg 0 $margin_md;
  overflow: hidden;
  &__inner{
    width: 100%;
    max-width: 100%;
  }
  &__body{
    position: relative;
    padding: 0 3%;
  }

  &__img{
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    svg{
      width: 47.5rem;
      height: 33.15rem;
      path{
        fill: none;
        stroke: #6e6e7e;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2px;
      }
    }
  }
  &__text{
    position: relative;
    z-index: 2;
    padding-bottom: 20rem;
    transition: 1s;
    .c-lead{
      line-height: 3;
    }
  }
  &__btn{
    margin-top: $margin_ss;
  }
  @include mq(screen){
    &__text{
      .c-lead_ttl{
        .ttl,.catch{
          @include animation('fadeLeft','before');
        }
      }
      p{
        @include animation('fadeLeft','before');
      }
    }
    &.is-anime{
      #{$amp}__text{
        .c-lead_ttl{
          .ttl,.catch{
            @include animation('fadeLeft','after');
          }
        }
        p{
          @include animation('fadeLeft','after');
        }
      }
    }
  }
  @include mq(md){
    &__img{
      svg{
        width: 38rem;
        height: 26.6rem;
      }
    }
    &__text{
      padding-bottom: 10rem;
    }
  }

  @include mq(sm){
    &__body{
      padding: 0;
    }
    &__text{
      padding: 0 3%;
    }
    &__img{
      position: static;
      margin-top: $margin_ss;
      text-align: center;
    }
  }
  @include mq(xs){
    &__text{
      .c-lead_ttl{
        .catch{
          letter-spacing: 0.35em;
        }
      }
      .c-lead{
        line-height: 2.5;
      }
    }
  }
  @include mq(ss){
    padding: $margin_md 0;
    &__img{
      position: relative;
      padding-top: 60%;
      svg{
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
        top: 0;
      }
    }
    &__text{
      .c-lead{
        line-height: 2;
      }
    }
  }
}

/*-------------------------------
  INDEX > SERVICE
-------------------------------*/
.p-idx_service{
  $amp : &;
  padding: $margin_md 0;
  overflow: hidden;
  .c-lead{
    margin-bottom: 2.5em;
  }
  &__list{
    justify-content: space-between;
    margin-bottom: $margin_sm;
  }
  @include mq(screen){
    .c-bg_ttl{
      @include animation('fadeLeft','before');
    }
    .c-main_ttl,
    .c-lead{
      @include animation('fadeLeft','before');
    }
    &.is-anime{
      .c-bg_ttl{
        @include animation('fadeLeft','after');
      }
      .c-main_ttl,
      .c-lead{
        @include animation('fadeLeft','after');
      }
    }
  }
}

.p-idx_service_item{
  $amp: &;
  width: calc((100% - .7rem) / 3);
  background: #fff;
  margin-top: .35rem;
  padding: 3rem 1.5rem;
  &.-full{
    width: 100%;
  }
  p{
    text-align: center;
  }
  &__cat{
    font-family: $base_font_en;
    line-height: 1;
    text-align: center;
    font-weight: 700;
    &.-sm{
      font-size: .85rem;
    }
  }
  &__title{
    font-size: 2rem;
    line-height: 1.4;
    margin-bottom: 1em;
    text-align: center;
    &.-sm{
      font-size: 1.75rem;
      margin-bottom: .25em;
    }
  }
  &__ico{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height:10.75rem;
    svg{
      width: 100%;
    }
    &.-design{
      svg{
        height: 5.85rem;
      }
    }
    &.-sales{
      svg{
        height: 6.6rem;
      }
    }
    &.-exhibition{
      svg{
        height: 5.75rem;
      }
    }
    &.-print{
      padding-left: 1.5rem;
      svg{
        height: 5.9rem;
      }
    }
    &.-video{
      svg{
        height: 4.3rem;
      }
    }
    &.-web{
      svg{
        height: 4.73rem;
      }
    }
    &.-event{
      svg{
        height: 5.9rem;
      }
    }
    &.-campaign{
      svg{
        height: 5.9rem;
      }
    }
  }
  &__list{
    justify-content: space-between;
    li{
      width: 20%;
    }
  }
  @include mq(screen){
    @include animation('fadeIn','before');
    .is-anime &{
      @include animation('fadeIn','after');
    }
  }
  @include mq(w1400){
    padding: 3rem 1.25rem;
  }
  @include mq(md){
    &:not(.-full){
      p{
        text-align: left;
      }
    }
  }
  @include mq(sm){
    width: 100%;
    &:not(.-full){
      #{$amp}__inner{
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }
    &__img{
      width: 30%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column-reverse;
      #{$amp}__cat{
        margin-top: 1em;
        margin-bottom: 0;
      }
    }
    &__text{
      width: 65%;
      #{$amp}__title{
        text-align: left;
      }
    }
    &__title{
      margin-bottom: .5em;
      &.-sm{
        font-size: 1.35rem;
        text-align: center;
      }
    }
    &__ico{
      height: auto;
    }
    &__cat{
      margin-bottom: .5em;
      line-height: 1.5;
    }
    &__list{
      margin-top: 2rem;
      justify-content: center;
      li{
      }
      #{$amp}__ico{
        position: relative;
        width: 40%;
        padding-top: 40%;
        margin:0 auto;
        height: 0;
        text-align: center;
        margin-bottom: .5rem;
        svg{
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100% !important;
        }
      }
      #{$amp}__text{
        width: 100%;
      }
    }
  }
  @include mq(xs){
    &__title{
      &.-sm{
        font-size: 1.125rem;
      }
    }
  }
  @include mq(ss){
    &:not(.-full){
      #{$amp}__inner{
        display: block;
      }
    }
    &__img{
      width: 100%;
    }
    &__text{
      width: 100%;
      #{$amp}__title{
        text-align: center;
      }
    }
    &__ico{
      margin-bottom: .5rem;
    }
    &__title{
      font-size: 1.75rem;
      &.-sm{
        text-align: left;
      }
    }
    &__cat{
      &.-sm{
        text-align: left;
      }
    }
    &__list{
      display: block;
      li{
        width: 100%;
        background: #f5f5f5;
        padding: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        #{$amp}__ico{
          width: 20%;
          padding-top: 20%;
          margin-bottom: 0;
          margin: 0;
        }
        #{$amp}__text{
          width: 80%;
          padding-left: 1.5rem;
        }
        &:nth-child(n+2){
          margin-top: .5rem;
        }
      }
    }
  }
}

/*-------------------------
  INDEX > WORKS
-------------------------*/
.p-idx_works{
  $amp:&;
  padding: $margin_ll 0 $margin_lg;
  overflow: hidden;
  .c-lead{
    margin-bottom: 4.5em;
  }
  &__list{
    position: relative;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    margin-bottom: $margin_md;
  }
  @include mq(screen){
    .c-main_ttl,
    .c-lead{
      @include animation('fadeLeft','before');
    }
    &.is-anime{
      .c-main_ttl,
      .c-lead{
        @include animation('fadeLeft','after');
      }
    }
  }
  @include mq(sm){
    .c-lead{
      margin-bottom: 2.5rem;
    }
  }
  @include mq(xs){
    padding: $margin_lg 0;
    &__list{
      justify-content: space-between;
      margin-bottom: $margin_sm;
    }
  }
  @include mq(xs){
    &__list{
      margin-bottom: $margin_ss;
    }
  }
}

.p-idx_works_item{
  $amp:&;
  width: calc((100% - 2px) / 3);
  margin-right: 1px;
  &:nth-child(3n){
    margin-right: 0;
  }
  &:nth-child(n+4){
    margin-top: 1px;
  }
  &__img{
    position: relative;
    z-index: 1;
  }
  &__text{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.6);
    padding: 2.5rem;
    z-index: 2;
  }
  &__info{
    font-weight: 400;
    color: #fff;
    span{
      display: block;
      &:nth-child(n+2){
        margin-top: .5em;
      }
    }
  }
  a{
    position: relative;
    display: block;
    @include mq(sm-){
      #{$amp}__text{
        transition: .6s;
        transform:scale(0);
      }
      &:hover{
        #{$amp}__text{
          transform:scale(1);
        }
      }
    }
  }
  @include mq(screen){
    @include animation('fadeIn','before');
    .is-anime &{
      @include animation('fadeIn','after');
    }
  }
  @include mq(sm){
    width: calc(50% - 1px);
    margin-right: 0;
    &__text{
      position: static;
      padding: 1.25rem 1rem;
      background: none;
    }
    &__info{
      color: $font_color;
      line-height: 1.6;
    }
  }
  @include mq(ss){
    width: 100%;
  }
}


/*-------------------------
  INDEX > PROJECT
-------------------------*/
.p-idx_business{
  $amp: &;
  padding: $margin_ll 0 $margin_md*2;
  overflow: hidden;
  .c-lead{
    margin-bottom: 3.3em;
  }
  @include mq(screen){
    .c-main_ttl,
    .c-lead{
      @include animation('fadeLeft','before');
    }
    &.is-anime{
      .c-main_ttl,
      .c-lead{
        @include animation('fadeLeft','after');
      }
    }
  }
  @include mq(xs){
    padding: $margin_lg 0;
  }
}

.p-idx_business_item{
  padding: $margin_sm * 2 $margin_md;
  background: #E1D9DC;
  &:nth-child(n+2){
    margin-top: $margin_lg;
  }
  &__img{
    background: #fff;
    margin-bottom: $margin_md;
    a{
      display: block;
      img{
        width: 100%;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  &__bnr{
    background: #fff;
    margin-top: $margin_md;
    a{
      display: block;
      img{
        width: 100%;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  .c-lead{
    margin-bottom: 0;
  }
  @include mq(screen){
    @include animation('fadeUp','before');
    .is-anime &{
      @include animation('fadeUp','after');
    }
  }
  @include mq(sm){
    padding: $margin_sm $margin_ss;
    &:nth-child(n+2){
      margin-top: $margin_md;
    }
    &__img{
      margin-bottom: $margin_sm;
    }
    &__bnr{
      margin-top: $margin_sm;
    }
  }
  @include mq(ss){
    padding: $margin_ss;
    &__img{
      margin-bottom: $margin_ss;
    }
    &__bnr{
      margin-top: $margin_ss;
    }
    &:nth-child(n+2){
      margin-top: $margin_sm;
    }
  }
}

/*-------------------------
  INDEX > CONTENTS
-------------------------*/
.p-idx_contents{
  $amp: &;
  padding-bottom: 10rem;
  overflow: hidden;
  &__box{
    justify-content: space-between;
    align-items: stretch;
    &:nth-child(n+2){
      margin-top: 10rem;
    }
  }
  &__text{
    width:60%;
    .c-main_ttl{
      margin-bottom: .75em;
    }
    .c-sub_ttl{
      margin-bottom: 1.75em;
    }
  }
  &__bnr{
    width:40%;
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
    flex-direction: column;
    a{
      display: block;
      img{
        max-width: 100%;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  @include mq(screen){
    .c-bg_ttl{
      @include animation('fadeLeft','before');
    }
    &__text{
      .c-main_ttl,
      .c-sub_ttl,
      .c-lead{
        @include animation('fadeLeft','before');
      }
    }
    &__bnr{
      @include animation('fadeIn','before');
    }
    &.is-anime{
      .c-bg_ttl{
        @include animation('fadeLeft','after');
      }
      #{$amp}__text{
        .c-main_ttl,
        .c-sub_ttl,
        .c-lead{
          @include animation('fadeLeft','after');
        }
      }
      #{$amp}__bnr{
        @include animation('fadeIn','after');
      }
    }
  }
  @include mq(sm){
    &__text{
      .c-sub_ttl{
        margin-bottom: 1.25em;
      }
    }
  }
  @include mq(xs){
    padding-bottom: $margin_ll;
    &__box{
      &:nth-child(n+2){
        margin-top: $margin_md;
      }
    }
    &__text{
      width: 100%;
    }
    &__bnr{
      margin-top: $margin_ss;
      width: 45%;
    }
  }
  @include mq(ss){
    padding-bottom: $margin_lg;
    &__bnr{
      width: 100%;
    }
  }
}

/*-------------------------
  INDEX > MEMBER
-------------------------*/
.p-idx_member{
  $amp:&;
  padding-bottom: $margin_ll;
  overflow: hidden;
  &__bnr{
    margin-top: 2.5rem;
    a{
      img{
        max-width: 100%;
      }
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
  }
  @include mq(screen){
    .c-bg_ttl,
    .c-sub_ttl,
    p{
      @include animation('fadeLeft','before');
    }
    &__bnr{
      @include animation('fadeUp','before');
    }
    &.is-anime{
      .c-bg_ttl,
      .c-sub_ttl,
      p{
        @include animation('fadeLeft','after');
      }
      #{$amp}__bnr{
        @include animation('fadeUp','after');
      }
    }
  }
  @include mq(xs){
    padding-bottom: $margin_lg;
  }
}

/*-------------------------
  INDEX > NEWS
-------------------------*/
.p-idx_news{
  $amp:&;
  padding: $margin_lg 0;
  overflow: hidden;
  &__btn{
    margin-top: $margin_md;
  }
  @include mq(screen){
    .c-bg_ttl{
      @include animation('fadeLeft','before');
    }
    .c-news_list{
      @include animation('fadeIn','before');
    }
    &.is-anime{
      .c-bg_ttl{
        @include animation('fadeLeft','after');
      }
      .c-news_list{
        @include animation('fadeIn','after');
      }
    }
  }
  @include mq(xs){
    padding: $margin_md 0;
  }
  @include mq(xs){
    &__btn{
      margin-top: $margin_sm;
    }
  }
}

/*-------------------------
  INDEX > ACCESS
-------------------------*/
.p-idx_access{
  $amp:&;
  padding: 7rem 0 9rem;
  overflow: hidden;
  h3{
    line-height: 1;
    margin-bottom: 1em;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: $base_font_en;
    letter-spacing: .1em;
  }
  p{
    font-size: 1.25rem;
    line-height: 1.5;
    margin-bottom: 1.5em
  }
  .c-gmap{
    padding-top: 0;
    height: 400px;
  }
  @include mq(screen){
    .c-bg_ttl
    h3,
    p{
      @include animation('fadeLeft','before');
    }
    .c-gmap{
      @include animation('fadeIn','before');
    }
    &.is-anime{
      .c-bg_ttl
      h3,
      p{
        @include animation('fadeLeft','after');
      }
      .c-gmap{
        @include animation('fadeIn','after');
      }
    }
  }
  @include mq(sm){
    padding: 3rem 0 $margin_lg;
  }
  @include mq(xs){
    padding: 3rem 0 $margin_md;
  }
  @include mq(ss){
    padding: 0 0 $margin_md;
  }
}