@charset "utf-8";
/*--------------------------------------------
  HEADER
--------------------------------------------*/
.l-header{
  position: fixed;
  z-index: 110;
  left: 0;
  top: 0;
  width: 100%;
  background: rgba(255,255,255,.85);
  box-shadow: 0 .25rem .25rem rgba(0,0,0,.1);
  $amp :&;
  &__inner{
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
  }
  &__logo{
    a{
      display: block;
      @include mq(sm-){
        &:hover{
          opacity: .5;
        }
      }
    }
    img{
      width: auto;
      height: 3rem;
    }
  }
  &__toggle{
    position: relative;
    z-index: 102;
    .so_toggle{
      position: absolute;
      background: transparent;
      width: 3.6rem;
      height: 3.6rem;
      padding:0;
      left:50%;
      top:50%;
      transform:translate(-50%,-50%);
      .hamburger-icon{
        background: transparent;
        top:0;
        &:before,&:after{
          background: $font_color;
          top:0;
        }
        &:before{
          transform:translateY(-4px);
        }
        &:after{
          transform:translateY(4px);
        }
      }
    }
  }
  .slideout-open & {
    &__toggle{
      background: rgba(255,255,255,0);
      .so_toggle{
        .hamburger-icon{
          &:before,&:after{
            background: #fff;
          }
          &:before{
            transform:rotate(45deg);
          }
          &:after{
            transform:rotate(-45deg);
          }
        }
      }
    }
  }
  @include mq(sm){
    &__toggle{
      width: 5rem;
      height: 5rem;
      .so_toggle{
        position: absolute;
        width: 72%;
        height: 72%;
      }
    }
  }
  @include mq(ss){
    &__inner{
      padding: .5rem 0;
    }
    &__logo{
      img{
        height: 2.5rem;
      }
    }
    &__toggle{
      width: 3.6rem;
      height: 3.6rem;
    }
  }
}