@charset "utf-8";
/*============================================

  ABOUT

============================================*/
/*-------------------------------
  ABOUT > THEME
-------------------------------*/
.p-abt_theme{
  padding: $margin_lg 0;
  &__list{
    justify-content: center;
  }
  @include mq(w1400){
    &__list{
      justify-content: space-between;
    }
  }
  @include mq(md){
    .c-lead_ttl{
      .catch{
        letter-spacing: 0.4em;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
      display: block;
    }
  }
  @include mq(xs){
    .c-lead_ttl{
      .catch{
        letter-spacing: 0.25em;
      }
    }
  }
  @include mq(ss){
    .c-lead_ttl{
      .ttl{
        .en{
          font-size: .925em;
        }
      }
    }
  }
}
.p-abt_theme_main{
  margin-bottom: $margin_md;
  justify-content: space-between;
  align-items: flex-end;
  &__text{
    width: calc(100% - 30.25rem);
    padding-right: 2rem;
    .c-lead{
      line-height: 3;
    }
  }
  &__img{
    padding-right: 3%;
    width: 30.25rem;
    svg{
      width: 100%;
      height: 18.09rem;
    }
  }
  @include mq(sm){
    margin-bottom:$margin_ss;
    &__img{
      width: 40%;
    }
    &__text{
      width: 58%;
      .c-lead{
        line-height: 2.5;
      }
    }
  }
  @include mq(xs){
    &__img{
      width: 100%;
      margin-top: $margin_ss;
      svg{
        height: 13rem;
      }
    }
    &__text{
      width: 100%;
    }
  }
}

.p-abt_theme_item{
  $amp:&;
  position: relative;
  max-width: 40%;
  border-radius: 50%;
  &__img{
    position: relative;
    z-index: 1;
    img{
      max-width: 100%;
    }
  }
  &__inner{
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20% 10%;
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &.-sales{
    margin-left: 20%;
  }
  &.-exhibition{
    margin-top: -10%;
  }
  &__title{
    font-size: 2rem;
    text-align: center;
    margin-bottom: .25em;
    span{
      display: block;
      &.en{
        font-size: .45em;
        line-height: 1.3;
        margin-bottom: .35em;
      }
      &.ja{
        line-height: 1.1;
      }
    }
  }
  &__text{
    font-size: .9rem;
    text-align: center;
  }
  @include mq(w1400){
    width: 33%;
    max-width: 33%;
    &__img{
      display: none;
    }
    &__inner{
      position: relative;
      display: block;
      padding: 0;
    }
    &__title{
      position: relative;
      width: 100%;
      padding-top: 100%;
      font-size: 1.8rem;
      background: url('../img/about/img_theme_design.png') no-repeat center center / contain;
      margin-bottom: 1em;
      h3{
        position: absolute;
        left: 50%;
        top: 50%;
        transform:translate(-50%,-50%);
      }
    }
    &__text{
      font-size: 1rem;
    }
    &.-sales{
      margin-left: 0%;
      #{$amp}__title{
        background-image: url('../img/about/img_theme_sales.png');
      }
    }
    &.-exhibition{
      margin-top: 0%;
      #{$amp}__title{
        background-image: url('../img/about/img_theme_exhibition.png');
      }
    }
  }
  @include mq(md){
    &__title{
      h3{
        span{
          &.ja{
            padding-bottom: 1em;
          }
        }
      }
    }
  }
  @include mq(sm){
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:nth-child(odd){
      flex-direction: row-reverse;
    }
    &__img{
      display: block;
      width: 30%;
    }
    &__inner{
      width: 65%;
    }
    &__title{
      padding: 0;
      background-image: none;
      text-align: left;
      h3{
        position: static;
        transform:translate(0,0);
      }
    }
    &__text{
      text-align: left;
    }
    &.-sales{
      margin-left: 0%;
      #{$amp}__title{
        background-image: none;
      }
    }
    &.-exhibition{
      margin-top: 0%;
      #{$amp}__title{
        background-image: none;
      }
    }
  }
  @include mq(xs){
    &__img{
      width: 40%;
    }
    &__inner{
      width: 55%;
    }
  }
  @include mq(ss){
    flex-direction: column-reverse;
    &:nth-child(odd){
      flex-direction: column-reverse;
    }
    &:nth-child(n+2){
      margin-top: $margin_md;
    }
    &__img{
      display: none;
    }
    &__inner{
      width: 100%;
    }
    &__title{
      position: relative;
      width: 100%;
      padding-top: 100%;
      background-image:url('../img/about/img_theme_design.png');
      h3{
        position: absolute;
        transform:translate(-50%,-50%);
        text-align: center;
      }
    }
    &__text{
      font-size: 1.1rem;
    }
    &.-sales{
      #{$amp}__title{
        background-image: url('../img/about/img_theme_sales.png');
      }
    }
    &.-exhibition{
      #{$amp}__title{
        background-image: url('../img/about/img_theme_exhibition.png');
      }
    }
  }
}

/*-------------------------------
  ABOUT > CONSCIOUS
-------------------------------*/
.p-abt_conscious{
  padding: $margin_md 0 $margin_lg;
  .c-lead_ttl{
    width: 100%;
  }
  &__inner{
    justify-content: space-between;
    align-items: flex-end;
    flex-direction: row-reverse;
  }
  &__img{
    transform:translateX(-3vw);
    width: 41.05rem;
    svg{
      width:100%;
      height: 18.88rem;
    }
  }
  &__text{
    width: calc(100% - 41.05rem);
    font-size: 1.1rem;
    padding-bottom: 2em;
    padding-left:1rem;
    .c-lead{
      line-height: 3.2;
    }
  }
  @include mq(w1400){
    padding: $margin_md 0;
    &__img{
      width: 38rem;
      svg{
        height: 17.48rem;
      }
    }
    &__text{
      width: calc(100% - 38rem);
      font-size: 1rem;
    }
  }
  @include mq(md){
    &__img{
      width: 32rem;
    }
    &__text{
      width: calc(100% - 32rem);
      .c-lead{
        line-height: 2.5;
      }
    }
  }
  @include mq(sm){
    padding: $margin_sm 0 $margin_md;
    display: block;
    &__text{
      width: 100%;
    }
    &__img{
      width: 100%;
    }
  }
  @include mq(xs){
    .c-lead_ttl{
      .catch{
        letter-spacing: .25em;
      }
    }
    &__img{
      svg{
        height: 13rem;
      }
    }
  }
  @include mq(ss){
    padding-bottom: 0;
    &__text{
      padding-bottom: 0;
    }
  }
}
/*-------------------------------
  ABOUT > MISSION
-------------------------------*/
.p-abt_mission{
  padding: $margin_ll 0;
  .c-lead{
    font-size: 1.1rem;
    line-height: 3;
    margin-bottom: 4.5em;
  }
  @include mq(w1400){
    padding: $margin_md 0 $margin_lg;
    .c-lead{
      margin-bottom: 4.5rem;
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    .c-lead{
      margin-bottom: 3em;
      line-height: 2.5;
    }
  }
  @include mq(xs){
    .c-lead_ttl{
      .catch{
        letter-spacing: .25em;
      }
    }
  }
}

.p-abt_mission_item{
  position: relative;
  padding-top: 25rem;
  &__img{
    text-align: center;
    svg{
      width: 100%;
      height: 27.1rem;
      #fill{
        opacity: 0;
        transition:.6s;
      }
    }
    &.is-anime{
      svg{
        #fill{
          opacity: .6;
        }
      }
    }
  }
  &__text{
    position: absolute;
    display: none;
    &:after{
      position: absolute;
      content:"";
      background: #6E6E7E;
      z-index: 2;
      height: 2px;
      transition:.6s linear .6s;
    }
    .inner{
      position: relative;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 22.5rem;
      height: 22.5rem;
      border-radius: 50%;
      color: #fff;
      z-index: 1;
    }
    h4{
      text-align: center;
      font-size: 3rem;
      margin-bottom: .5em;
      font-family: $base_font_en;
      font-weight: 400;
    }
    p{
      text-align: center;
    }
    &.-idea{
      left: 7%;
      top: 35%;
      .inner{
        background: $gray_color01;
      }
      &:after{
        width: 12rem;
        right: -11rem;
        top: 50%;
        transform-origin: left center;
        transform:rotate(3deg) scaleX(0);
      }
    }
    &.-passion{
      left: 38%;
      top: 0;
      &:after{
        left: 70%;
        bottom:-4.5rem;
        width: 2px;
        height: 7rem;
        transform-origin: center top;
        transform:scaleY(0);
      }
      .inner{
        background: $gray_color02;
      }
    }
    &.-quality{
      right: 7%;
      top: 44.5%;
      &:after{
        left:-12.5rem;
        top: 50%;
        width: 14rem;
        transform-origin: right center;
        transform:rotate(5deg) scaleX(0);
      }
      .inner{
        background: $gray_color03;
      }
    }
    .is-anime ~ &{
      .inner{
        opacity: 1;
      }
      &.-idea{
        &:after{
          transform:rotate(3deg) scaleX(1);
        }
      }
      &.-passion{
        &:after{
          transform:scaleY(1);
        }
      }
      &.-quality{
        &:after{
          transform:rotate(5deg) scaleX(1);
        }
      }
    }
  }
  @include mq(w1400){
    &__text{
      h4{
        font-size: 2.5rem;
      }
      p{
        font-size: .9rem;
      }
      &.-idea{
        &:after{
          width: 8rem;
          right: -7rem;
        }
      }
      &.-quality{
        &:after{
          width: 10rem;
          left: -8.5rem;
        }
      }
    }
  }
  @include mq(md){
    &__text{
      &.-idea{
        &:after{
          width: 5rem;
          right: -4rem;
        }
      }
      &.-quality{
        &:after{
          top: 47.5%;
          width: 7rem;
          left: -6rem;
        }
      }
    }
  }
  @include mq(sm){
    padding-top: 0;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;
    &__img{
      width: 100%;
      margin-bottom: $margin_ss;
      svg{
        height: 25rem;
      }
    }
    &__text{
      width: 33.33%;
      position: static;
      &:after{
        display: none;
      }
      .inner{
        padding: $margin_ss 0;
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 0;
        transition-delay:.6s;
      }
      &.-passion{
        width: 33.34%;
      }
    }
  }
  @include mq(xs){
    &__img{
      svg{
        height: 20rem;
      }
    }
  }
  @include mq(ss){
    display: block;
    &__text{
      width: 100%;
      .inner{
        padding: 2rem 1rem;
      }
      &.-passion{
        width: 100%;
      }
    }
  }
}