@charset "utf-8";
/*============================================

  OTHER PAGE

============================================*/
/*-------------------------------
  MEMBER > ARCHIVE
-------------------------------*/
.p-mem_archive{
  padding: 6rem 0 11rem;
  .c-lead{
    margin-bottom: 2em;
    font-size: 1.1rem;
  }
  @include mq(sm){
    padding: $margin_md 0 $margin_lg;
  }
}

.p-mem_item{
  &:nth-child(n+2){
    margin-top: $margin_md;
  }
  &__title{
    font-size: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    line-height: 1;
    border-bottom:solid 1px $font_color;
    margin-bottom: 1.25em;
    span{
      display: block;
      &.en{
        line-height: .75;
        font-family: $base_font_en;
        margin-right: 1em;
        letter-spacing: .05em;
      }
      &.ja{
        font-size: .625em;
        font-weight: 400;
      }
    }
  }
  &__list{
    li{
      width: 25%;
    }
  }
  &__img{
    padding-top: 61.777%;
  }
  &__info{
    line-height: 1.5;
    padding: .75em 0;
  }
  &__name{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 400;
    span{
      display: block;
      &.en{
        margin-right: 2em;
        font-family: $base_font_en;
        font-size: 1.1em;
      }

    }
  }
  &__position{
    margin-top: .25em;
  }
  @include mq(sm){
    &__list{
      li{
        width: calc(100% / 3);
      }
    }
    &__info{
      padding: 1em;
    }
  }
  @include mq(xs){
    &__list{
      li{
        width: 50%;
      }
    }
  }
  @include mq(ss){
    &__title{
      position: relative;
      display: block;
      span{
        &.en{
          line-height: 1.2;
        }
        &.ja{
          position: absolute;
          right: .5em;
          bottom: 1.2rem;
        }
      }
    }
    &__list{
      li{
        width: 100%;
      }
    }
  }
}

/*-------------------------------
  SERVICE > ARCHIVE
-------------------------------*/
.p-ser_archive{
  padding: $margin_lg 0 10rem;
  &__text{
    margin-bottom: $margin_md;
    .c-lead{
      font-size: 1.1rem;
    }
  }
  &__list{
    justify-content: flex-start;
  }
  @include mq(sm){
    padding: $margin_md 0 8rem;
    &__text{
      margin-bottom: $margin_sm;
      .c-lead_ttl{
        .catch{
          letter-spacing: 0.3em;
        }
      }
    }
  }
  @include mq(ss){
    &__text{
      .c-lead_ttl{
        .catch{
          letter-spacing: 0.2em;
        }
      }
      .c-lead{
        font-size: .95rem;
      }
    }
  }
}

.p-ser_archive_item{
  $amp:&;
  width: calc(100% / 3);
  &__inner{
    position: relative;
    display: block;
    @include mq(sm-){
      &:hover{
        #{$amp}__text{
          opacity: 1;
        }
      }
    }
  }
  &__img{
    padding-top: 76.875%;
    z-index: 1;
  }
  &__text{
    position: absolute;
    padding: 2.25em 2.5em;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.6);
    color: #fff;
    line-height: 1.5;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition:.6s;
    z-index: 2;
    >div{
      justify-content: flex-start;
      flex-wrap: wrap;
      .ttl{
        white-space: nowrap;
        width: 4em;
        &:after{
          content:"：";
        }
      }
      .text{
        width: calc(100% - 4em);
      }
    }
  }
  @include mq(sm){
    &__text{
      position: relative;
      opacity: 1;
      background: none;
      padding: 1em 1.25em;
      color: $font_color;
    }
    &:nth-child(n+4){
      margin-top: $margin_ss;
    }
  }
  @include mq(xs){
    width: 50%;
    &:nth-child(n+3){
      margin-top: $margin_ss;
    }
    &__text{
      line-height: 1.875;
    }
  }
  @include mq(ss){
    width: 100%;
    &:nth-child(n+2){
      margin-top: $margin_ss;
    }
  }
}

/*-------------------------------
  SERVICE > SINGLE
-------------------------------*/
.p-ser_single{
  padding: $margin_lg 0 $margin_sm;
  &__head{
    padding-bottom: 2.5rem;
    border-bottom: solid 2px $font_color;
    margin-bottom: $margin_md;
  }
  &__category{
    font-family: $base_font_en;
    line-height: 1;
    font-size: 2rem;
    margin-bottom: 1.75em;
    span{
      display: inline-block;
      padding: .5em .75em;
      transform:translateX(-.75em);
      border:solid 1px $font_color;
    }
  }
  &__info{
    table{
      th,
      td{
        vertical-align: top;
        line-height: 1;
        text-align: left;
      }
      th{
        white-space: nowrap;
        width: 7em;
      }
      td{
        width: calc(100% - 7em);
        >*{
          position: relative;
          padding-left: 1.5em;
          &:before{
            position: absolute;
            content:"：";
            left: 0;
            top:0;
          }
        }
      }
    }
    h1,p{
      font-weight: 400;
      line-height: 1.5;
    }
  }
  &__list{
    justify-content: center;
    .video{
      width: 100%;
      video{
        width: 100%;
        height: auto;
      }
      a{
        display: block;
        color: $link_color;
        margin-top: .75em;
        line-height: 1.1;
        @include mq(sm-){
          &:hover{
            opacity: .5;
            text-decoration:none;
          }
        }
      }
    }
    figure{
      margin-bottom:$margin_ll;
      a{
        display: block;
        color: $link_color;
        @include mq(sm-){
          &:hover{
            opacity: .5;
            text-decoration:none;
          }
        }
      }
      img{
        max-width: 100%;
        height: auto;
      }
      span{
        display: block;
        text-decoration:underline;
        word-break: break-all;
        line-height: 1.1;
        padding-top: .75em;
      }
      p{
        margin-top: 1.5em;
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0 $margin_ss;
    &__category{
      margin-bottom: 1em;
      font-size: 1.5rem;
      span{
        transform:translateX(0);
      }
    }
    &__info{
      font-size: 1.1rem;
      h1,p{
        .ttl{
          &:after{
            margin:0 .75em;
          }
        }
      }
    }
    &__list{
      figure{
        margin-bottom: $margin_lg;
      }
    }
  }
  @include mq(xs){
    &__head{
      padding-bottom: $margin_ss;
      margin-bottom: $margin_sm;
    }
    &__list{
      figure{
        margin-bottom: $margin_md;
      }
    }
  }
  @include mq(ss){
    &__list{
      figure{
        margin-bottom: $margin_sm;
        &.-vertical{
          width: 100%;
        }
      }
    }
  }
}
/*-------------------------------
  NEWS > ARCHIVE
-------------------------------*/
.p-news_archive{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
}

/*-------------------------------
  NEWS > SINGLE
-------------------------------*/
.p-news_single{
  padding: $margin_lg 0;
  &__head{
    padding-bottom: $margin_ss;
    border-bottom: solid 2px $font_color;
    margin-bottom: $margin_sm;
  }
  &__info{
    line-height: 1.5;
    margin-bottom: 1.5em;
    >*{
      display: inline-block;
      vertical-align: middle;
    }
    .badge{
      width: 4em;
      background: #fff;
      border:solid 1px $font_color;
      font-family: $base_font_en;
      text-align: center;
      margin-left: 2.5em;
    }
  }
  &__title{
    font-size: 2rem;
    font-weight: 400;
  }
  &__body{
    margin-bottom: $margin_sm;
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__body{
      margin-bottom: $margin_ss;
    }
  }
}

/*-------------------------------
  MAGAZINE > ARCHIVE
-------------------------------*/
.p-mag_archive{
  padding: $margin_lg 0;
  @include mq(sm){
    padding: $margin_md 0;
  }
}
/*-------------------------------
  CONTACT > INPUT
-------------------------------*/
.p-inq_form{
  padding: $margin_lg 0 $margin_ll;
  &__title{
    font-family: $base_font_en;
    font-size: 3.75rem;
    line-height: 1.1;
  }
  &__head{
    margin-bottom: $margin_lg;
    .c-lead{
      font-size: 1.75rem;
      margin-top: 1.5em;
      line-height: 1.7;
    }
  }
  @include mq(md){
    .c-lead{
      font-size: 1.5rem;
    }
  }
  @include mq(sm){
    padding: $margin_md 0 $margin_lg;
    &__title{
      font-size: 3rem;
    }
    &__head{
      margin-bottom:$margin_sm;
      .c-lead{
        font-size: 1.25rem;
      }
    }
  }
}

/*-------------------------------
  PRIVACY
-------------------------------*/
.p-privacy_main{
  padding: $margin_lg 0;
  &__head{
    font-size: 2.65rem;
    font-weight: 400;
    padding-bottom: .5em;
    border-bottom: solid 2px $font_color;
    max-width: 75%;
    margin: 0 auto 2.5em;
  }
  &__body{
    max-width: 75%;
    margin: 0 auto;
  }
}
.p-privacy_intro{
  &__title{
    background: #EBE8EB;
    text-align: center;
    font-weight: 400;
    font-size: 2.25rem;
    padding: .5em 0;
    margin-bottom: .5em;
  }
  .c-lead{
    font-size: 1.5rem;
    line-height: 1.875;
    margin-bottom: 1em;
  }
}
.p-privacy_list{
  &__title{
    font-size: 1.8rem;
    border-left: solid 5px $font_color;
    padding-left: 12px;
    margin-bottom: 1em;
    h3{
      font-weight: 400;
    }
  }
  &__text{
    font-size: 1.5rem;
    line-height: 1.875;
    &:not(:last-child){
      margin-bottom: 2em;
    }
    > p{
      + ol{
        margin-top: 2em;
      }
    }
    ol{
      padding-left: 2em;
      li{
        list-style: decimal;
      }
    }
  }
}
/*-------------------------------
  NOT FOUND
-------------------------------*/
.p-notfound_main{
  padding: $margin_lg 0 $margin_ll;
  .c-lead{
    margin-bottom: 2.5em;
  }
  @include mq(sm){
    padding: $margin_md 0 $margin_lg;
  }
}