@charset "utf-8";
/*--------------------------------------------
  FOOTER
--------------------------------------------*/
.l-footer{
  padding: 6.5rem 0;
  background: #b4b4ba;
  color: #fff;
  &__logo{
    font-size: 2rem;
    line-height: 1;
    font-weight: 700;
    margin-bottom: 1.25em;
  }
  &__contact{
    justify-content: space-between;
    align-items: center;
    margin-bottom: $margin_sm;
    .mail{
      width: 44%;
      a{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border:solid 1px #fff;
        font-size: 1rem;
        color: #fff;
        line-height: 1;
        padding: 1.5em 1em;
        &:before{
          display: block;
          font-size: 3em;
          @include icon(mail);
        }
        span{
          display: block;
          margin-left: 1em;
          text-align: left;
        }
        @include mq(md-){
          max-width: 75%;
        }
        @include mq(sm-){
          &:hover{
            background: #fff;
            color: #b4b4ba;
          }
        }
      }
    }
    .tel{
      width: 50%;
      line-height: 1;
      p{
        margin-bottom: .75em;
      }
      a{
        position: relative;
        font-size: 4rem;
        padding-left: .75em;
        padding-bottom: .125em;
        color: #fff;
        display: block;
        font-weight: 700;
        letter-spacing: -0.05em;
        &:before{
          position: absolute;
          left: 0;
          top: 0;
          font-size: 1em;
          transform:scale(.815);
          @include icon(phone);
        }
      }
    }
  }
  &__office{
    li{
      justify-content: space-between;
      align-items: stretch;
      &:nth-child(n+2){
        margin-top: $margin_ss;
      }
      .name{
        width: 44%;
        font-size: 2rem;
        line-height: 1;
        .en{
          font-weight: 700;
          letter-spacing: .1em;
        }
        .ja{
          font-size: .5em;
          margin-top: .5em;
        }
      }
      .info{
        width: 50%;
        font-size: 1.1rem;
        line-height: 1.875;
        span{
          display: inline-block;
          &:nth-last-child(n+2){
            margin-right: .75em;
          }
        }
      }
    }
  }
  &__copy{
    margin-top: 3rem;
    text-align: center;
  }
  @include mq(md){
    &__contact{
      .mail{
        a{
          font-size: 1.125em;
        }
      }
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__contact{
      .mail{
        width: 40%;
        a{
          &:before{
            font-size: 2.5rem;
          }
        }
      }
      .tel{
        width: 52%;
        a{
          font-size: 3.6rem;
        }
      }
    }
    &__office{
      .info{
        span{
          &.postal{
            display: block;
            margin-right: 0;
          }
        }
      }
    }
  }
  @include mq(xs){
    &__logo{
      text-align: center;
    }
    &__contact{
      display: block;
      .mail{
        width: 100%;
        margin-bottom: 3rem;
        a{
          text-align: center;
          span{
            position: relative;
            display: inline-block;
            font-size: 1.25rem;
          }
        }
      }
      .tel{
        width: 100%;
        text-align: center;
        a{
          display: inline-block;
        }
      }
    }
    &__office{
      li{
        display: block;
        &:nth-child(n+2){
          margin-top: 3rem;
        }
        .name{
          position: relative;
          width: 100%;
          margin-bottom: .35em;
          .ja{
            margin-top: .75em;
          }
        }
        .info{
          width: 100%;
          span{
            &.postal{
              display: inline-block;
            }
          }
        }
      }
    }
  }
  @include mq(ss){
    padding: $margin_md 0;
    &__logo{
      font-size: 1.75rem;
      margin-bottom: 1em;
    }
    &__contact{
      margin-bottom: 2rem;
      .mail{
        margin-bottom: 2rem;
      }
      .tel{
        p{
          line-height: 1.6;
        }
        a{
          font-size: 2.4rem;
        }
      }
    }
    &__office{
      li{
        &:nth-child(n+2){
          margin-top: 2rem;
        }
        .info{
          span{
            &:nth-last-child(n+2){
              margin-right: .5em;
            }
            &.postal{
              display: block;
            }
          }
        }
      }
    }
  }
}