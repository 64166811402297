@charset "utf-8";

/*-------------------------------
  WP > EDITOR
-------------------------------*/
.wp-editor {
  strong { 
    font-weight: bold; 
  }
  em {
    font-style: italic; 
  }
  blockquote {
    display: block;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 40px;
    -webkit-margin-end: 40px;
  }
  img.alignleft {
    margin: 0 auto 0 0;
  }
  img.aligncenter {
    display: block;
    margin: 0 auto;
  }
  img.alignright {
    display: block;
    margin: 0 0 0 auto;
  }
  img[class*="wp-image-"],
  img[class*="attachment-"] {
    height: auto;
    max-width: 100%;
  }
  hr{
    margin: 2em 0;
  }
  *{
    & + p{
      margin-top: 1em;
    }
    & + ul,
    & + ol,
    & + .c-tbl_responsive {
      margin-top: 2em;
    }

    & + h1,
    & + h2,
    & + h3,
    & + h4,
    & + h5,
    & + h6 {
      margin-top: 1.5em;
    }
  }
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: .1em;
  }
  h2{
    font-size: 1.4rem;
  }
  h3{
    font-size: 1.2rem;
  }
  h4{
    font-size: 1rem;
  }
  h5{
    font-size: .9rem;
  }
  h6{
    font-size: .85rem;
  }

  a{
    color: $link_color;
    word-break: break-all;
    &:focus{
      opacity: .7;
    }
    @include mq(sm-){
      &:hover{
        opacity: .7;
      }
    }
  }

  ul,
  ol {
    padding-left: 2em;
    line-height: 1.5;
    > li:not(:first-child){
      margin-top: .6em;
    }
  }
  
  ul{
    > li{
      list-style-type: disc;
    }
  }
  ol{
    >li{
      list-style-type: decimal;
    }
  }
  
  table{
    tr{
      > th,
      > td{
        padding: 1em;
        border: 1px solid #ddd;
      }
      > th{
        background: #eee;
      }
    }
  }

  @include mq(sm){
    *{
      & + p,
      & + ul,
      & + ol,
      & + .c-tbl_responsive {
        margin-top: 1.2em;
      }

      & + h1,
      & + h2,
      & + h3,
      & + h4,
      & + h5,
      & + h6 {
        margin-top: 2.5em;
      }
    }
    ul,
    ol {
      > li:not(:first-child){
        margin-top: .4em;
      }
    }
  }
}
/*-------------------------------
  WP > PAGINATION
-------------------------------*/
.wp-pagenavi{
  margin-top: $margin_md;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
  >*{
    width: 2em;
    height: 2em;
    line-height: 2;
    text-align: center;
    border-radius: 50%;
    &:nth-child(n+2){
      margin-left: 1em;
    }
  }
  .current{
    background: $main_color;
    color: #fff;
  }
  a{
    @include mq(sm-){
      &:hover{
        background: $main_color;
        color: #fff;
      }
    }
  }
  @include mq(sm){
    margin-top: $margin_sm;
  }
}