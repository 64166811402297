@charset "utf-8";
/*============================================

  COMPANY

============================================*/
/*-------------------------------
  COMPANY > MESSAGE
-------------------------------*/
.p-co_message{
  padding: $margin_lg 0 $margin_ll;
  .c-lead_ttl{
    width: 100%;
    .catch{
      letter-spacing: 0.15em;
    }
  }
  &__inner{
    justify-content: space-between;
  }
  &__text{
    max-width: 53%;
    .c-lead{
      &:nth-child(n+2){
        margin-top: 2em;
      }
    }
    .sign{
      text-align: right;
      margin-top: 4em;
      span{
        display: inline-block;
        vertical-align: middle;
      }
      img{
        width: auto;
        height: 2.8em;
        margin-left: 2em;
        vertical-align: middle;
      }
    }
  }
  &__img{
    max-width: 45%;
    img{
      max-width: 100%;
    }
  }
  @include mq(md){
    &__text{
      max-width: 60%;
      .c-lead{
        line-height: 2.25;
      }
      .sign{
        margin-top: 2em;
      }
    }
    &__img{
      max-width: 40%;
    }
  }
  @include mq(sm){
    &__inner{
      align-items: flex-start;
    }
    &__text{
      max-width: 75%;
    }
    &__img{
      max-width: 25%;
    }
  }
  @include mq(xs){
    padding: $margin_md 0;
    &__inner{
      position: relative;
      display: block;
    }
    &__text{
      max-width: 100%;
      margin-bottom: 2em;
      .c-lead{
        &:nth-child(n+2){
          margin-top: 1.5em;
        }
      }
    }
    &__img{
      width: 50%;
      max-width: 50%;
      margin:0 auto;
      .sign{
        display: block;
        text-align: center;
      }
    }
  }
  @include mq(ss){
    padding: $margin_md 0 $margin_lg;
    &__text{
      .c-lead{
        &:nth-child(n+2){
          margin-top: 1em;
        }
      }
    }
    &__img{
      width: 100%;
      max-width: 100%;
    }
  }
}

/*-------------------------------
  COMPANY > PHILOSOPHY
-------------------------------*/
.p-co_philosophy{
  padding: $margin_lg 0;
  &__inner{
    > .c-lead{
      margin-bottom: 3em;
      font-size: 1.1rem;
    }
  }
  @include mq(sm){
    padding: 0 0 $margin_md;
  }
  @include mq(xs){
    .c-lead_ttl{
      .ttl{
        .en{
          font-size: .95em;
        }
      }
    }
  }
  @include mq(ss){
    padding: 0 0 $margin_lg;
    .c-lead_ttl{
      .catch{
        letter-spacing: .03em;
      }
    }
  }
}
.p-co_philosophy_item{
  padding: 4.5rem 2.5rem;
  width: 33.33333%;
  &:nth-child(1){
    background: $gray_color01;
  }
  &:nth-child(2){
    width: 33.33334%;
    background: $gray_color02;
  }
  &:nth-child(3){
    background: $gray_color03;
  }
  &__title{
    font-size: 2.5rem;
    margin-bottom: 1.2em;
    text-align: center;
    color: #fff;
    font-weight: 400;
  }
  .c-lead{
    font-size: 1.25rem;
    color: #fff;
    line-height: 2;
  }
  @include mq(w1400){
    .c-lead{
      font-size: 1.15rem;
    }
  }
  @include mq(md){
    padding:4.5rem 1.5rem;
    &__title{
      font-size: 2.25rem;
      margin-bottom: .8em;
    }
    .c-lead{
      font-size: 1.1rem;
    }
  }
  @include mq(sm){
    padding:2.5rem 1rem;
    &__title{
      font-size: 2rem;
    }
    .c-lead{
      font-size: .95rem;
    }
  }
  @include mq(xs){
    width: 100%;
    padding: 2rem 0;
    &__inner{
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &:nth-child(2){
      width: 100%;
    }
    &__title{
      font-size: 1.8rem;
      width: 35%;
    }
    .c-lead{
      width: 60%;
    }
  }
  @include mq(ss){
    padding: 2.5rem 1.5rem;
    &__inner{
      display: block;
      padding: 0;
    }
    &__title{
      width: 100%;
      margin-bottom: 1em;
    }
  }
}

/*-------------------------------
  COMPANY > POLICY
-------------------------------*/
.p-co_policy{
  padding: $margin_md 0 $margin_lg;
  .c-lead_ttl{
    width: 100%;
  }
  &__inner{
    justify-content: space-between;
  }
  &__text{
    padding-top: $margin_md;
    .c-lead{
      font-size: 1.25rem;
    }
  }
  &__img{
    margin-top: -3rem;
    padding-right: 2%;
    svg{
      width: 37rem;
      height: 20rem;
    }
  }
  @include mq(md){
    &__img{
      margin-top: 0;
    }
  }
  @include mq(sm){
    &__inner{
      align-items: center;
    }
    &__text{
      padding-top: 0;
      .c-lead{
        font-size: 1.2rem;
      }
    }
    &__img{
      padding-right: 0;
      svg{
        width: 22.2rem;
        height: 12rem;
      }
    }
  }
  @include mq(xs){
    &__inner{
      display: block;
    }
    &__img{
      text-align: center;
      margin-top: 2rem;
      svg{
        width: 25.995rem;
        height:15rem;
      }
    }
  }
  @include mq(ss){
    padding: 0 0 $margin_lg;
    &__text{
      .c-lead{
        font-size: 1.15rem;
      }
    }
    &__img{
      position: relative;
      width: 100%;
      padding-top: 54%;
      svg{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

/*-------------------------------
  COMPANY > GUIDELINE
-------------------------------*/
.p-co_guideline{
  padding: $margin_ll 0;
  &__list{
    justify-content: space-between;
    counter-reset:co_count01 0;
    width: 72.22%;
    margin:$margin_lg auto 0;
  }
  @include mq(md){
    &__list{
      width: 80%;
    }
  }
  @include mq(sm){
    padding: 0 0 $margin_md;
    .c-lead_ttl{
      .catch{
        letter-spacing: 0.15em;
      }
    }
    &__list{
      width: 100%;
      margin-top: $margin_md;
    }
  }
  @include mq(ss){
    padding: 0 0 $margin_lg;
  }
}
.p-co_guideline_item{
  $amp: &;
  position: relative;
  counter-increment: co_count01 1;
  width: 49%;
  padding: 1.5rem 2rem;
  &:before{
    position: absolute;
    content:counter(co_count01);
    font-size: 10rem;
    text-align: center;
    line-height: .75;
    color: #fff;
  }
  &__inner{
    position: relative;
    padding-top: 65.625%;
  }
  &__title{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: .5em;
  }
  &__text{
    padding: 2.5rem;
    color: #fff;
    .c-lead{
      line-height: 2;
    }
  }
  &:nth-child(1){
    background: $gray_color01;
  }
  &:nth-child(2){
    background: $gray_color02;
  }
  &:nth-child(3){
    background: $gray_color04;
  }
  &:nth-child(4){
    background: $gray_color03;
  }
  @include mq(xs-){
    &__text{
      position: absolute;
    }
    &:nth-child(n+3){
      margin-top: 2%;
    }
    &:nth-child(1){
      &:before{
        bottom:1.5rem;
        right:2rem;
      }
      #{$amp}__text{
        top:0;
        left: 0;
      }
    }

    &:nth-child(2){
      &:before{
        bottom:1.5rem;
        left:2rem;
      }
      #{$amp}__text{
        top:0;
        right: 0;
      }
    }

    &:nth-child(3){
      &:before{
        top:1.5rem;
        right:2rem;
      }
      #{$amp}__text{
        bottom:0;
        left: 0;
      }
    }

    &:nth-child(4){
      &:before{
        top:1.5rem;
        left:2rem;
      }
      #{$amp}__text{
        bottom:0;
        right: 0;
      }
    }
  }
  @include mq(w1400){
    &__text{
      padding: 1.5rem;
    }
  }
  @include mq(sm){
    &:before{
      font-size: 8.5rem;
    }
    &__title{
      margin-bottom: .5em;
    }
  }
  @include mq(xs){
    padding: 2rem;
    &:nth-child(n+3){
      margin-top: 2%;
    }
    &:before{
      font-size: 7rem;
      right:2rem;
      top: 2rem;
    }
    &__text{
      position: absolute;
      left: 0;
      bottom:0;
      padding: 0;
    }
  }
  @include mq(ss){
    width: 100%;
    &:before{
      font-size: 6rem;
    }
    &:nth-child(n+2){
      margin-top: 2%;
    }
    &__inner{
      padding-top: 45%;
    }
  }
}
/*-------------------------------
  COMPANY > PROFILE
-------------------------------*/
.p-co_profile{
  padding: $margin_md 0 $margin_sm * 2;
  &__inner{
    justify-content: space-between;
  }
  @include mq(sm){
    padding: $margin_md 0;
  }
  @include mq(ss){
    padding: $margin_lg 0;
  }
}
.p-co_profile_item{
  $amp: &;
  width: 50%;
  .c-lead_ttl{
    .ttl{
      display: block;
      .ja{
        margin-left: 0;
        margin-top: 1em;
      }
    }
  }
  &__sheet{
    th,
    td{
      vertical-align: top;
      >*{
        font-weight: 400;
        line-height: 3;
      }
    }
    th{
      padding-right: 1.5em;
      text-align: left;
    }
    td{
      padding-left: 1.5em;
      ul{
        li{
          &:nth-last-child(n+2){
            &:after{
              content:"、";
            }
          }
        }
      }
    }
  }
  &.-information{
    padding-right: 1em;
    #{$amp}__sheet{
      th{
        width: 9em;
      }
    }
  }
  &.-history{
    padding-left: 1em;
    #{$amp}__sheet{
      th{
        width: 6em;
      }
      td{
        border-left:solid 1px $font_color;
      }
    }
  }
  @include mq(w1400){
    &.-history{
      #{$amp}__sheet{
        th{
          width: 7em;
        }
      }
    }
  }
  @include mq(md){
    th,
    td{
      >*{
        line-height: 2.5;
      }
    }
  }
  @include mq(sm){
    width: 100%;
    &__sheet{
      th{
        padding-right: 2em;
      }
      td{
        padding-left: 2em;
      }
    }
    &.-information{
      padding-right: 0;
    }
    &.-history{
      padding-left: 0;
      margin-top: $margin_md;
      #{$amp}__sheet{
        th{
          width: 9em;
        }
      }
    }
  }
  @include mq(ss){
    &__sheet{
      tr{
        border-top:solid 1px $font_color;
        border-bottom:solid 1px $font_color;
        padding: 1em 0;
        display: block;
        margin-top: -1px;
      }
      th,
      td{
        display: block;
        width: 100%;
        >*{
          line-height: 2;
        }
      }
      th{
        padding-right: 0;
        font-size: 1.125rem;
      }
      td{
        padding-left: 0;
      }
    }
    &.-information{
      #{$amp}__sheet{
        th{
          width: 100%;
        }
      }
    }
    &.-history{
      #{$amp}__sheet{
        td{
          border-left: none;
        }
      }
    }
  }
}