@charset "utf-8";
/*============================================

  RECRUIT

============================================*/
/*-------------------------------
  RECRUIT > INTRO
-------------------------------*/
.p-rec_intro{
  padding: $margin_lg 0;
  &__inner{
    align-items: flex-end;
  }
  &__text{
    width: calc(100% - 30.35rem);
    padding-bottom: 2em;
    .c-lead_ttl{
      .catch{
        letter-spacing: .4em;
      }
    }
    .c-lead{
      line-height: 3;
    }
  }
  &__img{
    width: 30.35rem;
    height: 25.4rem;
    figure{
      width: 100%;
      height: 100%;
      svg{
        width: 100%;
        height: 100%;
        #fill_01,
        #fill_02{
          opacity: 0;
          transition:.6s;
        }
      }
      &.is-anime{
        svg{
          #fill_01,
          #fill_02{
            opacity: .3;
          }
        }
      }
    }
  }
  @include mq(w1400){
    &__text{
      width: calc(100% - 25rem);
    }
    &__img{
      width: 25rem;
      height: 21rem;
    }
  }
  @include mq(md){
    &__text{
      width: calc(100% - 22.5rem);
      .c-lead_ttl{
        .catch{
          letter-spacing: .35em;
        }
      }
    }
    &__img{
      width: 22.5rem;
      height: 18.8rem;
    }
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__text{
      width: calc(100% - 20rem);
      .c-lead_ttl{
        .en{
          line-height: 1.1;
        }
        .catch{
          letter-spacing: .25em;
        }
      }
    }
    &__img{
      width: 20rem;
      height: 16.7rem;
    }
  }
  @include mq(xs){
    display: block;
    &__text{
      width: 100%;
    }
    &__img{
      margin:0 auto;
    }
  }
}
/*-------------------------------
  RECRUIT > FLOW
-------------------------------*/
.p-rec_flow{
  padding: $margin_md 0 $margin_lg;
  background: #ebe8eb;
  .c-lead{
    margin-bottom: 2em;
  }
  &__list{
    justify-content: space-between;
    width: 75%;
    margin:0 auto;
  }
  @include mq(sm){
    padding: $margin_md 0;
    &__list{
      width: 100%;
    }
  }
  @include mq(ss){
    &__list{
      display: block;
      width: 100%;
    }
  }
}

.p-rec_flow_item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  &__ico{
    display: flex;
    justify-content: center;
    align-items: center;
    figure{
      svg{
        width: 100%;
        height: 100%;
        fill:#3c3c3c;
      }
      &.-document{
        width: 4.9rem;
        height: 6rem;
      }
      &.-interview{
        width: 7.5rem;
        height: 6.2rem;
      }
      &.-test{
        width: 5.75rem;
        height: 6rem;
      }
      &.-good{
        width: 4.65rem;
        height: 4.57rem;
      }
      &:nth-child(n+2){
        position: relative;
        margin-left: 5rem;
        &:before,
        &:after{
          position: absolute;
          content:"";
          background: $font_color;
        }
        &:before{
          width: 1.8rem;
          height: 4px;
          left: calc(-5rem + (5rem - 1.8rem) / 2);
          top: 50%;
          transform:translateY(-50%);
        }
        &:after{
          height: 1.8rem;
          width: 4px;
          left: calc(-5rem + (5rem - 1.8rem) / 2 + 1.8rem / 2);
          top: 50%;
          transform:translate(-50%,-50%);
        }
      }
    }
  }
  &__text{
    margin-top: .5em;
    font-size: 1.1rem;
  }
  @include mq(xs){
    &__ico{
      figure{
        &.-document{
          width: 3.675rem;
          height: 4.5rem;
        }
        &.-interview{
          width: 5.625rem;
          height: 4.65rem;
        }
        &.-test{
          width: 4.3125rem;
          height: 3.4875rem;
        }
        &.-good{
          width: 3.4875rem;
          height: 3.4275rem;
        }
        &:nth-child(n+2){
          margin-left: 4rem;
          &:before,
          &:after{
            position: absolute;
            content:"";
            background: $font_color;
          }
          &:before{
            width: 1.5rem;
            height: 2px;
            left: calc(-4rem + (4rem - 1.5rem) / 2);
          }
          &:after{
            height: 1.5rem;
            width: 2px;
            left: calc(-4rem + (4rem - 1.5rem) / 2 + 1.5rem / 2);
          }
        }
      }
    }
  }
  @include mq(ss){
    &:nth-last-child(n+2){
      position: relative;
      padding-bottom: 4rem;
      &:after{
        position: absolute;
        content:"";
        left: 50%;
        bottom: 1rem;
        border-style:solid;
        border-width:1rem 1rem 0;
        border-color: #3c3c3c transparent;
        transform:translateX(-50%);
      }
    }
  }
}

.p-rec_flow_arrow{
  position: relative;
  width: 2.25rem;
  height: 2px;
  background: $font_color;
  align-self: center;
  &:after{
    position: absolute;
    content:"";
    width: 30%;
    height: 100%;
    right: 0;
    bottom: 0;
    transform-origin: right center;
    transform:rotate(30deg);
    background: $font_color;
  }
}
/*-------------------------------
  RECRUIT > GUIDELINE
-------------------------------*/
.p-rec_guideline{
  padding: $margin_lg 0 $margin_ll;
  @include mq(sm){
    padding: $margin_md 0 $margin_lg;
  }
}
.p-requirement{
  &__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
    padding: .75em 1.5em;
    color: #fff;
    background: #8c8c96;
    font-weight: 400;
    font-size: 1.25rem;
    &.-wanted{
      cursor: pointer;
    }
    &.-finished{
      background: #c8c0c8;
      h3{
        width: calc(100% - 12em);
      }
      .end{
        width: 12em;
        text-align: right;
      }
    }
    h3{
      width: calc(100% - 2em);
      font-weight: 400;
      span{
        display: inline-block;
        vertical-align: middle;
        &.type{
          font-size: 1.2em;
          font-family: $base_font_en;
          width: 10em;
        }
        &.title{
          max-width: calc(100% - 19em);
          min-width: 15em;
        }
        &.number{
          width: 7em;
          text-align: right;
        }
      }
    }
    .toggle{
      position: relative;
      width: 1em;
      height: 1em;
      transition:.6s;
      &:before,
      &:after{
        position: absolute;
        content:"";
        background: #fff;
        left: 50%;
        top: 50%;
        transform:translate(-50%,-50%);
        transition:.6s;
      }
      &:before{
        width: 2px;
        height: 1em;
        opacity: 1;
      }
      &:after{
        width: 1em;
        height: 2px;
      }
    }
    &:not(:first-child){
      margin-top: 2.3rem;
    }
    &.is-act{
      .toggle{
        transform:rotate(360deg);
        &:before{
          opacity: 0;
        }
      }
    }
  }
  &__sheet{
    display: none;
    tr{
      border-bottom:solid 5px #fff;
    }
    th,
    td{
      padding: 1.5rem 2.5rem;
      text-align: left;
      line-height: 1.6;
      font-weight: 400;
      vertical-align: top
    }
    th{
      width: 30%;
      background: #dfdcdc;
      font-size: 1.3rem;
    }
    td{
      width: 70%;
      background: #f2f0f0;
      font-size: 1.25rem;
    }
  }
  @include mq(sm){
    &__title{
      h3{
        span{
          &.title{
          }
        }
      }
    }
  }
  @include mq(xs){
    &__title{
      h3{
        line-height: 1.4;
        span{
          display: block;
          &.type{
            width: 100%;
          }
          &.title{
            width: 100%;
            line-height: 1.6;
          }
          &.number{
            width: 100%;
            text-align: left;
          }
        }
      }
      &.-finished{
        display: block;
        h3{
          width: 100%;
        }
        .end{
          width: 100%;
          text-align: left;
          line-height: 1.6;
        }
      }
    }
  }
  @include mq(ss){
    &__sheet{
      tr{
        display: block;
        border-bottom: none;
      }
      th,
      td{
        display: block;
        width: 100%;
        padding: 1rem 1rem;
      }
      td{
        padding: 1.25rem 1rem;
      }
    }
  }
}