@charset "utf-8";
/*--------------------------------------------
  FRAMEWORK
--------------------------------------------*/
.c-inner {
  width: 94%;
  max-width: $base_width_max;
  margin-left: auto;
  margin-right: auto;
  &.-base {
    max-width: $base_width;
  }
}
.c-key_under{
  position: relative;
  &__img{
    &.c-img_ofi{
      padding-top: 26%;
      z-index: 1;
    }
  }
  &__text{
    position: absolute;
    left: 0;
    top: -.1em;
    width: 100%;
    color: #fff;
    font-family: $base_font_en;
    z-index: 2;
    font-size: 11rem;
    line-height: .8;
    font-weight: 300;
  }
  @include mq(sm){
    &__text{
      font-size: 9rem;
    }
  }
  @include mq(xs){
    &__img{
      &.c-img_ofi{
        padding-top: 35%;
      }
    }
  }
  @include mq(ss){
    &__img{
      &.c-img_ofi{
        padding-top: 45%;
      }
    }
    &__text{
      font-size: 4.5rem;
      top: -.05em
    }
  }
}
.c-bg_gray{
  background: #ebebeb;
}

.c-lead{
  line-height: 2.5;
  @include mq(ss){
    line-height: 2;
  }
}
/*--------------------------------------------
  TITLE
--------------------------------------------*/
.c-lead_ttl{
  font-size: 4.5rem;
  margin-bottom: .55em;
  .ttl{
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    line-height: 1;
    margin-bottom: .65em;
    &:last-child{
      margin-bottom: 0;
    }
    span{
      display: block;
    }
    .en{
      line-height: .8;
      font-family: $base_font_en;
      letter-spacing: .05em;
    }
    .ja{
      line-height: 1;
      font-size: .333em;
      margin-left: 1.5em;
      letter-spacing: .5em;
      font-weight: 400;
    }
  }
  .catch{
    font-size: .47em;
    font-weight: 400;
    letter-spacing: .5em;
    &.-sm{
      font-size: .355em;
    }
  }
  @include mq(sm){
    font-size: 3.6rem;
  }
  @include mq(xs){
    .ttl{
      display: block;
      .en{
        line-height: 1.1;
      }
      .ja{
        margin-left: 0;
        margin-top: 1em;
      }
    }
    .catch{
      letter-spacing: .4em;
      line-height: 1.4;
    }
  }
  @include mq(ss){
    font-size: 2.6rem;
    .ttl{
      .ja{
        font-size: .5em;
      }
    }
    .catch{
      font-size: .5em;
      letter-spacing: 0.15em;
      &.-sm{
        font-size: .4em;
      }
    }
  }
}
.c-main_ttl{
  font-size: 3.9rem;
  line-height: 1;
  margin-bottom: .5em;
  font-family: $base_font_en;
  font-weight: 400;
  letter-spacing: .01em;
  @include mq(sm){
    font-size: 3.2rem;
  }
  @include mq(xs){
    font-size: 2.4rem;
    line-height: 1.1;
  }
}
.c-bg_ttl{
  color: #E6E6EA;
  font-family: $base_font_en;
  line-height: 1;
  font-size: 10.75rem;
  font-weight: 400;
  margin-bottom: .35em;
  white-space: nowrap;
  overflow: hidden;
  &.-wh{
    color: #fff;
  }
  @include mq(md){
    font-size: 9.35rem;
  }
  @include mq(sm){
    font-size: 7.25rem;
  }
  @include mq(xs){
    font-size: 5.6rem;
  }
  @include mq(ss){
    font-size: 4rem;
    white-space: pre-wrap;
  }
}
.c-sub_ttl{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1;
  font-size: 1.5rem;
  margin-bottom: 1.25em;
  .lg{
    margin-right: 1.75em;
  }
  .sm{
    font-size: .73em;
    font-weight: 400;
  }
  @include mq(xs){
    display: block;
    span{
      display: block;
    }
    .lg{
      padding-top: .25em;
      margin-bottom: .5em;
    }
  }
  @include mq(ss){
    margin-bottom: 1em;
  }
}
/*--------------------------------------------
  BUTTON, LINK
--------------------------------------------*/
.c-btnwrap {
  display: flex;
  justify-content: center;
  align-items:center;
  text-align: center;
  &.-fx{
    .link_btn{
      width: calc((100% - 4rem) / 2 );
      a{
        display: block;
      }
      @include mq(xs-){
        &:nth-child(n+2){
          margin-left:4rem;
        }
      }
      @include mq(xs){
        &:nth-child(n+2){
          margin-top:2rem;
        }
      }
    }
  }
  @include mq(xs){
    display: block;
    &.-fx{
      .link_btn{
        width:100%;
      }
    }
  }
}
.c-btn01{
  line-height: 1;
  text-align: center;
  a,
  .link_btn{
    position: relative;
    display: inline-block;
    background: $font_color;
    padding: 1em 5em;
    color: #fff;
    font-family: $base_font_en;
    overflow: hidden;
    cursor: pointer;
    span{
      position: relative;
      z-index: 2;
    }
    &:before{
      position: absolute;
      content:"";
      width: 100%;
      height: 100%;
      background: $main_color;
      left: 0;
      top: 0;
      z-index: 1;
      transform:scaleY(0);
      transition:inherit;
    }
    &:after{
      position: absolute;
      content:"＞";
      right: 1em;
      top: 50%;
      transform:translateY(-50%);
      z-index: 2;
    }
    @include mq(sm-){
      &:hover{
        &:before{
          transform:scaleY(1);
        }
      }
    }
  }
}


/*--------------------------------------------
  TABLE
--------------------------------------------*/
.c-tbl_responsive{
  @include mq(sm){
    .rwd_sheet{
      overflow-x:auto;
      overflow-y: visible;
      table{
        min-width: 100%;
        width:auto;
        th,
        td{
          white-space: nowrap;
        }
      }
    }
    &:after{
      display: block;
      content:"横にスクロールできます";
      text-align: center;
      margin-top: .5em;
    }
  }
}

/*--------------------------------------------
 PARTS - NEWS
--------------------------------------------*/
.c-news_list{
  li{
    border-top:solid 1px $font_color;
    border-bottom:solid 1px $font_color;
    margin-top: -1px;
    a{
      position: relative;
      padding: 1.5em;
      align-items: flex-start;
      > *{
        position: relative;
        z-index: 2;
        padding: .25em 0 .15em;
        line-height: 1.5;
      }
      &:before{
        position: absolute;
        content:"";
        top: 0;
        left: initial;
        right: 0;
        width: 0;
        height: 100%;
        background: #f9f9f9;
        transition:.6s;
      }
      time{
        width: 7em;
        font-family: $base_font_en;
      }
      .badge{
        width: 4em;
        margin-right: 2em;
        span{
          display: block;
          width: 100%;
          background: #fff;
          border:solid 1px $font_color;
          font-family: $base_font_en;
          text-align: center;
        }
      }
      .title{
        width: calc(100% - 13em);
        font-weight: 400;
      }
      @include mq(sm-){
        &:hover{
          &:before{
            left: 0;
            right: initial;
            width: 100%;
          }
        }
      }
    }
  }
  @include mq(xs){
    li{
      a{
        .title{
          width: 100%;
          padding-top: 1em;
        }
      }
    }
  }
  @include mq(ss){
    li{
      a{
        .badge{
          font-size: .95rem;
          padding: .2em .25em .15em;
        }
        padding: 1em;
      }
    }
  }
}
/*--------------------------------------------
 PARTS - MAGAZINE
--------------------------------------------*/
.c-magazine_list{
  li{
    width: 22%;
    margin-right:4%;
    @include mq(md){
      width: 23.5%;
      margin-right:2%;
    }
    @include mq(xs-){
      &:nth-child(4n){
        margin-right: 0;
      }
      &:nth-child(n+5){
        margin-top: $margin_sm;
      }
    }
    @include mq(xs){
      width: 30%;
      margin-right:5%;
    }
    @include mq(ss-xs){
      &:nth-child(3n){
        margin-right:0;
      }
      &:nth-child(n+4){
        margin-top: $margin_sm;
      }
    }
    @include mq(ss){
      width: 47%;
      margin-right:6%;
      &:nth-child(even){
        margin-right:0;
      }
      &:nth-child(n+3){
        margin-top: $margin_sm;
      }
    }
  }
  &__inner{
    display: block;
    @include mq(sm-){
      &:hover{
        opacity: .5;
      }
    }
  }
  &__info{
    line-height: 1.5;
    margin-bottom: .75em;
    font-size: .9rem;
    >*{
      display: inline-block;
      vertical-align: middle;
    }
    .badge{
      width: 4em;
      background: #fff;
      border:solid 1px $font_color;
      font-family: $base_font_en;
      text-align: center;
      margin-left: 1.25em;
    }
  }
  &__img{
    img{
      max-width: 100%;
    }
  }
  &__title{
    margin-top: 1em;
    .category{
      line-height: 1.4;
    }
    h2{
      line-height: 1.4;
      font-weight: 400;
    }
  }
}
/*--------------------------------------------
  PARTS - FORM
--------------------------------------------*/
.c-form_box{
  $amp :&;
  table{
    tr{
      border-bottom:solid 0.75rem #fff;
    }
  }
  &__step{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.5rem;
    counter-reset:step 0;
    margin-bottom:6.575rem;
    &:after{
      position: absolute;
      content:"";
      left: 1em;
      top: 50%;
      width: calc(100% - 2em);
      height: 2px;
      transform:translateY(-50%);
      background: #828282;
      z-index: 1;
    }
    li{
      position: relative;
      counter-increment: step 1;
      z-index: 2;
      background: #fff;
      padding:0 1.5em;
      font-size: 1.5rem;
      &:nth-last-child(n+2){
        &:before{
          content:'STEP'counter(step);
          margin-right: .5em;
        }
      }
      &:first-child{
        padding-left: 0;
      }
      &:last-child{
        padding-right: 0;
      }
    }
  }
  &__label{
    text-align: left;
    line-height: 1.35;
    vertical-align: top;
    width: 20%;
    .form_label{
      .ja{
        padding-top: .5em;
      }
      .en{
        font-size: .85rem;
        font-family: $base_font_en;
      }
    }
  }
  &__input{
    width: 80%;
    vertical-align: top;
    padding-left: 1em;
    *{
      width: 100%;
    }
  }
  &__privacy{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $margin_md;
    font-size: 1.25rem;
    line-height: 1;
    >span{
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: .5em;
    }
    label{  
      display: block;
      cursor: pointer;
    }
    a{
      text-decoration: underline;
      @include mq(sm-){
        &:hover{
          text-decoration: none;
        }
      }
    }
    input[type="checkbox"]{
      display: none;
      + span{
        position: relative;
        width: 100%;
        height: 100%;
        display: block;
        &:before,
        &:after{
          position: absolute;
          content:"";
        }
        &:before{
          left: 0;
          top: 0;
          width: 1em;
          height: 1em;
          border:solid 2px #828282;
        }
        &:after{
          left: 0;
          top: 0;
          width: 1em;
          height:.5em;
          border-left:solid 4px $main_color;
          border-bottom:solid 4px $main_color;
          transform:rotate(-45deg);
          visibility: hidden;
        }
      }
      &:checked + span{
        &:after{
          visibility: visible;
        }
      }
    }
  }
  &__btn{
    margin-top: $margin_ll;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    .link_btn{
      display: inline-block;
      padding:1em 0;
      text-align: center;
      width: 15rem;
      background: $font_color;
      cursor: pointer;
      color: #fff;
      border:solid 1px $font_color;
      transition:.6s;
      border-radius: 0;
      &.-back{
        background: $gray_color01;
        border:solid 1px $gray_color01;
        margin-left:2em;
      }
      @include mq(sm-){
        &:hover{
          background: #fff;
          color: $font_color;
        }
        &.-back{
          &:hover{
            background: #fff;
            color: $gray_color01;
          }
        }
      }
    }
  }
  &.-confirm{
    #{$amp}__privacy{
      display: none;
    }
  }

  @include mq(sm-){
    max-width: 75%;
    margin:0 auto;
  }
  @include mq(md){
    &__step{
      margin-bottom:3.5rem;
    }
    &__privacy{
      margin-top: $margin_sm;
    }
    &__btn{
      margin-top: $margin_md;
    }
  }
  @include mq(xs){
    &__label{
      width: 30%;
    }
    &__input{
      width: 70%;
    }
  }
  @include mq(ss){
    table{
      tr{
        display: block;
        border-bottom:none;
        &:nth-child(n+2){
          margin-top: $margin_ss;
        }
      }
    }
    &__step{
      margin-bottom: $margin_ss;
      li{
        text-align: center;
        padding: 0 1em;
        font-size: 1.25rem;
        &:nth-last-child(n+2){
          &:before{
            display: block;
            margin-right: 0em;
          }
        }
      }
    }
    &__label{
      display: block;
      width: 100%;
      line-height: 1;
      margin-bottom: .75em;
      .form_label{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .ja{
          padding-top: 0;
        }
        .en{
          margin-left: 1.5em;
        }
      }
    }
    &__input{
      width: 100%;
      display: block;
      padding-left: 0;
    }
    &__privacy{
      margin-top: $margin_ss;
      font-size: 1.1rem;
      input[type="checkbox"]{
        + span{
          padding-left: 1.25em;
        }
      }
    }
    &__btn{
      display: block;
      margin-top: $margin_sm;
      text-align: center;
      .link_btn{
        width: 80%;
        &.-back{
          margin-left: 0;
          margin-top: 1.5rem;
        }
      }
    }
  }
}
/*--------------------------------------------
  GOOGLE MAP
--------------------------------------------*/
.c-gmap {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}


/*--------------------------------------------
  OBJECT FIT
--------------------------------------------*/
.c-img_ofi {
  position: relative;
  padding-top: 60%;
  backface-visibility: hidden;

  .ofi {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-width: none;
    max-height: none;
    &.cover{
      object-fit: cover;
      object-position: center center;
      font-family: 'object-fit: cover;object-position:center center;';
    }

    &.contain {
      margin: auto;
      object-fit: contain;
      font-family: 'object-fit: contain;object-position:center center;';
    }
  }
}


/*--------------------------------------------
  OTHERS
--------------------------------------------*/
.c-img_full {
  max-width: none;
  height: auto;
}

.c-center_block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.c-ta_left {
  text-align: left;
}

.c-ta_center {
  text-align: center;
}

.c-ta_right {
  text-align: right;
}

.c-ta_justify{
  @include mq(ss-){
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.c-pe_none {
  pointer-events: none;
}


.c-flex{
  display: flex;
  justify-content: flex-start;
  align-items:stretch;
  flex-wrap: wrap;
}

@include mq(sm-) {
  .c-pe_sp {
    pointer-events: none;
    cursor: default;
  }
}



