@charset "utf-8";
/*--------------------------------------------
  BREADCRUMBS
--------------------------------------------*/
.l-page{
  padding-top: 5rem;
  @include mq(sm){
    padding-top: 7rem;
  }
  @include mq(ss){
    padding-top: 4.6rem;
  }
}
.l-breadcrumb{
  line-height: 1;
  &__list{
    justify-content: flex-end;
    align-items: center;
    li{
      position: relative;
      padding-right:2em;
      &:after{
        position: absolute;
        content:"";
        right: .5em;
        top:50%;
        width: 1em;
        height: 2px;
        background: #b7b8b9;
        transform:translateY(-50%);
      }
      a{
        position: relative;
        padding:5px 0;
        &:after{
          position: absolute;
          content:"";
          left: 0;
          bottom:0;
          width: 100%;
          height: 1px;
          background: $font_color;
          transition:$transition_val;
        }
        &:hover{
          color:#b7b8b9;
          &:after{
            background: #b7b8b9;
            transform:scaleX(0);
          }
        }
      }
      &:last-child{
        padding-right:0;
        &:after{
          display: none;
        }
        a{
          pointer-events: none;
          color:#b7b8b9;
          &:after{
            display: none;
          }
        }
      }
    }
  }
}