@charset "utf-8";
/*--------------------------------------------
  GLOBAL NAVIGATION
--------------------------------------------*/
.l-gnavi{
  font-size: .9rem;
  font-family: $base_font_en;
  line-height: 1;
  transition-property: transform;
  li{
    a{
      display: block;
      padding: .5em 0;
      letter-spacing: .1em;
      transition-property: color;
      span{
        display: block;
        transition:$transition_val;
        transition-property:transform;
        transform:translateY(0);
      }
      @include mq(sm-){
        &:hover{
          span{
            color: $main_color;
            transform:translateY(-.5em);
          }
        }
      }
    }
    &:nth-child(n+2){
      margin-left: 2.5rem;
    }
  }
  @include mq(sm-){
    // slideout 打ち消し
    position: static;
    background: transparent;
    width: auto;
    overflow: hidden;
  }
  @include mq(md){
    font-size: 1rem;
  }
  @include mq(sm){
    background: rgba(0,0,0,.75);
    padding: $margin_ll;
    font-size: 2.5rem;
    right: 0;
    transform:translateX(100%);
    ul{
      display: block;
    }
    li{
      &:nth-child(n+2){
        margin-left: 0;
        margin-top: 1em;
      }
      a{
        color: #fff;
        padding: 0;
      }
    }
    .slideout-open &{
      transform:translateX(0);
    }
  }
  @include mq(xs){
    padding: $margin_ll $margin_lg;
  }
  @include mq(ss){
    padding: $margin_ll $margin_sm;
  }
}